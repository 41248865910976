import { COMPLAINT_SECTION, HTML_ELEMENT, HTML_SECTION, REDIAL_BUTTON, REDIAL_SECTION, SAVE_BUTTON_LINE, SAVE_BUTTON_SECTION, TWO_COLUMN_CHECKBOX_ROW, TWO_COLUMN_DATE_ROW, TWO_COLUMN_DROPDOWN_ROW, TWO_COLUMN_EMAIL_ROW, TWO_COLUMN_NUMBER_ROW, TWO_COLUMN_PHONE_ROW, TWO_COLUMN_RADIO_BUTTON_ROW, TWO_COLUMN_SECTION, TWO_COLUMN_TEXT_ROW, WRONG_NUMBER_BUTTON } from '../../constant'
import { Grid, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { getCallTask, getComposedForm } from '../../services/sidebarApi'
import { permissionErrorCheck, renderFormatMsg } from '../../shared/funs'

import Axios from 'axios'
import Error from '../../components/Error/Error'
import { Form } from 'react-final-form'
import { HOST_API } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading'
import { get } from 'lodash'

export default function ComposedForm(props) {

  const [composedForms, setComposedForms] = useState(null)
  const [disableForm, setDisableForm] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState({})
  const [, setTimeMessage] = useState(false)
  const [onSuccess, setOnSuccess] = useState("")
  const [message, setMessage] = useState(false)


  const formId = props.match.params.id.split("?dbid=", 1)



  const query = new URLSearchParams(window.location.search);
  const dbid = query.get('dbid');

  let db = {
    "taskId": dbid,
  }

  let emailRE = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  let phoneRE = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  let decimalRE = new RegExp(/^\d+(,\d{1,2})?$/);
  let dateRE = new RegExp(/^\d{2}[./-]\d{2}[./-]\d{4}$/);

  const getData = useCallback(async () => {
    setLoading(true)
    let param = {
      "id": formId.toString(),
    }

    getComposedForm(param).then(res => {
       res.sections.forEach(sec => {
         sec.elements.forEach(element => {
          getCallTask(db).then(resDB => {
            const dataDB = resDB[0];

            let value = get(dataDB, element.inputPath)
            let dataKey = element.inputPath.split(".").slice(-1)[0];

            if (value) {
              element.valueDB = value
              setFormData(prevState => ({
                ...prevState,
                [dataKey]: value
              }))
            }
          }).catch(err => {
            setError(permissionErrorCheck(err))
            setFormData([])
          })
        })
      })
      setComposedForms(res)
      setLoading(false)
    }).catch(err => {
      setError(permissionErrorCheck(err))
      setLoading(false)
      setComposedForms([])
    })
  },[formId, db])

  useEffect(() => {
    getData()
  }, [getData])

 

  const getDropdownValues = (values) => {
    let options = [<option value=""> Select Type</option>]
    let optionsArray = JSON.parse(values);
    optionsArray.values.forEach((option) => options.push(<option value={option.text}>{option.text}</option>))
    return options;
  }

  const getCheckboxValues = (element) => {
    let checkboxes = []
    let checkboxesArray = JSON.parse(element.values);

    checkboxesArray.values.forEach((checkbox) => {
      checkboxes.push(
        <>
          <input
            type="checkbox"
            value={checkbox.text}
            disabled={disableForm && true}
            id={checkbox.id}
            name={element.targetValue.split(".").slice(-1)[0]}
            onChange={(e) => handleChange(e, "checkbox")}
            readOnly={element.readOnly}
          />&nbsp;
          <label htmlFor={checkbox.id}>{checkbox.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      )
    })

    return checkboxes;
  }

  const getRadioButtonValues = (element) => {
    let radios = []
    let radiosArray = JSON.parse(element.values);

    radiosArray.values.forEach((radio) => {
      radios.push(
        <>
          <input
            type="radio"
            value={radio.text}
            disabled={disableForm && true}
            id={radio.id}
            name={element.targetValue.split(".").slice(-1)[0]}
            onChange={handleChange}
            readOnly={element.readOnly}
          />&nbsp;
          <label htmlFor="radio">{radio.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      )
    })

    return radios;
  }

  const getRedialButtonValues = (element) => {
    try {
      let buttons = []
      let buttonsArray = JSON.parse(element.values);

      buttonsArray.values.forEach((button) => {
        buttons.push(
          <>
            <input
              type="button"
              value={button.text}
              disabled={disableForm && true}
              id={button.id}
              name={element.targetValue.split(".").slice(-1)[0]}
              className="btn btn-primary mt-3"
              readOnly={element.readOnly}
            /> &nbsp;
          </>
        )
      })

      return buttons;
    } catch (error) {
      return "err";
    }
  }


  const handleAddForm = async () => {
    let payload = {
      task_id: dbid,
    }
    switch (onSuccess) {
      case "interested":
        if (formData.email) {
          payload.status = "11"
        } else {
          payload.status = "10"
        }
        break;

      case "notInterested":
        payload.status = "16"
        break;

      case "doNotCallMeEverAgain":
        payload.status = "19"
        break;

      default:
        break;
    }
    payload = { ...payload, ...formData }

    Axios.post(`${HOST_API}/api/v1/CallTask/TaskFinished/`, payload)
      .then(() => {
        setDisableForm(true)
        if (formData.block && formData.block === "time") {
          setTimeMessage({
            type: "success",
            text: renderFormatMsg('save_msg')
          })
        } else {
          setMessage({
            type: "success",
            text: renderFormatMsg('save_msg')
          })
        }
      })
      .catch(err => {
        setDisableForm(true)
        if (formData.block && formData.block === "time") {
          setTimeMessage({
            type: "danger",
            text: renderFormatMsg('error_msg')
          })
        } else {
          setMessage({
            type: "success",
            text: renderFormatMsg('save_msg')
          })
        }
        console.info('submitTaskFinished err => ', err);
      });
  }

  const handleChange = (event, type = "") => {

    if (type === "checkbox") {
      let _formData = { ...formData }

      if (_formData[event.target.name]) {

        if (event.target.checked) {
          _formData[event.target.name].push(event.target.value)
        } else {
          let index = _formData[event.target.name].indexOf(event.target.value)
          _formData[event.target.name].splice(index, 1)
        }
      } else {
        _formData[event.target.name] = [event.target.value]
      }

      setFormData(_formData)
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,

      })
    }
  }

 

  const setErrorMsg = () => {
    let listEle = [];
     
    composedForms?.sections?.length > 0 &&
    composedForms.sections.forEach(section => {
        section?.elements?.length > 0 &&
        section.elements.forEach(element => {

          let eleValue = element.targetValue.split(".").slice(-1)[0];
          let eleType = element.elementType;


          if (onSuccess === 'interested' && element.mandatoryOnSuccess === true) {
            listEle.push({ eleValue, eleType })
          }
          else if (onSuccess === "notInterested" && element.mandatoryOnReject === true) {
            listEle.push({ eleValue, eleType })
          }
        }
        )
    }
    )
    let objValue = {};

    listEle.forEach(checkValue => {

      if (!formData[checkValue.eleValue] || formData[checkValue.eleValue] === "") {
        objValue[checkValue.eleValue] = "Required"
      }
      else if (!emailRE.test(formData[checkValue.eleValue]) && checkValue.eleType === "TwoColumnEmailRow") {
        objValue[checkValue.eleValue] = "Please enter valid email address"
      }
      else if (!phoneRE.test(formData[checkValue.eleValue]) && checkValue.eleType === "TwoColumnPhoneRow") {
        objValue[checkValue.eleValue] = "Please enter valid phone number"
      }
      else if (!decimalRE.test(formData[checkValue.eleValue]) && checkValue.eleType === "TwoColumnNumberRow") {
        objValue[checkValue.eleValue] = "Please enter valid decimal number"
      }
      else if (!dateRE.test(formData[checkValue.eleValue]) && checkValue.eleType === "TwoColumnDateRow") {
        objValue[checkValue.eleValue] = "Please enter valid date"
      }
    })
    return objValue;
  }


  return (
    <div className="main-content">
      <PageHeading headingId={"GenerateForm"} isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <Form onSubmit={handleAddForm} validate={setErrorMsg}>
            {
              ({ handleSubmit, form, values, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item className="mt-4 mb-4 ml-4 mr-4">

                      {
                        composedForms &&
                        composedForms.sections &&
                        composedForms.sections.length > 0 &&
                        composedForms.sections.map(section =>
                          <div className={`${section.type !== "SaveButtonSection" && "card"}`}>
                            <div className="cardHeading">
                              {
                                section.type !== "SaveButtonSection" &&
                                <Typography className="text-monospace" variant={"h6"}>{section.name}</Typography>
                              }
                            </div>
                            <div className="card-body pb-2">

                              {/* TwoColumnSection Section */}
                              {section.type === TWO_COLUMN_SECTION &&
                                <Grid container alignItems={"flex-start"}>
                                  {
                                    section &&
                                    section.elements &&
                                    section.elements.length > 0 &&
                                    section.elements.map(element =>
                                      <Grid item xs={12} className="altrow mt-2 ml-4 mr-4" >

                                        {element.elementType === TWO_COLUMN_TEXT_ROW &&
                                          <Grid container justify={"space-between"}>
                                            {/* {console.info('element =>', element)} */}

                                            <Grid item >
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                              {/* <Typography variant={"subtitle1"}>Two Column Row Demo Value</Typography> */}
                                              {/* <Typography variant={"subtitle1"}>{element.targetValue.split(".").slice(-1)[0]}</Typography> */}

                                              <div>
                                                <input
                                                  type="text"
                                                  name={element.targetValue.split(".").slice(-1)[0]}
                                                  id="textbox"
                                                  readOnly={element.readOnly}
                                                  value={element.valueDB}
                                                  // value={data.payload.company.companyName}
                                                  autoComplete={"off"}
                                                  placeholder=""
                                                  className={`form-control phone_us ${errors.mobile && "invalid"}`}
                                                  onChange={handleChange}
                                                />
                                              </div>
                                              {errors[element.targetValue.split(".").slice(-1)[0]] && <span style={{ color: "red" }}>{errors[element.targetValue.split(".").slice(-1)[0]]}</span>}
                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_CHECKBOX_ROW &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                              {/* <label style={{ cursor: "pointer" }} htmlFor="business">{element.values}</label>&nbsp; */}

                                              <div readOnly={element.readOnly}>
                                                {getCheckboxValues(element)}
                                              </div>

                                          
                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_RADIO_BUTTON_ROW &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>

                                              <div readOnly={element.readOnly}>
                                                {getRadioButtonValues(element)}
                                              </div>

                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_EMAIL_ROW &&
                                          <Grid container justify={"space-between"} alignItems={"baseline"} className="">
                                            <Grid item xs={3} style={{ textAlign: "left" }}>
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                              <input
                                                type="text"
                                                name={element.targetValue.split(".").slice(-1)[0]}
                                                value={element.valueDB}
                                                readOnly={element.readOnly}
                                                autoComplete={"off"}
                                                className={`form-control ${errors.email && "invalid"}`}
                                                placeholder="Email address"
                                                onChange={handleChange}
                                              />

                                              {errors[element.targetValue.split(".").slice(-1)[0]] && <span style={{ color: "red" }}>{errors[element.targetValue.split(".").slice(-1)[0]]}</span>}

                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_PHONE_ROW &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>

                                              <div>
                                                <input
                                                  type="text"
                                                  name={element.targetValue.split(".").slice(-1)[0]}
                                                  id="textbox"
                                                  readOnly={element.readOnly}
                                                  value={element.valueDB}
                                                  autoComplete={"off"}
                                                  placeholder="8 (xxx) xxx-xxxx"
                                                  className={`form-control phone_us ${errors.mobile && "invalid"}`}
                                                  onChange={handleChange}
                                                />

                                              </div>

                                              {errors[element.targetValue.split(".").slice(-1)[0]] && <span style={{ color: "red" }}>{errors[element.targetValue.split(".").slice(-1)[0]]}</span>}

                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_NUMBER_ROW &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                              <div>
                                                <input
                                                  type="number"
                                                  name={element.targetValue.split(".").slice(-1)[0]}
                                                  id="numberEle"
                                                  value={element.valueDB}
                                                  onChange={handleChange}
                                                  defaultValue={"test"}
                                                  autoComplete={"off"}
                                                  placeholder="Number"
                                                  className={`form-control ${errors.number && "invalid"}`}
                                                  readOnly={element.readOnly}
                                                />
                                              </div>

                                              {errors[element.targetValue.split(".").slice(-1)[0]] && <span style={{ color: "red" }}>{errors[element.targetValue.split(".").slice(-1)[0]]}</span>}

                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_DATE_ROW &&
                                          <Grid container justify={"space-between"} alignItems={"baseline"} >
                                            <Grid item xs={3} className="text-left">

                                            </Grid>
                                            <Grid item xs={7} className="text-right">
                                              <input
                                                type="text"
                                                name={element.targetValue.split(".").slice(-1)[0]}
                                                id="DOB"
                                                readOnly={element.readOnly}
                                                onChange={handleChange}
                                                autoComplete={"off"}
                                                placeholder="Birth of date"
                                                value={element.valueDB}
                                                className={`form-control phone_us ${errors.DOB && "invalid"}`}
                                              />
                                              {errors[element.targetValue.split(".").slice(-1)[0]] && <span style={{ color: "red" }}>{errors[element.targetValue.split(".").slice(-1)[0]]}</span>}

                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === TWO_COLUMN_DROPDOWN_ROW &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <label>{element.name}</label>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                              <select
                                                name={element.targetValue.split(".").slice(-1)[0]}
                                                id={values.dropdown}
                                                className={`form-control`}
                                                readOnly={element.readOnly}
                                                onChange={handleChange}
                                              >
                                                {getDropdownValues(element.values)}
                                              </select>

                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === HTML_ELEMENT &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <div dangerouslySetInnerHTML={{ __html: element.targetValue.split(".").slice(-1)[0] || "" }}></div>
                                            </Grid>
                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                              <div dangerouslySetInnerHTML={{ __html: element.valueDB || "" }}></div>
                                            </Grid>
                                          </Grid>
                                        }

                                        {element.elementType === WRONG_NUMBER_BUTTON &&
                                          <Grid item xs={12} className="">
                                            <Grid container alignItems={"flex-end"} direction={"column"}>
                                              <Grid item>
                                                <button
                                                  type="button"
                                                  style={{ padding: 8 }}
                                                  className={`btn btn-danger text-right ${disableForm && `disabled`}`}
                                                  disabled={disableForm && true}
                                                >
                                                  {element.name}
                                                </button>
                                              </Grid>
                                             
                                            </Grid>
                                          </Grid>
                                        }

                                      </Grid>
                                    )
                                  }
                                </Grid>
                              }


                              {/* HTMLSection */}
                              {section.type === HTML_SECTION &&
                                <Grid container alignItems={"flex-start"}>
                                  {
                                    section &&
                                    section.elements &&
                                    section.elements.length > 0 &&
                                    section.elements.map(element =>
                                      <Grid item xs={12} className="altrow ml-4 mr-4" >

                                        {element.elementType === HTML_ELEMENT &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >

                                              <div dangerouslySetInnerHTML={{ __html: element.valueDB || "" }}></div>
                                            </Grid>

                                          </Grid>
                                        }
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              }

                              {/* RedialSection */}
                              {section.type === REDIAL_SECTION &&
                                <Grid container alignItems={"flex-start"}>
                                  {
                                    section &&
                                    section.elements &&
                                    section.elements.length > 0 &&
                                    section.elements.map(element =>
                                      <Grid item xs={12} className="altrow ml-3 mr-4" >

                                        {element.elementType === REDIAL_BUTTON &&
                                          <Grid container justify={"space-between"}>
                                            <Grid item >
                                              <div readOnly={element.readOnly}>
                                                {getRedialButtonValues(element)}
                                              </div>
                                            </Grid>

                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                            </Grid>
                                          </Grid>
                                        }
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              }

                              {/* ComplaintSection */}
                              {section.type === COMPLAINT_SECTION &&
                                section &&
                                section.elements &&
                                section.elements.length > 0 &&
                                section.elements.map(element =>
                                  <Grid container alignItems={"flex-start"}>
                                    <Grid item xs={12} className="mt-4 mb-4 ml-4 mr-4">
                                      <Grid item xs={12} style={{ textAlign: "right" }}>
                                        <textarea
                                          name={element.targetValue.split(".").slice(-1)[0]}
                                          className="form-control"
                                          onChange={handleChange}
                                          readOnly={element.readOnly}
                                        >
                                        </textarea>

                                        {errors[element.targetValue.split(".").slice(-1)[0]] && <span style={{ color: "red" }}>{errors[element.targetValue.split(".").slice(-1)[0]]}</span>}

                                      </Grid>
                                      <Grid item style={{ textAlign: "right" }}>
                                        <input
                                          disabled={disableForm && true}
                                          type="checkbox"
                                          id="chkbox"
                                          name="canWeCallInvestigateReasons"
                                          onChange={handleChange}
                                          value={true}
                                        /> &nbsp;
                                        <label htmlFor="chkbox">{renderFormatMsg('can_we_call_to_investigate_reasons')}</label>

                                      </Grid>
                                      {/* </Grid> */}
                                    </Grid>
                                  </Grid>
                                )
                              }

                              {/* SaveButtonSection */}
                              {section.type === SAVE_BUTTON_SECTION &&
                                <Grid container alignItems={"flex-start"} className="mb-2">
                                  {
                                    section &&
                                    section.elements &&
                                    section.elements.length > 0 &&
                                    section.elements.map(element =>
                                      <Grid item xs={12} className="altrow mr-4 mt-3" >

                                        {element.elementType === SAVE_BUTTON_LINE &&
                                          <Grid container spacing={1}>

                                            <Grid item>
                                              <button
                                                type="submit"
                                                style={{ padding: 8 }}
                                                className={`btn btn-secondary btn-success`}
                                                disabled={disableForm && true}

                                                onClick={() => setOnSuccess("interested")}
                                              >
                                                {renderFormatMsg("interested", "Interested")}
                                              </button>
                                            </Grid>

                                            <Grid item>
                                              <button
                                                type="submit"
                                                style={{ padding: 8 }}
                                                className={`btn btn-secondary btn-danger`}
                                                disabled={disableForm && true}
                                                onClick={() => setOnSuccess("notInterested")}
                                              >
                                                {renderFormatMsg("not_interested", "Not interested")}
                                              </button>
                                            </Grid>

                                            <Grid item>
                                              <button
                                                type="submit"
                                                style={{ padding: 8 }}
                                                className={`btn btn-secondary`}
                                                onClick={() => setOnSuccess("doNotCallMeEverAgain")}
                                                disabled={disableForm && true}
                                              >
                                                {renderFormatMsg("do_not_call_me_ever_again", "Do not call me ever again")}
                                              </button>
                                            </Grid>


                                            <Grid item xs={7} style={{ textAlign: "right" }}>
                                            </Grid>
                                          </Grid>
                                        }

                                        {message && message.text &&
                                          <span className="mt-3" style={{ color: message.type === "success" ? "#4caf50" : "#f00" }}>{message.text}</span>
                                        }
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              }
                            </div>
                          </div>)
                      }
                    </Grid>
                  </form>
                );
              }
            }
          </Form>
        }
      </div>
    </div>
  )
}