import { renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import { Form } from 'react-final-form';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import React from 'react'
import { changePassword } from '../../services/userApi';

export default function HandleClient(props) {


    const handleChangePassword = async (data) => {
        changePassword(data).then(res => {
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
            props.history.push("/client")
        }).catch(() => toastError("Current password does not match."))
    }

    const validation = values => {
        const errors = {};
        if (!values.currentPassword) {
            errors.currentPassword = "Required";
        }
        if (!values.newPassword) {
            errors.newPassword = "Required";
        } else {
            if (values.confirmPassword && values.newPassword !== values.confirmPassword) {
                errors.confirmPassword = "Confirm Password does not match";
            }
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
        }
        return errors;
    };


    return (
        <div className="main-content">
            <PageHeading headingId={"Change Password"} />
            <div className="card p-3">
                <Form onSubmit={handleChangePassword}
                    validate={validation}
                >
                    {({ handleSubmit, submitting }) => {
                        return (
                            <>
                                <form onSubmit={handleSubmit} className="align-items-end form">
                                    <div className="row">
                                        <InputField
                                            colClass="col-lg-6"
                                            name={"currentPassword"}
                                            type="password"
                                            id="currentPassword"
                                            defaultLabel="Current Password"
                                            placeholder={"Current Password"}
                                            className="form-control"
                                        /></div>
                                    <div className="row">
                                        <InputField
                                            colClass="col-lg-6"
                                            name={"newPassword"}
                                            type="password"
                                            id="newPassword"
                                            defaultLabel="New Password"
                                            placeholder={"New Password"}
                                            className="form-control"
                                        /></div>
                                    <div className="row">
                                        <InputField
                                            colClass="col-lg-6"
                                            name={"confirmPassword"}
                                            type="password"
                                            id="confirmPassword"
                                            defaultLabel="Confirm Password"
                                            placeholder={"Confirm Password"}
                                            className="form-control"
                                        /></div>




                                    <div className="col-12 d-flex">
                                        <div className="submit_btn d-flex justify-content-end w-100 mx-3 mt-1">
                                            <button
                                                type="submit"
                                                disabled={submitting}
                                                className="btn btn-primary"
                                            >
                                                {renderFormatMsg("Save", "Save")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        );
                    }}
                </Form>
            </div>
        </div >

    )
}
