import * as Yup from 'yup';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core'
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'

import Axios from 'axios';
import { HOST_API } from '../../constant';
import ScheduleTable from '../../components/ScheduleTable/ScheduleTable';
import { withTranslation } from "react-i18next";

const validationSchemaForReceiveInfo = Yup.object().shape({
  email: Yup.string().test('Invalid email', 'Invalid email',
    function (value) {
      return new Promise((resolve) => {
        if (value && value.length) {
          const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (re.test(String(value).toLowerCase())) {
            Axios.post(`${HOST_API}/api/v1/CallTask/email/`, { 'email': value })
              .then(res => {
                if (res.data.success === "false") {
                  resolve(false)
                } else {
                  resolve(true)
                }
              }).catch(err => {
                resolve(false)
              })
          } else {
            resolve(false)
          }
        } else {
          resolve(true)
        }
      })
    }
  ),
  canWeCallInvestigateReasons: Yup.boolean(),
  aggrement: Yup.boolean().required("Required"),
  lastName: Yup.string().required("Required"),
  DOB: Yup
    .string()
    .required("Please enter birth date")
    .matches(
      /^([0-9]{1,2}).([0-9]{1,2}).([0-9]{4})$/,
      "Invalid Birthdate Format"
    ),
  loanTerm: Yup.number().test(
    'loanTerm',
    "Invalid Value",
    (value) => typeof value === "number" && value >= 0,
  ),
  requestedAmount: Yup.number().test(
    'requestedAmount',
    "Amount should be between 10.000 and 999.999",
    (value) => typeof value === "number" && (value >= 10000 && value <= 999999),
  ),
  // region: Yup.string().required("Required"),
  validTimeSelection: Yup.boolean().oneOf([true], "Invalid time schedule").required("Required"),
  complaint: Yup.string()
    .when("canWeCallInvestigateReasons", {
      is: true,
      then: Yup.string().min(4, "Complains should be at least 4 characters long")
    }),
});

const CALL_NOW_BUTTON = [
  {
    label: "Call back in 1 hour",
    id: "call_back_in_1_hour",
    delay: 60
  },
  {
    label: "Call back in 2 hour",
    id: "call_back_in_2_hour",
    delay: 120
  },
  {
    label: "Call back in 4 hour",
    id: "call_back_in_4_hour",
    delay: 240
  },
  {
    label: "Call back in 6 hour",
    id: "call_back_in_6_hour",
    delay: 360
  },
  {
    label: "Call back tomorrow",
    id: "call_back_tomorrow",
    delay: 720
  },
]

function Call(props) {
  const [state, setState] = useState({
    mobile: "",
    currentCustomerName: "",
    callDuration: "",
    timeType: "",
    time: "",
    customerName: "",
    changedContact: null,
    aggrement: "",
    complaint: "",
    company: null,
    promotion: null,
    timeselection: null,
  })
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(false)
  const [, setTimeMessage] = useState(false)
  const [wrongNumberMessage, setWrongNumberMessage] = useState(false)
  const [callBackMessage, setCallBackMessage] = useState(false)
  const [, setIsloading] = useState(false)
  const [disableForm, setDisableForm] = useState(false)
  const [isDirty, setIsDirty] = useState(true)

  const { t, i18n } = props

  const query = new URLSearchParams(window.location.search);
  const dbid = query.get('dbid') && query.get('dbid').replace(/\//g, '')
  const lang = query.get('lang')



  useEffect(() => {
    return () => {
      window.location.reload()
    };
  }, []);


  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    setIsloading(true)
    Axios.get(`${HOST_API}/api/v1/CallTask/callTask?taskId=${dbid}`)
      .then(resp => {

        var data = resp.data[0]

        // console.info('data =>', data);


        var _data = {
          mobile: data.phone && String(data.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4")),
          currentcustomerName: data.name || "",
          NPS: data.payload.NPS,
          callDuration: data.callDuration || "",
          timeType: data.timeType || "",
          time: data.time || "",
          typeOfCustomer: data.payload && data.payload.changedContact && data.payload.changedContact.contactType ? data.payload.changedContact.contactType : "business",
          email: data.payload && data.payload.contact && data.payload.contact.mail ? data.payload.contact.mail : "",
          content: data.payload && data.payload.content && data.payload.content.HTML ? data.payload.content : "",
          contact: data.payload && data.payload.contact ? data.payload.contact && typeof data.payload.contact.mail !== "undefined" ? true : false : false,
          aggrement: String(data.payload && data.payload.permissions && data.payload.permissions.toContact ? data.payload.permissions.toContact : ""),
          complaint: data.customerComment || "",
          products: data.payload && data.payload.products ? data.payload.products : [],
          credit: data.payload && data.payload.credit ? data.payload.credit : null,
          lastName: data.payload && data.payload.original && typeof data.payload.original.f !== "undefined" ? data.payload.original.f : null,
          DOB: data.payload && data.payload.original && typeof data.payload.original.birthDate !== "undefined" ? data.payload.original.birthDate : "",
          fathersName: data.payload && data.payload.original && typeof data.payload.original.o !== "undefined" ? data.payload.original.o : null,
          perm_ind: data.payload && data.payload.original && typeof data.payload.original.perm_ind !== "undefined" ? data.payload.original.perm_ind : null,
          sms_perm_ind: data.payload && data.payload.original && typeof data.payload.original.sms_perm_ind !== "undefined" ? data.payload.original.sms_perm_ind : undefined,
          sms_phone: data.payload && data.payload.original && typeof data.payload.original.sms_phone !== "undefined" ? data.payload.original.sms_phone : undefined,
          companyName: data.payload && data.payload.company && data.payload.company.companyName ? data.payload.company.companyName : "",
          LK_reg: data.payload && data.payload.original && typeof data.payload.original.LK_reg !== "undefined" ? `${data.payload.original.LK_reg}` : "",
          currentCustomerName: data.name || "",
          promotionName: data.payload && data.payload.promotion && data.payload.promotion.PromotionName ? data.payload.promotion.PromotionName : "",
          promotionDiscount: data.payload && data.payload.promotion && data.payload.promotion.Offer_ind ? data.payload.promotion.Offer_ind : "",
          planTurnover: data.payload && data.payload.promotion && data.payload.promotion.Plan_turnover ? data.payload.promotion.Plan_turnover : "",
          factTurnover: data.payload && data.payload.promotion && data.payload.promotion.Fact_turnover ? data.payload.promotion.Fact_turnover : "",
          targetAchievement: data.payload && data.payload.promotion && data.payload.promotion.Target_achievement ? data.payload.promotion.Target_achievement : "",
          missingTurnover: data.payload && data.payload.promotion && data.payload.promotion.Missing_turnover ? data.payload.promotion.Missing_turnover : "",
          fulfillment: data.payload && data.payload.promotion && data.payload.promotion.Fulfillment ? data.payload.promotion.Fulfillment : "",
          timeselection: data.payload && data.payload.timeselection ? data.payload.timeselection : null,
          validTimeSelection: data.payload && data.payload.timeselection ? true : false,
          hideDoNotCallMeEverAgain: false,
          questions: data.payload && data.payload.questions ? data.payload.questions : null,
          bonusAmount: data.payload && data.payload.promotion && data.payload.promotion.bonus_amount && data.payload.promotion.bonus_amount ? data.payload.promotion.bonus_amount : "",
          bonusExpiration: data.payload && data.payload.promotion && data.payload.promotion.bonus_expiration && data.payload.promotion.bonus_expiration ? data.payload.promotion.bonus_expiration : "",
          bonusRegistration: data.payload && data.payload.promotion && data.payload.promotion.bonus_registration && data.payload.promotion.bonus_registration ? data.payload.promotion.bonus_registration : "",
          callBackButtons: data.payload && data.payload.call_back && data.payload.call_back.configuration ? data.payload.call_back.configuration : null
        }


        if (!_data.contact) {
          delete validationSchemaForReceiveInfo.fields.email
          delete validationSchemaForReceiveInfo.fields.aggrement
        }

        if (!_data.credit) {
          delete validationSchemaForReceiveInfo.fields.loanTerm
          delete validationSchemaForReceiveInfo.fields.requestedAmount
          delete validationSchemaForReceiveInfo.fields.lastName
          delete validationSchemaForReceiveInfo.fields.DOB
          // delete validationSchemaForReceiveInfo.fields.region
        }

        if (_data.LK_reg === 1) {
          delete validationSchemaForReceiveInfo.fields.email
        }

        if (!_data.questions) {
          delete validationSchemaForReceiveInfo.fields.questionsValues
        }
        if (!_data.validTimeSelection) {
          delete validationSchemaForReceiveInfo.fields.validTimeSelection
        }
        else {
          var days = []
          var validTimeSelection = false;
          var validTimeSelectionArray = Object.assign([], _data.timeselection.days)
          validTimeSelectionArray.forEach(time => {
            if (!time.to || time.to === "" || !time.from || time.from === "") {
              validTimeSelection = true
            }

            days.push({
              to: time.to || "",
              from: time.from || "",
              lines: [],
            })
          })

          _data.days = days
          _data.disableIntrestedBtn = validTimeSelection
        }


        setState(_data)
        setIsloading(false)

      }).catch(err => {

        setIsloading(false)
        setState({
          mobile: "",
          customerName: "",
          currentCustomerName: "",
          NPS: "",
          callDuration: "",
          timeType: "",
          time: "",
          changedContact: null,
          aggrement: "",
          typeOfCustomer: "business",
          email: "",
          perm_ind: "",
          sms_perm_ind: "",
          sms_phone: "",
          bonusAmount: "",
          bonusExpiration: "",
          bonusRegistration: "",
          contact: "",
          complaint: "",
          company: null,
          promotion: null,
          questions: null,
          validTimeSelection: true,
          content: ""
        })
      })
  }, [dbid, i18n, lang])


  const submitDelayCall = (value) => {
    var payload = {
      external_id: dbid,
      next_time_call: value
    }
    document.getElementById("isChange1").value = false;
    document.getElementById("isChange2").value = false;
    setIsDirty(false)
    Axios.post(`${HOST_API}/api/v1/CallTask/DelayCall/`, payload)
      .then(resp => {
        setDisableForm(true)
        setCallBackMessage({
          type: "success",
          text: t('save_msg')
        })
      })
      .catch(err => {
        setDisableForm(true)
        setCallBackMessage({
          type: "danger",
          text: t('error_msg')
        })
        console.info('submitDelayCall err => ', err);
      });

  }

  const submitWrongNumber = () => {
    var payload = {
      status: "17",
      task_id: dbid
    }
    document.getElementById("isChange1").value = false;
    document.getElementById("isChange2").value = false;
    setIsDirty(false)
    Axios.post(`${HOST_API}/api/v1/CallTask/TaskFinished/`, payload)
      .then(resp => {
        setDisableForm(true)
        setWrongNumberMessage({
          type: "success",
          text: t('save_msg')
        })
        window.open('', '_self').close();
      })
      .catch(err => {
        setDisableForm(true)
        setWrongNumberMessage({
          type: "danger",
          text: t('error_msg')
        })
        window.open('', '_self').close();
        console.info('submitWrongNumber err => ', err);
      });
  }

  const submitTaskFinished = (values) => {

    var aggrement = values.aggrement;
    var payload = {
      task_id: dbid,
      complain: values.complaint,
      complain_permission: values.canWeCallInvestigateReasons === true ? "1" : "0",
      // sms_phone: values.sms_phone !== null && values.sms_phone,
    }

    if (state.sms_perm_ind && values.sms_perm_ind !== null) {
      payload.sms_perm_ind = values.sms_perm_ind.toString()
    }

    if (state.sms_phone && values.sms_phone && values.sms_phone !== null) {
      payload.sms_phone = values.sms_phone
    }

    var questions = []
    setIsDirty(false)
    if (state.questions) {
      questions = values.questionsValues || []
      payload.questions = questions
    }

    if (state.timeselection) {
      payload.days = values.days || []
    }

    if (state.NPS) {
      payload.NPS = values.NPS
    }

    if (state.credit) {
      // payload.region = values.region
      payload.birthDate = values.DOB
      payload.lastName = values.lastName
      payload.fathersName = values.fathersName
      payload.loanTerm = parseInt(values.loanTerm)
      payload.requestedAmount = parseInt(values.requestedAmount)
    }

    switch (values.actionBtn) {
      case "interested":
        if (state.contact) {
          payload.purpose = values.typeOfCustomer
          payload.edit_email = values.email
          payload.permission_email = aggrement
        }
        payload.questions = questions
        if (values.email) {
          payload.status = "11"
        } else {
          payload.status = "10"
        }

        if (values.products && values.products.length) {
          var temp = []
          values.products.map(product => temp.push({ name: product.name, selected: typeof product.value !== "undefined" ? product.value : "" }))
          payload.products = temp;
        }
        break;
      case "notInterested":
        // eslint-disable-next-line
        state.timeselection && state.timeselection.days && state.timeselection.days.map(day => { day.to = ""; day.from = "" })
        payload.edit_email = values.email
        payload.purpose = values.typeOfCustomer
        payload.status = "16"
        payload.questions = questions
        payload.days = state.timeselection && state.timeselection.days ? state.timeselection.days : []
        break;
      case "doNotCallMeEverAgain":
        payload.status = "19"
        break;

      default:
        break;
    }
    Axios.post(`${HOST_API}/api/v1/CallTask/TaskFinished/`, payload)
      .then(resp => {
        setDisableForm(true)
        if (values.block && values.block === "time") {
          setTimeMessage({
            type: "success",
            text: t('save_msg')
          })

        } else {
          setMessage({
            type: "success",
            text: t('save_msg')
          })
        }
      })
      .catch(err => {
        setDisableForm(true)
        if (values.block && values.block === "time") {
          setTimeMessage({
            type: "danger",
            text: t('error_msg')
          })
        } else {
          setMessage({
            type: "danger",
            text: t('error_msg')
          })
        }
        console.info('submitTaskFinished err => ', err);
      });
  }


  const requireQuestionValidation = (value, required = false) => {

    var error = "";
    if (Boolean(required) && !value) {
      error = 'Required';
    }

    return error;
  }



  const ifAnswerTextBoxEnable = (score, enableBelow) => {
    if (!score || score <= 0) {
      return true
    } else {
      return enableBelow >= score
    }
  }

  return (
    <div>

      <div className="card">
        <div className="card-body">
          {<Formik
            enableReinitialize
            initialValues={
              {
                companyName: state.companyName || "",
                LK_reg: state.LK_reg || "",
                changedContact: state.changedContact,
                promotionName: state.promotionName,
                promotionDiscount: state.promotionDiscount,
                products: state.products,
                credit: state.credit,
                lastName: state.lastName,
                fathersName: state.fathersName,
                loanTerm: state.credit ? state.credit.loanTerm : "",
                requestedAmount: state.credit ? state.credit.requestedAmount ? state.credit.requestedAmount : "" : "",
                region: state.credit ? state.credit.region : "",
                DOB: state.credit ? state.DOB.split(" ")[0] : "",
                mobile: state.mobile,
                currentCustomerName: state.currentCustomerName,
                callDuration: state.callDuration,
                NPS: state.NPS,
                timeType: "hours",
                time: "",
                typeOfCustomer: state.typeOfCustomer,
                aggrement: state.aggrement,
                customerName: state.customerName,
                planTurnover: state.planTurnover,
                factTurnover: state.factTurnover,
                targetAchievement: state.targetAchievement,
                missingTurnover: state.missingTurnover,
                fulfillment: state.fulfillment,
                email: state.email || "",
                perm_ind: state.perm_ind || "",
                sms_perm_ind: state.sms_perm_ind,
                sms_phone: state.sms_phone,
                bonusAmount: state.bonusAmount || "",
                bonusExpiration: state.bonusExpiration || "",
                bonusRegistration: state.bonusRegistration || "",
                contact: state.contact,
                complaint: state.complaint,
                timeselection: state.timeselection,
                questions: state.questions,
                callBackButtons: state.callBackButtons,
                canWeCallInvestigateReasons: false,
                disableIntrestedBtn: state.disableIntrestedBtn,
                validTimeSelection: state.validTimeSelection,
                questionsValues: state.questions,
                content: state.content,
                days: state.days,
              }}
            validationSchema={validationSchemaForReceiveInfo}
            validate={(values) => {

            }}
            onSubmit={(values, actions) => {

              if (!disableForm) {
                submitTaskFinished(values)
                // actions.setSubmitting(false);
              }
            }}
          >
            {({ values, errors, touched, handleSubmit, validateField, handleChange, handleBlur, setFieldValue, setFieldError, isValid, dirty, isSubmitting, ...rest }) =>
              <form onSubmit={handleSubmit}>
                <input type="hidden" value={isDirty ? "true" : "false"} id="isChange1" />
                <Grid container direction={"column"}>
                
                  {/* HTML Block */}
                  {values.content && values.content.HTML &&
                    <Grid item className="mt-3 mb-3">
                      <div className="card">
                        <div className="cardHeading">
                          <Typography className="text-monospace" variant={"h6"}>{values.content.title || ""}</Typography>
                        </div>
                        <div className="card-body pb-0">
                          <div dangerouslySetInnerHTML={{ __html: values.content.HTML || "" }}></div>

                        </div>
                      </div>
                    </Grid>
                  }

                  {/* block Customer data */}
                  <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('customer_data')}</Typography>
                      </div>
                      <div className="card-body pb-2">
                        <Grid container alignItems={"flex-start"}>
                          <Grid item xs={12} className="altrow" >
                            <Grid container justify={"space-between"}>
                              <Grid item >
                                <label>{t('company_name')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <Typography variant={"subtitle1"}>{values.companyName}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="altrow" >
                            <Grid container justify={"space-between"}>
                              <Grid item >
                                <label>{t('current_customer_name')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <Typography variant={"subtitle1"}>{values.currentCustomerName}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="altrow" >
                            <Grid container justify={"space-between"}>
                              <Grid item >
                                <label>{t('phone_of_the_customer')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <div>
                                  <input
                                    type="text"
                                    name="mobile"
                                    id="textbox"
                                    readOnly
                                    value={values.mobile}
                                    autoComplete={"off"}
                                    placeholder="8 (xxx) xxx-xxxx"
                                    className={`form-control phone_us ${errors.mobile && touched.mobile && "invalid"}`}
                                  />
                                </div>
                                <span className="" style={{ color: "red" }}> {errors.mobile && touched.mobile && errors.mobile} </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          {values.LK_reg && <Grid item xs={12} className="altrow" >
                            <Grid container justify={"space-between"}>
                              <Grid item >
                                <label>{t('personnal_cabinet')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right" >
                                <Typography variant={"subtitle1"}>{values.LK_reg}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}
                          <Grid item xs={12} className="mt-3">
                            <Grid container alignItems={"flex-end"} direction={"column"}>
                              <Grid item>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    if (!disableForm) {
                                      submitWrongNumber()
                                    } else {
                                      e.preventDefault()
                                    }
                                  }
                                  }
                                  style={{ padding: 8 }}
                                  className={`btn btn-danger text-right ${disableForm && `disabled`}`}>{t('wrong_number')}</button>
                              </Grid>
                              <Grid item>
                                {wrongNumberMessage && wrongNumberMessage.text &&
                                  <span className="mt-3" style={{ color: wrongNumberMessage.type === "success" ? "#4caf50" : "#f00" }}>{wrongNumberMessage.text}</span>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>

                  {/* block Call Back later */}

                  <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('call_back_later')}</Typography>
                      </div>
                      <div className="card-body">
                        <Grid container alignItems={"flex-start"}>
                          <Grid item xs={12}>
                            <Grid container justify={"flex-start"} spacing={1} alignItems={"flex-start"}>
                              {values.callBackButtons ? values.callBackButtons.map((btn, index) => <Grid key={index} item>
                                <button type="button" onClick={(e) => {
                                  if (!disableForm) { submitDelayCall(btn.delay); } else { e.preventDefault() }
                                }} style={{ padding: 8 }} className={`btn btn-primary  ${disableForm && `disabled`}`}>{btn.label}</button>
                              </Grid>) :
                                CALL_NOW_BUTTON.map((btn, index) => <Grid key={index} item>
                                  <button type="button" onClick={(e) => {
                                    if (!disableForm) { submitDelayCall(btn.delay); } else { e.preventDefault() }
                                  }} style={{ padding: 8 }} className={`btn btn-primary  ${disableForm && `disabled`}`}>{t(btn.id)}</button>
                                </Grid>)
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                        {callBackMessage && callBackMessage.text &&
                          <span className="mt-3" style={{ color: callBackMessage.type === "success" ? "#4caf50" : "#f00" }}>{callBackMessage.text}</span>
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {
                  // console.info('xyz errors => ', errors)

                }
                {
                  // console.info('xyz values => ', values)

                }
                <input type="hidden" value={isDirty ? "true" : "false"} id="isChange2" />
                <Grid container direction={"column"}>



                  {/* block Schedule */}
                  {(values.timeselection) && <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('schedule')}</Typography>
                      </div>
                      <div className="card-body pb-0">
                        <ScheduleTable
                          setFieldError={setFieldError}
                          disableIntrestedBtn={values.disableIntrestedBtn}
                          validTimeSelection={values.disableIntrestedBtn}
                          data={values.timeselection}
                          setFieldValue={setFieldValue}
                          t={t}
                          disableForm={disableForm} />
                        {/* <ScheduleTableOld data={values.timeselection} setFieldValue={setFieldValue} t={t} disableForm={disableForm} /> */}
                      </div>
                      <div className="card-body pb-0 pt-0">
                        <span className="mt-3" style={{ color: "red" }}> {values.disableIntrestedBtn && values.disableIntrestedBtn && "Invalid time schedule"} </span>
                        {message && message.text && message.type !== "success" &&
                          <span className="mt-3" style={{ color: message.type === "success" ? "#4caf50" : "#f00" }}>{message.text}</span>
                        }
                      </div>
                    </div>


                  </Grid>}

                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  {/* block Questions */}
                  {(values.questions && values.questions.length > 0) && <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('questions')}</Typography>
                      </div>
                      <div className="card-body pb-0">
                        <Grid container alignItems={"flex-start"} direction={"column"}>
                          {
                            values.questions && values.questions.map((question, index) => (
                              <Grid item xs={12} className="altrow w-100" >
                                <Grid container justify={"space-between"}>
                                  <Grid item >
                                    <label>{question.name}</label>
                                  </Grid>
                                  <Grid item xs={7} style={{ textAlign: "right" }}>
                                    <Field
                                      disabled={disableForm ? true : false}
                                      validate={(value) => requireQuestionValidation(value, question.mandatory)}
                                      component="select"
                                      name={`questionsValues[${index}].selection`}
                                      onChange={handleChange}
                                      onBlur={(e) => handleBlur(e)}
                                      className={`form-control`}
                                      defaultValue={question.selection || ""}
                                    // value={question.selection || ""}
                                    >
                                      <option value={""} disabled={false}>Select Answer</option>
                                      {
                                        question.choices.map((opt, index) =>
                                          <option value={opt}>{opt}</option>
                                        )
                                      }

                                    </Field>
                                    <span className="" style={{ color: "red" }}> {errors.questionsValues && errors.questionsValues[index] && errors.questionsValues[index].selection && errors.questionsValues[index].selection} </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                          }
                        </Grid>
                      </div>
                    </div>


                  </Grid>}


                  {/* block credit information */}
                  {(values.credit) && <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('credit')}</Typography>
                      </div>
                      <div className="card-body pb-0">
                        <Grid container alignItems={"flex-start"} className="received">
                          <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('lastName')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.lastName}
                                  autoComplete={"off"}
                                  placeholder="Last Name"
                                  className={`form-control phone_us ${errors.lastName && touched.lastName && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.lastName && touched.lastName && errors.lastName} </span>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('fathersName')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="fathersName"
                                  id="fathersName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.fathersName}
                                  autoComplete={"off"}
                                  placeholder="Father Name"
                                  className={`form-control phone_us ${errors.fathersName && touched.fathersName && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.fathersName && touched.fathersName && errors.fathersName} </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('loanTerm')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="loanTerm"
                                  id="loanTerm"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.credit.loanTerm}
                                  autoComplete={"off"}
                                  placeholder="Loan Term"
                                  className={`form-control phone_us ${errors.loanTerm && touched.loanTerm && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.loanTerm && touched.loanTerm && errors.loanTerm} </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('requestedAmount')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="requestedAmount"
                                  id="requestedAmount"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.requestedAmount}
                                  autoComplete={"off"}
                                  placeholder="Requested Amount"
                                  className={`form-control phone_us ${errors.requestedAmount && touched.requestedAmount && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.requestedAmount && touched.requestedAmount && t('amountValidationFailedMessage')} </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('region')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="region"
                                  id="region"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.credit.region}
                                  autoComplete={"off"}
                                  placeholder="Region"
                                  className={`form-control phone_us ${errors.region && touched.region && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.region && touched.region && errors.region} </span>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('DOB')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="DOB"
                                  id="DOB"
                                  // readOnly={true}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.DOB}
                                  autoComplete={"off"}
                                  placeholder="Birth of date"
                                  className={`form-control phone_us ${errors.DOB && touched.DOB && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.DOB && touched.DOB && errors.DOB} </span>
                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                        {/* {message && message.text &&
                          <span className="mt-3" style={{ color: message.type === "success" ? "#4caf50" : "#f00" }}>{message.text}</span>
                        } */}
                      </div>
                    </div>
                  </Grid>
                  }

                  {/* block Received information */}
                  {(values.contact) && <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('received_information')}</Typography>
                      </div>
                      <div className="card-body pb-0">
                        <Grid container alignItems={"flex-start"} className="received">

                          {values.promotionName && <Grid item xs={12} className="altrow" style={{ textAlign: "start" }} >
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('promotion_name')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                {(values.promotionName) || "N/A"}
                              </Grid>
                            </Grid>
                          </Grid>}

                          {values.promotionDiscount && <Grid item xs={12} className="altrow" style={{ textAlign: "right" }}>
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('discount')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                <Typography variant={"subtitle1"}>{values.promotionDiscount || "NA"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}

                          {values.planTurnover && <Grid item xs={12} className="altrow" style={{ textAlign: "right" }}>
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('plan_turnover')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                <Typography variant={"subtitle1"}>{values.planTurnover || "NA"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}

                          {values.factTurnover && <Grid item xs={12} className="altrow" style={{ textAlign: "right" }}>
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('fact_turnover')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                <Typography variant={"subtitle1"}>{values.factTurnover || "NA"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}

                          {values.targetAchievement && <Grid item xs={12} className="altrow" style={{ textAlign: "right" }}>
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('target_achievement')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                <Typography variant={"subtitle1"}>{values.targetAchievement || "NA"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}

                          {values.missingTurnover && <Grid item xs={12} className="altrow" style={{ textAlign: "right" }}>
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('missing_turnover')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                <Typography variant={"subtitle1"}>{values.missingTurnover || "NA"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}

                          {values.fulfillment && <Grid item xs={12} className="altrow" style={{ textAlign: "right" }}>
                            <Grid container justify={"space-between"} alignItems={"flex-start"}>
                              <Grid item style={{ textAlign: "start" }} >
                                <label>{t('fulfillment')}</label>
                              </Grid>
                              <Grid item style={{ textAlign: "right" }} >
                                <Typography variant={"subtitle1"}>{values.fulfillment || "NA"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}

                          <Grid item xs={12} className="altrow">
                            <Grid container justify={"space-between"}>
                              <Grid item >
                                <label>{t('type_of_customer')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <label style={{ cursor: "pointer" }} htmlFor="business">{t('company')}</label>&nbsp;
                                <input
                                  type="radio"
                                  id="business"
                                  name="typeOfCustomer"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={disableForm && true}
                                  // defaultChecked={values.typeOfCustomer === "business"}
                                  value="business" /> &nbsp;&nbsp;&nbsp;
                                <label style={{ cursor: "pointer" }} htmlFor="personal">{t('private_person')}</label>&nbsp;
                                <input
                                  type="radio"
                                  id="personal"
                                  name="typeOfCustomer"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={disableForm && true}
                                  // defaultChecked={values.typeOfCustomer === "personal"}
                                  value="personal" /> &nbsp;
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="altrow">
                            <Grid container justify={"space-between"}>
                              <Grid item >
                                <label>{t('agreement_for_communication')}</label>
                              </Grid>
                              <Grid item >
                                <label style={{ cursor: "pointer" }} htmlFor="yes"> {t('yes')}</label>&nbsp;
                                <input
                                  type="radio"
                                  name="aggrement"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={disableForm && true}
                                  id="yes"
                                  defaultChecked={values.aggrement === "1"}
                                  value={"1"} />&nbsp;&nbsp;&nbsp;

                                <label style={{ cursor: "pointer" }} htmlFor="no"> {t('no')}</label>&nbsp;
                                <input type="radio"
                                  name="aggrement"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={disableForm && true}
                                  id="no"
                                  defaultChecked={values.aggrement === "0"}
                                  value={"0"} />
                                &nbsp;
                                <br /><span className="" style={{ color: "red" }}> {errors.aggrement && touched.aggrement && errors.aggrement} </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} className="">
                              <Grid item xs={3} style={{ textAlign: "left" }}>
                                <label>{t('email')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <input
                                  type="text"
                                  name="email"
                                  readOnly={values.LK_reg === 1 ? true : false}
                                  onChange={values.LK_reg === 0 && handleChange}
                                  onBlur={handleBlur}
                                  disabled={(values.LK_reg === 1 || disableForm) && true}
                                  value={values.email}
                                  autoComplete={"off"}
                                  className={`form-control ${errors.email && touched.email && "invalid"}`}
                                  placeholder="Email address" />
                                <span className="" style={{ color: "red" }}> {errors.email && touched.email && errors.email} </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          {values.sms_perm_ind !== undefined && <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('AgreementForSendSMS')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <label style={{ cursor: "pointer" }} htmlFor="yes"> {t('yes')}</label>&nbsp;
                                <input
                                  type="radio"
                                  name="sms_perm_ind"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={disableForm && true}
                                  id="yes"
                                  // eslint-disable-next-line
                                  defaultChecked={values.sms_perm_ind == "1"}
                                  value={"1"} />&nbsp;&nbsp;&nbsp;

                                <label style={{ cursor: "pointer" }} htmlFor="no"> {t('no')}</label>&nbsp;
                                <input type="radio"
                                  name="sms_perm_ind"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={disableForm && true}
                                  id="no"
                                  // eslint-disable-next-line
                                  defaultChecked={values.sms_perm_ind == "0"}
                                  value={"0"} />
                                <span style={{ color: "red" }}> {errors.sms_perm_ind && touched.sms_perm_ind && errors.sms_perm_ind} </span>
                              </Grid>
                            </Grid>
                          </Grid>}
                          {values.sms_phone !== undefined && <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('SMSPhone')}</label>
                              </Grid>
                              <Grid item xs={7} className="text-right">
                                <input
                                  type="text"
                                  name="sms_phone"
                                  id="textbox"
                                  defaultValue={values.sms_phone}
                                  autoComplete={"off"}
                                  placeholder="8 (xxx) xxx-xxxx"
                                  className={`form-control phone_us ${errors.sms_phone && touched.sms_phone && "invalid"}`}
                                />
                                <span style={{ color: "red" }}> {errors.sms_phone && touched.sms_phone && errors.sms_phone} </span>
                              </Grid>
                            </Grid>
                          </Grid>}
                          {/* {
                            JSON.stringify(values, null, 2)
                          } */}
                          {/* bonus */}
                          {/* bonusAmount
                              bonusExpiration
                              bonusRegistration */}
                          {values.bonusAmount && <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('bonusAmount')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <Typography variant={"subtitle1"}>{values.bonusAmount}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}
                          {values.bonusExpiration && <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('bonusExpiration')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <Typography variant={"subtitle1"}>{values.bonusExpiration}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}
                          {values.bonusRegistration && <Grid item xs={12} className="altrow mb-2">
                            <Grid container justify={"space-between"} alignItems={"baseline"} >
                              <Grid item xs={3} className="text-left">
                                <label>{t('bonusRegistration')}</label>
                              </Grid>
                              <Grid item xs={7} style={{ textAlign: "right" }}>
                                <Typography variant={"subtitle1"}>{values.bonusRegistration}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>}


                          {/* Action Button */}
                          {/* <Grid item className="mt-3 mb-2">
                            <Grid container justify={"flex-end"} spacing={1}>
                              <Grid item>
                                <button type="submit" onClick={(e) => {
                                  if (!disableForm) {
                                    setFieldValue('actionBtn', 'interested')
                                    setIsDirty(true)
                                  }
                                  else {
                                    e.preventDefault()
                                  }
                                }} style={{ padding: 8 }} className={`btn btn-secondary ${disableForm && `disabled`}`}>{t('interested')}</button>
                              </Grid>
                              <Grid item>
                                <button type="submit" onClick={(e) => {
                                  if (!disableForm) {
                                    setFieldValue('actionBtn', 'notInterested')
                                    setIsDirty(true)
                                  }
                                  else {
                                    e.preventDefault()
                                  }
                                }} style={{ padding: 8 }} className={`btn btn-secondary ${disableForm && `disabled`}`}>{t('not_interested')}</button>
                              </Grid>
                              <Grid item>
                                <button type="submit" onClick={(e) => {
                                  if (!disableForm) {
                                    setFieldValue('actionBtn', 'doNotCallMeEverAgain')
                                    setIsDirty(true)
                                  }
                                  else {
                                    e.preventDefault()
                                  }
                                }} style={{ padding: 8 }} className={`btn btn-secondary ${disableForm && `disabled`}`}>{t('do_not_call_me_ever_again')}</button>
                              </Grid>
                            </Grid>

                          </Grid> */}

                        </Grid>
                        {/* {message && message.text &&
                          <span className="mt-3" style={{ color: message.type === "success" ? "#4caf50" : "#f00" }}>{message.text}</span>
                        } */}
                      </div>
                    </div>
                  </Grid>
                  }

                  {/* product information */}
                  {values.products && values.products.length > 0 && <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('products')}</Typography>
                      </div>
                      <div className="card-body">
                        <table border="1" className="table table-striped">
                          <tr>
                            <th>{t('category')}</th>
                            <th>{t('name')}</th>
                            <th>{t('action')}</th>
                          </tr>

                          {values.products.map((product, index) =>
                            <tr>
                              <td><span>{product.category}</span></td>
                              <td><span>{product.name}</span></td>
                              <td><span>{product.selectable &&
                                <input
                                  disabled={disableForm && true}
                                  type="checkbox"
                                  id="chkbox"
                                  defaultChecked={product.value}
                                  name={`products[${index}].value`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={true} />
                              } </span></td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                  </Grid>}


                  {/* block Feedback */}
                  {values.NPS &&
                    <Grid item className="mt-3 mb-3">
                      <div className="card">
                        <div className="cardHeading">
                          <Typography className="text-monospace" variant={"h6"}>{t('feedback')}</Typography>
                        </div>
                        <div className="card-body">
                          <Grid container alignItems={"flex-start"}>
                            <Grid item xs={12} className="mt-3">
                              <Grid container justify={"space-between"} direction={"column"}>
                                <Grid item >
                                  {/* <label>{`Feedback`}</label> */}
                                  {/* <label>{`On a scale from 1 to 10, how did you like it your purchase on July 1st?`}</label> */}
                                </Grid>
                                {/* <Grid item xs={7} style={{ textAlign: "right" }}>
                                  <input
                                    type="text"
                                    name="feedbackAnswer"
                                    id="feedbackAnswer"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={values.feedbackAnswer}
                                    autoComplete={"off"}
                                    placeholder="feedback Answer"
                                    className={`form-control`}
                                  />
                                </Grid> */}
                              </Grid>
                              <Grid container justify={"space-between"}>
                                <Grid item xs={5}>
                                  <label>{values.NPS.configuration && values.NPS.configuration[0] && values.NPS.configuration[0].mainLabel}</label>
                                </Grid>
                                <Grid item xs={7} style={{ textAlign: "right" }}>
                                  <input
                                    type="number"
                                    name="NPS.score"
                                    id="NPS.score"
                                    max={values.NPS.configuration[0].max}
                                    min={values.NPS.configuration[0].min}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={"test"}
                                    autoComplete={"off"}
                                    placeholder="Score"
                                    className={`form-control`}
                                  />
                                </Grid>
                              </Grid>
                              <pre>
                                {
                                  JSON.stringify(values, null, 2)
                                }
                              </pre>
                              {values.NPS.details.map((element, index) =>
                                <Grid container justify={"space-between"} className="mt-2" >
                                  <Grid item xs={5}>
                                    <label>{element.label}</label>
                                  </Grid>
                                  <Grid item xs={7} style={{ textAlign: "right" }}>
                                    {/* {element.enableBelow} */}
                                    <input
                                      type="text"
                                      disabled={ifAnswerTextBoxEnable(values.NPS.score, element.enableBelow)}
                                      name={`NPS.details[${index}].value`}
                                      id={`NPS.details[${index}].value`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      autoComplete={"off"}
                                      className={`form-control`}
                                    />
                                  </Grid>
                                </Grid>
                              )}

                              {/* <Grid item xs={12}>
                                <br />
                                {
                                  values.NPS && values.NPS.configuration && values.NPS.configuration[0] && values.NPS.configuration[0].event
                                }
                                {
                                  values.NPS && values.NPS.configuration && values.NPS.configuration[0] && values.NPS.configuration[0].mainLabel
                                }
                                <Grid container>
                                  <Grid></Grid>
                                  <Grid></Grid>
                                </Grid>


                              </Grid> */}
                              <Grid item>

                              </Grid>
                              {/* </Grid> */}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  }

                  <Grid item className="mt-3 mb-2 ">
                    <Grid container spacing={1}>
                      <Grid item>
                        <button type="submit"
                          onClick={(e) => {

                            if (values.disableIntrestedBtn) {
                              setTimeMessage({
                                type: "success",
                                text: "Invalid time"
                              })
                            }
                            if (!disableForm && !values.disableIntrestedBtn) {
                              setFieldValue('actionBtn', 'interested')
                              setIsDirty(true)
                              // if (isSubmitting) {
                              //   submitTaskFinished({ ...values, actionBtn: `interested` })
                              // }
                            }
                            else {
                              e.preventDefault()
                            }
                          }}
                          style={{ padding: 8 }} className={`btn btn-secondary btn-light-green ${(values.disableIntrestedBtn || disableForm) && `disabled`}`}>{t('interested')}</button>

                        {/* {
                          dirty ? "true" : "false"
                        }
                        {
                          isValid ? "isValid true" : "isValid false"
                        } */}


                      </Grid>
                      <Grid item>
                        <button type="submit" onClick={(e) => {
                          if (!disableForm && isValid) {
                            setFieldValue('actionBtn', 'notInterested')
                            setFieldValue('aggrement', "1")
                            // setFieldValue('validTimeSelection', true)
                            setIsDirty(true)

                            // if (isSubmitting) {
                            if (Object.keys(errors).length <= 0) {
                              e.preventDefault()
                              submitTaskFinished({ ...values, validTimeSelection: true, actionBtn: `notInterested` })
                            }
                            // }
                          }
                          else {
                            e.preventDefault()
                          }
                        }} style={{ padding: 8 }} className={`btn btn-secondary btn-light-red ${(disableForm || isSubmitting) && `disabled`}`}>{t('not_interested')}</button>
                      </Grid>
                      {values.contact && <Grid item>
                        <button type="button" onClick={(e) => {
                          if (!disableForm && isValid) {
                            setFieldValue('actionBtn', 'doNotCallMeEverAgain')
                            setFieldValue('aggrement', "1")
                            // setFieldValue('validTimeSelection', true)
                            setIsDirty(true)

                            // if (isSubmitting) {
                            if (Object.keys(errors).length <= 0) {
                              submitTaskFinished({ ...values, validTimeSelection: true, actionBtn: `doNotCallMeEverAgain` })
                            }
                            // }
                          }
                          else {
                            e.preventDefault()
                          }
                        }} style={{ padding: 8 }} className={`btn btn-secondary ${(disableForm || isSubmitting) && `disabled`}`}>{t('do_not_call_me_ever_again')}</button>
                      </Grid>}

                      {state.hideDoNotCallMeEverAgain && <Grid item>
                        <button type="submit" onClick={(e) => {
                          if (!disableForm) {
                            setFieldValue('actionBtn', 'doNotCallMeEverAgain')
                            // document.getElementById("isChange1").value = false;
                            // document.getElementById("isChange2").value = false;
                            setIsDirty(true)
                          }
                          else {
                            e.preventDefault()
                          }
                        }} style={{ padding: 8 }} className={`btn btn-secondary ${disableForm && `disabled`}`}>{t('do_not_call_me_ever_again')}</button>
                      </Grid>
                      }

                      {/* <pre>
                        {
                          JSON.stringify(rest, null, 2)
                        }
                      </pre> */}

                    </Grid>


                    {message && message.text &&
                      <span className="mt-3" style={{ color: message.type === "success" ? "#4caf50" : "#f00" }}>{message.text}</span>
                    }
                  </Grid>





                  {/* block Complains */}
                  <Grid item className="mt-3 mb-3">
                    <div className="card">
                      <div className="cardHeading">
                        <Typography className="text-monospace" variant={"h6"}>{t('complains')}</Typography>
                      </div>
                      <div className="card-body pb-0">
                        <Grid container alignItems={"flex-start"}>
                          <Grid item xs={12} className="mt-3">
                            <Grid item xs={12} style={{ textAlign: "right" }}>
                              <textarea
                                readOnly={disableForm}
                                name="complaint"
                                className="form-control"
                                onChange={handleChange}
                                value={values.complaint}
                                onBlur={handleBlur}
                              >{values.complaint}</textarea>
                              <span className="" style={{ color: "red" }}> {errors.complaint && touched.complaint && errors.complaint} </span>
                            </Grid>
                            <Grid item style={{ textAlign: "right" }}>
                              <input
                                disabled={disableForm && true}
                                type="checkbox"
                                id="chkbox"
                                name="canWeCallInvestigateReasons"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={true} /> &nbsp;
                              <label htmlFor="chkbox">{t('can_we_call_to_investigate_reasons')}</label>
                            </Grid>
                            {/* </Grid> */}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>

                </Grid>
                {/* </div>
            </div> */}
              </form>
            }
          </Formik >
          }
        </div>
      </div >
      <Dialog open={open} style={{ width: "100%" }} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('feedback')}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <textarea style={{ width: 500 }} rows={3} cols={3} className="form-control" name="feedback" placeholder="Enter feedback" >

          </textarea>
        </DialogContent>
        <DialogActions>
          <button type="button" onClick={() => setOpen(!open)} className="btn btn-secondary mr-3">Cancel</button>
          <button type="submit" onClick={() => setOpen(!open)} style={{ padding: 8 }} className="btn btn-primary mr-3">{"Submit"}</button>
        </DialogActions>
      </Dialog>
    </div >
  )
}


export default withTranslation("translations")(Call);
