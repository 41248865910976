import "./Sidebar.scss";

import { PERMISSION_LEVEL_READ_ONLY, PERMISSION_LEVEL_READ_WRITE, SIDEBAR_MENU_ACCESS_FUNCTION } from "../constant";
import React, { useContext } from "react";
import { find, get } from "lodash";

import { AppContext } from "../context/AppContext";
import LogoIcon from "../images/logo.png";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { renderFormatMsg } from "../shared/funs"
import { withRouter } from "react-router-dom";

const Sidebar = props => {
  const { open, handleopen } = props;
  const { userDetails } = useContext(AppContext);


  const CheckMenuPermission = (key) => {

    let permission = true
    let dataArray = get(userDetails, "accesses");

    let accessDetail = find(dataArray, (o) => SIDEBAR_MENU_ACCESS_FUNCTION[key] === o.function)

    if (accessDetail && accessDetail.accessLevel === PERMISSION_LEVEL_READ_WRITE) {
      permission = true;
    } else if (accessDetail && accessDetail.accessLevel === PERMISSION_LEVEL_READ_ONLY) {
      permission = false;
    }
    return permission;
  }

  const MENU_LIST = [
    {
      to: "/overview",
      id: "sidebar.overview",
      default: "Overview",
      classNames: "icon-overview",
      isVisible: CheckMenuPermission("VIEW_OVERVIEW")
    },
    {
      to: "/open-recruiting-tasks",
      id: "OpenRecruitingTasks",
      default: "Open Recruiting Tasks",
      classNames: "icon-settings",
      isVisible: CheckMenuPermission("OPENRECRUITINGTASKS")
    },
    {
      to: "/unfinished-tasks",
      id: "UnfinishedTasks",
      default: "Unfinished Tasks",
      classNames: "icon-close",
      isVisible: CheckMenuPermission("UNFINISHEDTASKS")
    }
  ]

  return (
    <Navbar expand="true" className="sliderbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => handleopen(!open)} />
      <NavLink className="nav-link" to="/">
        <Navbar.Brand href="#">
          <img src={LogoIcon} alt="LogoIcon" />
          <h3> {renderFormatMsg("Heading", "TELETEL")} </h3>
        </Navbar.Brand>
      </NavLink>
      <Navbar.Collapse id="basic-navbar-nav">
        <Scrollbars autoHide style={{ width: "100%" }}>
          <ul>
            {MENU_LIST.map((menu, index) =>
              menu.isVisible && <li key={index}>
                <NavLink className="nav-link" to={menu.to}>
                  <i className={menu.classNames}></i>
                  <span> {renderFormatMsg(menu.id, menu.default)} </span>
                </NavLink>
              </li>)}
          </ul>
        </Scrollbars>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default React.memo(withRouter(Sidebar));
