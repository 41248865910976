import { APIResponse } from "../../repoTypes";
import { CALL_TASK } from "../endpoint";
import { CancelTokenSource } from "axios";
import { RequestParams } from "../types";
import { UnfinishedTaskPagination } from "v2/core/apiTypes";
import { get } from "services";

export const getUnfinishedTasksFromRepo = (
  params: RequestParams,
  cancelToken?: CancelTokenSource
): APIResponse<UnfinishedTaskPagination> =>
  get(CALL_TASK.getUnfinishedTasks, params);
