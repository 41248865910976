import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { addFieldToReport, deleteFieldFromReport, getButtonStructureFieldsById, getReportFields, updateReportFieldOrder } from '../../services/toolsAndSettingsPagesApi';
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastSuccess, } from '../../shared/funs';
import { get, sortBy } from 'lodash';

import { AppContext } from '../../context/AppContext';
import CustomButton from '../../components/Buttons/CustomButton';
import Error from "../../components/Error/Error";
import Modal from '../../components/Modal/index';
import { PAGE_SIZE } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { confirmAlert } from 'react-confirm-alert'; // Import
import queryString from "query-string";
import useEditFieldFromReport from './services/useEditFieldFromReport';
import { useLocation } from 'react-router-dom';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  position: "fixed !important"
});

const getListStyle = isDraggingOver => ({
  padding: 8,
  width: 250
});

const queryAttr = "data-rbd-drag-handle-draggable-id";




const HandleReportDefinitionButton = (props) => {
  const location = useLocation()

  const [reportFields, setReportFields] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPageForModal, setTotalPageForModal] = useState(0)
  const [pageIndexForModal, setPageIndexForModal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [, setPageReadOnly] = useState({ read: true, write: false })
  const [openModal, setOpenModal] = useState(false)
  const [reportFieldsToAdd, setReportFieldsToAdd] = useState([])
  const [selectedFieldFromModal, setSelectedFieldFromModal] = useState([])
  const {redirectToEditField}= useEditFieldFromReport()
  const parsed = queryString.parse(location.search);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setReportFields(items => reorder(items, result.source.index, result.destination.index));
  };


  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
   
    var _result = result.map((o, index) => { return { ...o, sequence: index + 1 } })
    reorderHandler(_result)

    return _result;
  };


  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
   
   
  };
  let dataArray = get(userDetails, "accesses")
  
  const getData = useCallback(async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      page: page + 1,
      display: 9999999,
      reportId: props.match.params.id,
      search
    }
    getButtonStructureFieldsById(param).then(res => {
      setLoading(false)

      var data = sortBy(res.reportColumnRes, (e) => { return e.sequence })
      var allFieldId = [];
      data = data.map((o, index) => { allFieldId.push(o.fieldId); return { ...o } })
      setSelectedFieldFromModal(allFieldId)

      setReportFields(data)
    }).catch(() => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setReportFields([])
    })
  }, [props.match.params.id])
  
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_CONSTANTS").screenId))
  }, [dataArray])

  useEffect(() => {
    getData({ page: 0 })
  }, [getData])


  const getDataForModal = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      page: page + 1,
      display: PAGE_SIZE,
      search
    }
    getReportFields(param).then(res => {
      setLoading(false)
      setReportFieldsToAdd(res.reportfield)
      setTotalPageForModal(res.totalPage)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setReportFieldsToAdd([])
    })
  }



  const removeFieldFromReport = (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteFieldFromReport({ key: "columnId", id }).then(resp => {
              toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted successfully"))
              getData({ page: 0 })
              setLoading(false)
            }).catch(err => {
              setLoading(false)
            })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }


  const reorderHandler = (data) => {
    var payload = data.map(o => { return { sequence: o.sequence, columnId: o.columnId } })

    updateReportFieldOrder({ seqanceUpdates: payload }).then(resp => {
      getData({ page: 0, search: searchValue })
    }).catch(err => {
    })
  }

  const fetchDataForModalField = (state, instance) => {
    getDataForModal({ page: state.page, search: searchValue })
    setPageIndexForModal(state.page)
  }

  const SearchHandlerForSku = (e) => {
    setPageIndexForModal(0); getDataForModal({ page: 0, search: e.target.value })
  }


  const handleAddedReportField = (data) => {
    var _selectedFieldFromModal = [...new Set([...selectedFieldFromModal, data.fieldId])]
    setSelectedFieldFromModal(_selectedFieldFromModal)
    addFieldToReport({
      reportId: props.match.params.id,
      fieldId: data.fieldId,
    }).then(resp => {
      getData({ page: 0 })
    }).catch(err => {
      console.info('handleAddedReportField err => ', err);
    })
  }


  const columnsForFieldModal = [
    {
      Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
      accessor: 'name',
      minWidth: 150,
      maxWidth: 350,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Title", "Title")}</div>,
      accessor: 'title',
      minWidth: 120,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: renderFormatMsg("Add Field", "Add Field"),
      resizable: false,
      width: 120,
      Cell: props => <button onClick={() => handleAddedReportField(props.original)} className={`${checkIfAlreadyAddedField(props.original.fieldId) && `already-added`} addbtn edit-icon addbtn-style`}>{checkIfAlreadyAddedField(props.original.fieldId) ? "Added" : renderFormatMsg("Add", "Add")}</button>
    },

  ]


  const checkIfAlreadyAddedField = (fieldId) => {
    return selectedFieldFromModal.indexOf(fieldId) >= 0
  }
  const SearchHandler = (e) => { 
    getData({ page: 0, search: e.target.value })
   }

  return (
    <div className="main-content">
     
      <PageHeading headingId={"ReportDefinitionDetails"} backURL={`/report-definition${parsed.search ? `?search=${parsed.search}`: ''}`} isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} />
              <div className="col-sm-12 col-lg-6 col-xl-6  ml-auto"><CustomButton classes="addsku-btn" onClick={() => setOpenModal(!openModal)} id="AddReportFields" defaultText="Add Report Fields" /></div>
            </div>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <Droppable droppableId="table">
                {(provided, snapshot) => (
                  <table className="table table-striped">
                    <thead>
                      <th>{renderFormatMsg("Name", "Name")}</th>
                      <th>{renderFormatMsg("Header", "Header")}</th>
                      <th>{renderFormatMsg("ReportName", "Report Name")}</th>
                      <th>{renderFormatMsg("Edit", "Edit")}</th>
                      <th>{renderFormatMsg("Delete", "Delete")}</th>
                    </thead>
                    <tbody {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>

                      {reportFields.map((item, index) => (
                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                          {(provided, snapshot) => (
                            <tr ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td className="rt-th text-left">{item.reportName}</td>
                              <td className="rt-th text-left">{item.title}</td>
                              <td className="rt-th text-left">{item.reportFieldName}</td>
                              <td className="rt-th text-left" onClick={() => redirectToEditField(item.columnId)}><div className="rt-td"><i className="edit-icon"></i>Edit</div></td>
                              <td className="rt-th text-left" onClick={() => removeFieldFromReport(item.columnId)}><div className="rt-td"><i className="delete-icon"></i>Delete</div></td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    
                    </tbody>
                  </table>

                )}
              </Droppable>
            </DragDropContext>

          </>
        }
      </div>
      <Modal openModal={openModal} setOpenModal={setOpenModal} heading={renderFormatMsg("AddFieldToReport", "Add field to report")} classes="">
        <div className="main-content">
          <div className="card">
            <div className="card-header"> <SearchBox searchHandler={SearchHandlerForSku} /></div>
            {error ? <Error /> : <ReactTable data={reportFieldsToAdd} columns={columnsForFieldModal} onFetchData={fetchDataForModalField} pages={totalPageForModal} page={pageIndexForModal} />}
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default React.memo(HandleReportDefinitionButton)