import React, { ChangeEvent, useCallback } from "react";

import SearchBox from "v2/views/components/searchBox";
import TextResizeButtonGroup from "v2/views/components/textResizeButtonGroup";

type UnfinishedTaskHeaderProps = {
  changeFontSize: string;
  setChangeFontSize: (fontSize: string) => void;
  searchHandler: (value: string) => void;
  searchValue: string;
};
const UnfinishedTaskHeader = ({
  changeFontSize,
  setChangeFontSize,
  searchHandler,
  searchValue,
}: UnfinishedTaskHeaderProps) => {
  const normalFont = useCallback(() => {
    setChangeFontSize("14px");
    localStorage.setItem("currentFontSize", "14px");
  }, [setChangeFontSize]);

  const smallFont = useCallback(() => {
    setChangeFontSize("12px");
    localStorage.setItem("currentFontSize", "12px");
  }, [setChangeFontSize]);

  const extraSmallFont = useCallback(() => {
    setChangeFontSize("11px");
    localStorage.setItem("currentFontSize", "11px");
  }, [setChangeFontSize]);

  return (
    <div className="card-header">
      <SearchBox
        value={searchValue}
        searchHandler={(e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          searchHandler(value);
        }}
      />
      <TextResizeButtonGroup
        changeFontSize={changeFontSize}
        normalFont={normalFont}
        smallFont={smallFont}
        extraSmallFont={extraSmallFont}
      />
    </div>
  );
};

export default UnfinishedTaskHeader;
