import { DateRangeStatus, checkPermission, findPermission, permissionErrorCheck, renderFormatMsg } from '../../shared/funs';
import React, { useContext, useEffect, useState } from 'react'

import { AppContext } from '../../context/AppContext';
import Calendar from '../../components/Calendar';
import Error from "../../components/Error/Error";
import { Link } from 'react-router-dom';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import { get } from 'lodash';
import { getPromotionGroupLinkedPromotion } from '../../services/toolsAndSettingsPagesApi';
import moment from 'moment';

const PromotionGroupReports = (props) => {

  // console.info('props =>', props);


  const [promotionGroupLinkedPromotion, setPromotionGroupLinkedPromotion] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })


  let dataArray = get(userDetails, "accesses")
  let longDateFormat = get(userDetails, "longDateFormat", "DD.MM.YYYY HH:mm a")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_CONSTANTS").screenId))

  }, [dataArray])


 
  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
   

    getPromotionGroupLinkedPromotion({
      promotionGroupId: props.match.params.id
    }).then(res => {
      console.info('res => ', res);
      setLoading(false)
      console.info('res.response => ', res.response);

      setPromotionGroupLinkedPromotion(res.response)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
    })
  }



  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Description", "Description")}</div>,
      accessor: 'description',
      minWidth: 130,
      maxWidth: 350,
      resizable: false,
      style: { justifyContent: "flex-start" },
      headerClassName: "xyz",
      className: "abc"
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Bucket", "Bucket")}</div>,
      accessor: 'bucket',
      minWidth: 150,
      maxWidth: 350,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: renderFormatMsg("ActiveFrom", "Active From"),
      accessor: 'activeFrom',
      resizable: false,
      width: 130,
      maxWidth: 200,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar
          name="activeFrom"
          className={`read-only py-2 px-0`}
          disabled={true}
          selectedDate={new Date(props.original.activeFrom)}
          maxDate={new Date(props.original.activeTo)}
        />
      </div>
    }, {
      Header: renderFormatMsg("ActiveTo", "Active to"),
      accessor: 'activeTo',
      resizable: false,
      width: 130,
      maxWidth: 200,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar name="activeTo"
          className={`read-only py-2 px-0`}
          disabled={true}
          selectedDate={new Date(props.original.activeTo)}
          minDate={new Date(props.original.activeFrom)}
        />
      </div>
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Created", "Created")}</div>,
      accessor: 'createdAt',
      minWidth: 110,
      maxWidth: 180,
      resizable: false,
      style: { justifyContent: "flex-start" },
      Cell: props => <div>{get(props, "original.createdAt") && moment(props.original.createdAt).format(longDateFormat)}</div>
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Promotion", "Promotion")}</div>,
      accessor: 'Edit',
      minWidth: 110,
      maxWidth: 180,
      resizable: false,
      style: { justifyContent: "flex-start" },
      Cell: props => pageReadOnly.write && <Link to={`/promotions/edit/${props.original.promotionId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    },

    
  ]


  const fetchData = (state, instance) => {
    getData({ page: state.page, search: searchValue })
    setPageIndex(state.page)
  }

  return (
    <div className="main-content">
      
      <PageHeading headingId={"PromotionLinkedToPromotionGroup"} backURL="/promotion-group" isLoading={isLoading} >
      </PageHeading>
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
            </div>
            <ReactTable data={promotionGroupLinkedPromotion} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={0} page={pageIndex} />
          </>
        }
      </div>
    </div>
  )
}
export default React.memo(PromotionGroupReports)
