import { Form, FormControl } from 'react-bootstrap';

import React from 'react'

export default function SelectDropDown({ className, value, handleChange, name, options = [], defaultLabel, id }) {

    return (
        <>
            <label style={{ marginTop: "15px" }}>{name}</label>
            <Form className={`search-boxs ${className}`}>
                <FormControl
                    as="select"
                    componentClass="select"
                    name={name}
                    value={value}
                    onChange={handleChange}
                >
                    {options}
                </FormControl>
            </Form>
        </>
    )
}
