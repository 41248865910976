import { APIResponse } from "../../repoTypes";
import { HOST_API } from "constant";
import { PROMOTION } from "../endpoint";
import { PromotionByIdParameter } from "../types";
import { PromotionResponses } from "v2/core/apiTypes";
import axiosInstance from "services/axiosInstance";
export const getPromotionInfoFromRepo = (
  params: PromotionByIdParameter
): APIResponse<PromotionResponses> =>
  axiosInstance.get(`${HOST_API}/${PROMOTION.getPromotionInfo}`, { params });
