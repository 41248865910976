import React, { useContext, useState } from 'react'
import { permissionErrorCheck, renderFormatMsg } from '../../shared/funs';

import { AppContext } from '../../context/AppContext';
import Error from "../../components/Error/Error";
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import { get } from 'lodash';
import { getPromotionDetailsPhone } from '../../services/toolsAndSettingsPagesApi';
import moment from 'moment';

const PromotionDetailsPhone = (props) => {

    const [promotionDetailsPhone, setPromotionDetailsPhone] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [phone, setPhone] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { userDetails } = useContext(AppContext)

    let longDateFormat = get(userDetails, "longDateFormat", "DD.MM.YYYY HH:mm a")

 

    const getData = async () => {
        let param = {
            callTaskId: props.match.params.taskId,
        }

        getPromotionDetailsPhone(param).then(res => {
            setLoading(false)
            setPromotionDetailsPhone(res.callTaskDetails)
            setPhone(res.phone)
            setTotalPage(res.totalPage)
            setError(false)
        }).catch(err => {
            setError(permissionErrorCheck(err))
            setLoading(false)
            setPromotionDetailsPhone([])
        })
    }

    const fetchData = (state) => {
        console.info('fetchData state => ', state);
        getData({ page: state.page })
        setPageIndex(state.page)
    }

    const columns = [
        {
            Header: <div className="text-left">{renderFormatMsg("Type", "Type")}</div>,
            accessor: 'type',
            minWidth: 130,
            maxWidth: 200,
            resizable: false,
            style: { justifyContent: "flex-start" },
        },
        {
            Header: <div className="text-left">{renderFormatMsg("Message", "Message")}</div>,
            accessor: 'message',
            minWidth: 150,
            maxWidth: 750,
            resizable: false,
            style: { justifyContent: "flex-start" },
            Cell: props => <div><span title={props.original.message}>{props.original.message}</span></div>
        },
        {
            Header: renderFormatMsg("EventDate", "Event Date"),
            accessor: 'eventDate',
            resizable: false,
            width: 130,
            maxWidth: 350,
            Cell: props => <div>{get(props, "original.eventDate") && moment(props.original.eventDate).format(longDateFormat)}</div>
        }
    ]

    return (
        <div className="main-content">
            <PageHeading headingId={`Promotion Details ${phone}`} isLoading={isLoading} backURL={`/promotions/details/${props.match.params.id}`} />

            <div className="card promotion">
                {error ? <Error msg={error} /> :
                    <>

                        <ReactTable data={promotionDetailsPhone} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} showPaginationBottom={false} />
                    </>
                }
            </div>
        </div >
    )
}
export default React.memo(PromotionDetailsPhone)
