import { Field } from "../../../../components/PromoFrontendForm";
import { FieldInputProps } from "react-final-form";
import { InputGroup } from "react-bootstrap";
import React from "react";
import { renderFormatMsg } from "../../../../shared/funs";

/**
 *
 * @param {name} name of input
 * @param {placeholder} placeholder of input
 * @param {label} label of input
 */

type InputFieldProps = {
  name: string;
  placeholder: string;
  label: string;
  type?: string;
  colClass?: string;
  iconText?: boolean;
  defaultLabel: string;
  id: string;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
};
const InputField = ({
  name,
  type = "text",
  iconText = false,
  placeholder,
  defaultLabel,
  id,
  readOnly = false,
  required = false,
  disabled = false,
  ...rest
}: InputFieldProps) => {
  const inputFieldRender = (input: FieldInputProps<any, HTMLElement>) => {
    return (
      <input
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        className={`${readOnly ? "read-only" : ""} form-control`}
        type={type}
        placeholder={placeholder}
        {...input}
        {...rest}
      />
    );
  };
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <div className="form-group">
          <label> {id && renderFormatMsg(id, defaultLabel)} </label>
          {iconText ? (
            <InputGroup className="mb-2">
              {inputFieldRender(input)}
              <InputGroup.Prepend>
                <InputGroup.Text>{iconText}</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          ) : (
            inputFieldRender(input)
          )}
          {meta.error && meta.touched ? <span>{meta.error}</span> : null}
        </div>
      )}
    </Field>
  );
};

export default InputField;
