import { get, put, post, deleted } from "./index";

// Repl3nishment page APIs
export const getspecialReplenishment = async (params) => {
  return await get("api/v1/Replenishment/specialReplenishment", params);
};

export const updatespecialReplenishment = async (data) => {
  return await put("api/v1/Replenishment/UpdateSpecialReplenishment", data);
};

export const getDashboadData = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=discSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForSaleUnit = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=unitSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForSalePrice = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=priceSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForRealizedMargin = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForSellOut = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForRealOOS = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForSpeedOfSales = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForCoverage = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForAbsoluteOOS = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForDiscountedProducts = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

//Promotion API
export const getPromotion = async (params) => {
  return await get(`api/v1/Promotion/promotions`, params);
};
export const updatePromotion = async (params) => {
  return await put(`api/v1/Promotion/Promotion`, params);
};
export const addPromotion = async (params) => {
  return await post(`api/v1/Promotion/Promotion`, params);
};
export const deletePromotion = async (key, id) => {
  return await deleted(`api/v1/Promotion/?${key}=${id}`);
};

export const getReviews = async (params) => {
  return await get(`api/v1/CallTask/GetList`, params);
};

export const getOpenRecruitingTasks = async (params) => {
  return await get(`api/v1/RecruitingTasks/openTasks`, params);
};

export const deleteremoveRecruitingTasks = async (id) => {
  return await deleted(`api/v1/RecruitingTasks/tasks/${id}`);
};

export const getConstants = async (params) => {
  return await get(`api/v1/Constant/GetList`, params);
};

export const deleteConstants = async (key, id) => {
  return await deleted(`api/v1/Constant/DeleteConstant?${key}=${id}`);
};

export const deleteForms = async (key, id) => {
  return await deleted(`api/v1/Forms/Form?${key}=${id}`);
};

export const getPromotionType = async (params) => {
  return await get(`api/v1/PromotionType/GetList`, params);
};

export const getComposedForm = async (params) => {
  return await get(`api/v1/Forms/ComposedForm`, params);
};

export const getCallTask = async (params) => {
  return await get(`api/v1/CallTask/callTask`, params);
};
