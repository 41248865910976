import {
  DATE_FORMAT,
  PERMISSION_LEVEL_READ_ONLY,
  PERMISSION_LEVEL_READ_WRITE,
  SCREEN_ACCESS_RIGHT,
} from "../constant";
import _, { find } from "lodash";

import { FormattedMessage } from "react-intl";
import React from "react";
import jwt from "jsonwebtoken";
import moment from "moment";
import { toast } from "react-toastify";

/**
 * get token of user
 * @returns
 */
export const getToken = () => {
  return localStorage.getItem("token") ? localStorage.getItem("token") : false;
};

/**
 * check if user is auth
 * @returns boolean
 */
export const isAuth = () => {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : false;
  if (!token) return false;
  var decodedToken = jwt.decode(token, { complete: true });
  var dateNow = new Date();
  return decodedToken && decodedToken.payload.exp < dateNow.getTime();
};

/**
 *
 * @param {string} data data to be toast with error
 * @returns
 */
export const toastError = (data) => toast.error(data);

/**
 *
 * @param {string} data data to be toast with info
 * @returns
 */
export const toastInfo = (data) => toast.info(data);

/**
 *
 * @param {string} data data to be toast with success
 * @returns
 */
export const toastSuccess = (data) => toast.success(data);

/**
 *
 * @param {string} id Id of language String
 * @param {string} msg default message for language
 * @returns
 */
export const renderFormatMsg = (id, msg) => (
  <FormattedMessage id={id} defaultMessage={msg} />
);

/**
 *
 * @param {array} dataArray array of permission
 * @param {string} permissionVal value of permission
 * @returns
 */
export const checkPermission = (dataArray = [], permissionVal) => {
  var permission = { read: true, write: false };
  var givenPageAccessDetail = find(dataArray, { function: permissionVal });

  if (
    givenPageAccessDetail &&
    givenPageAccessDetail.accessLevel === PERMISSION_LEVEL_READ_WRITE
  )
    permission.write = true;
  else if (
    givenPageAccessDetail &&
    givenPageAccessDetail.accessLevel === PERMISSION_LEVEL_READ_ONLY
  )
    permission.write = false;
  return permission;
};

/**
 *
 * @param {date} dateOne Active start Date
 * @param {date} dateTwo Active end Date
 * @returns
 */
export const DateRangeStatus = (dateOne, dateTwo) => {
  return (
    dateOne &&
    (new Date(dateOne) < new Date() && new Date(dateTwo) > new Date()
      ? "greenBG"
      : "redBG")
  );
};

/**
 *
 * @param {array} data array of table data
 * @param {string} key key of update
 * @param {date} date date of change in active from and active to
 * @param {object} obj object to be update in payload
 */
export const updateActiveFromAndToRow = (data, key, date, obj) => {
  let tempData = data && data.length > 0 ? [...data] : [];
  tempData.forEach((currentData) => {
    if (currentData[Object.keys(obj)[0]] === obj[Object.keys(obj)[0]]) {
      key === "activeFrom"
        ? (currentData.activeFrom = moment(date).format())
        : (currentData.activeTo = moment(date).format());
    }
  });
  let object = {
    ...obj,
    [key]: moment(date).format(DATE_FORMAT),
  };
  return { data: tempData, payload: object };
};

/**
 *
 * @param {int} value to be convert into short number
 */
export const intToString = (value) => {
  if (value < 10000) return value;

  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  newValue = newValue.toPrecision(3);
  newValue += suffixes[suffixNum];
  return newValue;
};

/**
 *
 * @param {object} err object of error
 * @returns
 */
export const permissionErrorCheck = (err) => {
  if (err && (err.status === 401 || err.status === 403)) {
    return "Access denied you don't have permission to access this page";
  } else {
    return err;
  }
};

/**
 *
 * @param {DOM} input input object value
 * @returns
 */
export const selectStyle = (input) => {
  return { color: `${input.value ? "#495057" : "#a0a0a0"}` };
};

/**
 *
 * @param {object} rows row data
 * @param {string} accessor object key name
 * @param {string} headerText Heading Text to be render on table
 * @returns
 */
export const getColumnWidth = (rows, accessor, headerText) => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...rows.map((row) => (`${row[accessor]}` || "").length),
    headerText.length
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

/**
 *
 * @param {string} name which permision you need to check
 * @returns permission access right
 */
export const findPermission = (name) => {
  return _.find(SCREEN_ACCESS_RIGHT, { name });
};
