import { useCallback, useState } from "react";

import { cloneForm } from "v2/core/repository/forms/cloneForm";

const useCloneForm = (onSuccess?: () => void, onError?: () => void) => {
  const [loading, setLoading] = useState(false);
  const postCloneForm = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await cloneForm(id);
        onSuccess?.();
      } catch (e) {
        console.log(e);
        onError?.();
      } finally {
        setLoading(false);
      }
    },
    [onError, onSuccess]
  );
  return {
    postCloneForm,
    loading,
  };
};

export default useCloneForm;
