import React, { useContext } from 'react'
import { findPermission, renderFormatMsg } from "./../shared/funs"

import { AppContext } from '../context/AppContext'
import { Dropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'

const ToolsAndSettingDetail = () => {

  const { userDetails } = useContext(AppContext);

  let dataArray = get(userDetails, "accesses");

  let permission = true;

  // For close Tools & Settings Menu
  const closeTools = () => {
    document.getElementById("tools").click();
  };


  //User Rights
  const CheckMenuPermission = (key) => {

    let dd1 = findPermission(key).screenId;


    permission = dataArray && dataArray.some(o => {

      return o.function === dd1
    })


    return permission;
  }


  const MENU_LIST = [
    {
      icon: "icon-shared-liberary",
      id: "header.Tools.User",
      name: "User",
      url: "/user",
      isVisible: CheckMenuPermission("CREATE_EDIT_USERS")
    },
    {
      icon: "icon-planning",
      id: "header.Tools.Roles",
      name: "User Roles",
      url: "/user-roles",
      isVisible: CheckMenuPermission("CREATE_EDIT_ROLES")
    },
    {
      icon: "icon-planning",
      id: "header.Tools.client",
      name: "client",
      url: "/client",
      isVisible: CheckMenuPermission("CREATE_EDIT_CLIENT")
    },
    {
      icon: "icon-planning",
      id: "Promotions",
      name: "Promotions",
      url: "/promotions",
      isVisible: CheckMenuPermission("CREATE_EDIT_PROMOTIONS")
    },
    {
      icon: "icon-planning",
      id: "Constants",
      name: "Constants",
      url: "/constants",
      isVisible: CheckMenuPermission("CREATE_EDIT_CONSTANTS")
    },
    {
      icon: "icon-planning",
      id: "PromotionType",
      name: "Promotion Type",
      url: "/promotion-type",
      isVisible: CheckMenuPermission("CREATE_EDIT_PROMOTION_TYPES")
    },
    {
      icon: "icon-planning",
      id: "PromotionGroup",
      name: "Promotion Group",
      url: "/promotion-group",
      isVisible: CheckMenuPermission("CREATE_EDIT_PROMOTION_GROUP")
    },
    {
      icon: "icon-planning",
      id: "ReportFields",
      name: "Report Fields",
      url: "/report-fields",
      isVisible: CheckMenuPermission("CREATE_EDIT_REPORT_FIELDS")
    },
    {
      icon: "icon-planning",
      id: "ReportDefinition",
      name: "Report Definition",
      url: "/report-definition",
      isVisible: CheckMenuPermission("CREATE_EDIT_REPORT_DEFINITIONS")
    },
    {
      icon: "icon-planning",
      id: "Forms",
      name: "Forms",
      url: "/forms",
      isVisible: CheckMenuPermission("CREATE_EDIT_FORMS")
    }
  ]

  return (
    <Dropdown className="tools">
      <Dropdown.Toggle id="tools">
        <i className="icon-tool-setting"></i>
        {renderFormatMsg("header.Tools.Heading", "Tools & Settings")}
      </Dropdown.Toggle>
      <Dropdown.Menu>

        <ul>
          {
            MENU_LIST.map(({ url, name, id, icon, isVisible }, index) =>
              isVisible && <li key={index}>
                <NavLink to={url} onClick={closeTools} >
                  <i className={icon}></i>
                  {renderFormatMsg(id, name)}
                </NavLink>
              </li>
            )
          }
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default React.memo(ToolsAndSettingDetail)
