type PromoFormDataType = {
  clientId: string;
  name: string;
  description: string;
  bucket: string;
  fromDay: string;
  toDay: string;
  target: string;
  prefix: string;
  receiver: string;
  customerCampaignId: string;
  maxCallCount: string;
  scriptTitle: string;
  shortCallLimit: number | string;
  script: string;
  preProcessing: string;
  autoPushTime: string;
  autoReportTime: string;
  class: string;
};

export const promoFormValidation = (data: PromoFormDataType) => {
  const errors: Partial<PromoFormDataType> = {};
  if (!data.clientId || data.clientId === "") {
    errors.clientId = "Required";
  }
  if (!data.name || data.name === "") {
    errors.name = "Required";
  }
  if (!data.description || data.description === "") {
    errors.description = "Required";
  }
  if (!data.bucket || data.bucket === "") {
    errors.bucket = "Required";
  }
  if (typeof data.fromDay === undefined || data.fromDay === "") {
    errors.fromDay = "Required";
  }
  if (typeof data.toDay === undefined || data.toDay === "") {
    errors.toDay = "Required";
  }
  if (!data.target || data.target === "") {
    errors.target = "Required";
  }
  if (!data.prefix || data.prefix === "") {
    errors.prefix = "Required";
  }
  if (!data.class || data.class === "") {
    errors.prefix = "Required";
  }
  if (!data.receiver || data.receiver === "") {
    errors.receiver = "Required";
  }
  if (!data.customerCampaignId || data.customerCampaignId === "") {
    errors.customerCampaignId = "Required";
  }
  if (!data.maxCallCount || data.maxCallCount === "") {
    errors.maxCallCount = "Required";
  }
  if (!data.shortCallLimit && data.shortCallLimit !== 0) {
    errors.shortCallLimit = "Required";
  }
  if (!data.preProcessing || data.preProcessing === "") {
    errors.preProcessing = "Required";
  }

  if (
    data.autoPushTime &&
    !data.autoPushTime.match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/)
  ) {
    errors.autoPushTime = "Invalid time input";
  }

  if (
    data.autoReportTime &&
    !data.autoReportTime.match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/)
  ) {
    errors.autoReportTime = "Invalid time input";
  }

  return errors;
};
