import { DATE_FORMAT, PAGE_SIZE } from '../../constant';
import React, { useContext, useEffect, useState } from 'react'
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, } from '../../shared/funs';

import { AppContext } from '../../context/AppContext';
import Error from "../../components/Error/Error";
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import { get } from 'lodash';
import { getReviews } from '../../services/sidebarApi';
import moment from 'moment';

const Reviews = (props) => {
  const [reviews, setReviews] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { userDetails } = useContext(AppContext)

  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    if (!checkPermission(dataArray, findPermission("REVIEWS").screenId).read) {
      props.history.goBack();
    }
  }, [dataArray, props.history])

  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
    }
    getReviews(param).then(res => {
      setReviews(res.calltask)
      setTotalPage(res.totalPage)
      setLoading(false)
    }).catch(err => {
      setError(permissionErrorCheck(err))
      setLoading(false)
      setReviews([])
    })
  }


  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("TaskId", "Task Id")}</div>,
      accessor: 'taskId',
      minWidth: 100,
      maxWidth: 350,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Status", "Status")}</div>,
      accessor: 'status',
      minWidth: 40,
      maxWidth: 100,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Duration", "Duration")}</div>,
      accessor: 'duration',
      minWidth: 40,
      maxWidth: 100,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Date", "Date")}</div>,
      accessor: 'date',
      minWidth: 180,
      resizable: false,
      style: { justifyContent: "flex-start" },
      Cell: props => <span>{moment(props.original.date).format(DATE_FORMAT)}</span>
    }
  ]

  const fetchData = (state, instance) => {
    //getting change page number from state
    getData({ page: state.page, search: searchValue })
    setPageIndex(state.page)
  }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <span>{renderFormatMsg("Reviews", "Reviews")}</span>
        </div>
      </div> */}
      <PageHeading headingId={"Reviews"} isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <ReactTable data={reviews} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div>
  )
}
export default React.memo(Reviews)
