import { Field, Form } from 'react-final-form';
import React, { useCallback, useEffect, useState } from 'react'
import { addElement, getElementById, updateElement } from '../../services/toolsAndSettingsPagesApi';
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ELEMENT_TYPES } from '../../constant';
import Error from '../../components/Error/Error';
import FieldErrorMessage from '../../components/FieldErrorMessage';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import PlusIcon from '../../images/plus.svg'
import SelectField from '../../components/Fields/SelectField';
import { get } from "lodash";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const HandleElements = (props) => {
  const location = useLocation()
  const parsed = queryString.parse(location.search);

  const [formData, setFormData] = useState({})
  const [valuesEle, setValuesEle] = useState([])
  const [variable, setVariable] = useState(null)
  const [error, setError] = useState(false)

  var heading = "EditElement"

  var values = {};
  if (props.location.pathname.includes("Edit")) {

    let keyValues = []
    try {

      if (get(variable, "values")) {
        let temp = JSON.parse(get(variable, "values"))
        keyValues = temp.values ? temp.values : []
      }

    } catch (error) {
      keyValues = []
    }

    values = {
      elementId: get(variable, "elementId", ""),
      sectionId: get(variable, "sectionId", ""),
      id: get(variable, "id", ""),
      name: get(variable, "name", ""),
      elementType: get(variable, "elementType", ""),
      fieldLength: get(variable, "fieldLength", ""),
      fieldLengthMax: get(variable, "fieldLengthMax", ""),
      text: get(variable, "text", ""),
      inputPath: get(variable, "inputPath", ""),
      targetValue: get(variable, "targetValue", ""),
      mandatoryOnSuccess: get(variable, "mandatoryOnSuccess", ""),
      mandatoryOnReject: get(variable, "mandatoryOnReject", ""),
      readOnly: get(variable, "readOnly", ""),
      endsInput: get(variable, "endsInput", ""),
      saveOnReject: get(variable, "saveOnReject", ""),
      sequence: get(variable, "sequence"),
      values: keyValues,
      valuePath: get(variable, "valuePath", ""),
      description: get(variable, "description", ""),
      fieldDescription: get(variable, "fieldDescription", ""),
      ...formData
    }

  } else {
    heading = "AddElement"
    values = {
      name: "",
      elementType: "",
      text: "",
      inputPath: "",
      targetValue: "",
      fieldLength: "",
      fieldLengthMax: "",
      mandatoryOnSuccess: "",
      mandatoryOnReject: "",
      readOnly: "",
      endsInput: "",
      saveOnReject: "",
      sequence: 0,
      values: [],
      valuePath: "",
      description: "",
      fieldDescription: "",
      ...formData
    }


  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getElementById({ id: props.match.params.elementId }).then(res => {

        setVariable(res)
        try {

          let values = JSON.parse(get(res, "values", []))

          if (values) {
            setValuesEle(values.values)
          }
        } catch (error) {

        }
      }).catch(err => {
        setVariable(null)
        setError(permissionErrorCheck(get(err, "data.value.errors.description", "Something went wrong, please try after some time!")))
      })
    }

  }, [
    props.location.pathname,
    props.match.params.elementId
  ])

  const handleEditForm = async (data) => {
    let object = {
      "sectionId": props.match.params.sectionId,
      "elementId": props.match.params.elementId,
      "id": props.match.params.id,
      "name": data.name,
      "elementType": data.elementType,
      "text": data.text,
      "inputPath": data.inputPath,
      "targetValue": data.targetValue,
      "fieldLength": data.fieldLength,
      "fieldLengthMax": data.fieldLengthMax,
      "mandatoryOnSuccess": data.mandatoryOnSuccess,
      "mandatoryOnReject": data.mandatoryOnReject,
      "readOnly": data.readOnly,
      "endsInput": data.endsInput,
      "saveOnReject": data.saveOnReject,
      "description": data.description || "",
      "fieldDescription": data.fieldDescription || "",
      "sequence": data.sequence,
      "values": JSON.stringify({ values: valuesEle }) || [],
      "valuePath": data.valuePath
    }
    // console.info('object => ', object);

    updateElement(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      props.history.push(`/elements/${props.match.params.id}/${props.match.params.sectionId}${
        parsed.search ? `?search=${parsed.search}` : ""
      }`)
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }

  const handleElementTypeChange = useCallback(e => {
    const { value } = e.target;
    const newFormData = { ...formData, elementType: value }
    if(value === "TwoColumnActionDropDown"){
      setFormData({
        ...newFormData,
        name : "action",
      })
    }
    else{
      setFormData({
        ...newFormData,
        name : values?.name || "",
      })
    }
  }, [formData, values.name])

  const handleAddElement = async (data) => {
    // console.info('data =>', data);

    let object = {
      "sectionId": props.match.params.sectionId,
      "name": data.name,
      "elementType": data.elementType,
      "text": data.text,
      "inputPath": data.inputPath,
      "targetValue": data.targetValue,
      "mandatoryOnSuccess": data.mandatoryOnSuccess,
      "mandatoryOnReject": data.mandatoryOnReject,
      "readOnly": data.readOnly,
      "endsInput": data.endsInput,
      "saveOnReject": data.saveOnReject,
      "sequence": data.sequence,
      "values": JSON.stringify({ values: valuesEle }),
      "valuePath": data.valuePath
      // "values": data.values
    }

    addElement(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully"))
      props.history.push(`/elements/${props.match.params.id}/${props.match.params.sectionId}${
        parsed.search ? `?search=${parsed.search}` : ""
      }`)
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }

  const validation = (data) => {

    let errors = {}
    if (!get(data, "name", false)) {
      errors.name = "Required";
    }

    

    if (!get(data, "elementType", false)) {
      errors.elementType = "Required";
    }

 

    if ((!get(data, "inputPath", false)) && values.elementType !== "StaticText" && formData.elementType !=="StaticText") {
      if (["RedialButton", "SaveButtonLine", "WrongNumberButton"].includes(data.elementType)) {
        return
      } else {
        errors.inputPath = "Required";
      }
    }

    if ((!get(data, "targetValue", false)) && (data.readOnly !== true) && values.elementType !== "StaticText" && formData.elementType !=="StaticText") {
      if (["RedialButton", "SaveButtonLine", "WrongNumberButton"].includes(data.elementType)) {
        return
      } else {
        errors.targetValue = "Required";
      }
    }

    return errors
  }

  const generateOptions = () => {
    let options = [<option value={``}> Select Type</option>]
    
    ELEMENT_TYPES.forEach(({ label, value }) => {
      options.push(<option value={value}>{label}</option>)
    })

    return options;
  }

  const addValue = (values) => {

    if (["WrongNumberButton", "TwoColumnTextRow", "TwoColumnEmailRow", "TwoColumnPhoneRow", "TwoColumnNumberRow", "TwoColumnDateRow", "HTMLElement", "SaveButtonLine"].includes(values.elementType)) {
      return
    } else {
      var obj = { id: "", text: "" }
      let allValues = [...valuesEle];

      allValues.push(obj)

      setFormData({
        ...formData,
        ...values,
        values: allValues
      })
      setValuesEle(allValues)
    }
  }



  const removeValue = (index) => {
    let allValues = [...valuesEle];
    allValues.splice(index, 1)

    setFormData({
      ...formData,
      values: allValues
    })
    setValuesEle(allValues)
  }

  const changeHandler = (value, key, index) => {
    let allValues = [...valuesEle];
    allValues[index][key] = value
    setFormData({
      ...formData,
      values: allValues
    })
    setValuesEle(allValues)
  }


  let eleValues = [];
  valuesEle.forEach((eleValue, index) => {
    eleValues.push(
      <div className="row">
        <div className="col-lg-5">
          <InputField
            type="text"
            value={eleValue.id}
            id={"Id"}
            name={`values-${index}`}
            defaultLabel={"Id"}
            placeholder={"Element Value Id"}
            onChange={e => changeHandler(e.target.value, "id", index)}
          />
        </div>
        <div className="col-lg-6">
          <InputField
            type="text"
            value={eleValue.text}
            id={"Text"}
            name={`values-${index}`}
            defaultLabel={"Text"}
            placeholder={"Element Value Text"}
            onChange={e => changeHandler(e.target.value, "text", index)}

          />
        </div>

        <div className="col-lg-1">
          {
            <div style={{ marginTop: "30px" }}>
              <span
                className="icon-close closeBtn"
                style={{ cursor: "pointer" }}
                onClick={() => removeValue(index)}
              ></span>
            </div>
          }
        </div>
      </div>
    )
  })

  



  return (
    <div className="main-content">
      <PageHeading headingId={heading} backURL={`/elements/${props.match.params.id}/${props.match.params.sectionId}${
        parsed.search ? `?search=${parsed.search}` : ""
      }`} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Edit") ? handleEditForm : handleAddElement} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values, ...rest }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    <SelectField
                      colClass="col-lg-6"
                      name="elementType"
                      options={generateOptions()}
                      id="Type"
                      onChange={handleElementTypeChange}
                      defaultLabel={"Type"}
                    />

                    <InputField
                      colClass="col-lg-3"
                      name={"fieldLength"}
                      id={"FiledLength"}
                      onwheel="return false;"
                      type="text"
                      pattern="[0-9]*"
                      defaultLabel="Min Field Length"
                      className={`form-control ${values.elementType === "TwoColumnActionDropDown" ? "read-only" : ""}`}
                      disabled={formData.elementType === "TwoColumnActionDropDown" || values.elementType === "TwoColumnActionDropDown"}
                      placeholder={"Min Field Length"}
                    />
                    <InputField
                      colClass="col-lg-3"
                      name={"fieldLengthMax"}
                      type="text"
                      pattern="[0-9]*"
                      id={"FiledLengthMax"}
                      defaultLabel="Max Field Length"
                      className={`form-control ${values.elementType === "TwoColumnActionDropDown" ? "read-only" : ""}`}
                      disabled={formData.elementType === "TwoColumnActionDropDown" || values.elementType === "TwoColumnActionDropDown"}
                      placeholder={"Max Field Length"}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id={"Name"}
                      defaultLabel="Name"
                      className={`form-control ${values.elementType === "TwoColumnActionDropDown" ? "read-only" : ""}`}
                      disabled={formData.elementType === "TwoColumnActionDropDown" || values.elementType === "TwoColumnActionDropDown"}
                      placeholder={"Name"}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"text"}
                      type="text"
                      id={"Text"}
                      defaultLabel="Text"
                      placeholder={"Text"}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"inputPath"}
                      type="text"
                      id="InputPath"
                      defaultLabel="Input Path"
                      placeholder={"Input Path"}
                      disabled={values.elementType === "RedialButton" || values.elementType === "SaveButtonLine" || values.elementType === "WrongNumberButton" || values.elementType === "StaticText" || formData.elementType === "StaticText"}
                      className={"form-control " + (values.elementType === "RedialButton" || values.elementType === "SaveButtonLine" ||  values.elementType === "StaticText" || formData.elementType === "StaticText" || values.elementType === "WrongNumberButton" ? "read-only" : "")}
                    />

                    {values.readOnly !== true
                      ? <InputField
                        colClass="col-lg-6"
                        name={"targetValue"}
                        type="text"
                        id="TargetValue"
                        defaultLabel="Target Value"
                        placeholder={"Target Value"}
                        disabled={values.elementType === "RedialButton" || values.elementType === "SaveButtonLine" || values.elementType === "WrongNumberButton" || 
                          values.elementType === "StaticText" ||
                         formData.elementType === "StaticText"}
                        className={"form-control " + (values.elementType === "RedialButton" ||   values.elementType === "StaticText" ||
                        formData.elementType === "StaticText" || values.elementType === "SaveButtonLine" || values.elementType === "WrongNumberButton" ? "read-only" : "")}
                      />
                      : <InputField
                        colClass="col-lg-6"
                        name={"targetValue"}
                        type="text"
                        id="TargetValue"
                        defaultLabel="Target Value"
                        disabled={ values.elementType === "StaticText" || formData.elementType === "StaticText"}
                        placeholder={"Target Value"}
                        className={"form-control " + (values.elementType === "StaticText" || formData.elementType === "StaticText" ? "read-only" : "")}
                      />
                    }

                    <Field name="mandatoryOnSuccess" type="checkbox">
                      {({ input, meta }) => (
                        <div className="col-lg-6">
                          <div className="checkbox">
                            <input 
                              disabled={values.elementType === "StaticText" || formData.elementType === "StaticText"}
                              className={values.elementType === "StaticText" || formData.elementType === "StaticText" ? "read-only" : ""}
                              {...input} id="mandatoryOnSuccess" />

                            <label></label>
                            <label htmlFor="mandatoryOnSuccess" style={{ 
                                width: "auto !important", 
                            color: (values.elementType === "StaticText" || formData.elementType === "StaticText") ? "gray" : "#212529"
                          }}> {renderFormatMsg("Mandatory on Success", "Mandatory on Success")} </label>
                          </div>
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>

                    <Field name="mandatoryOnReject" type="checkbox">
                      {({ input, meta }) => (
                        <div className="col-lg-6">
                          <div className="checkbox">
                            <input {...input}  
                              id="mandatoryOnReject"
                              disabled={values.elementType === "StaticText" || formData.elementType === "StaticText"}
                              className={values.elementType === "StaticText" || formData.elementType === "StaticText" ? "read-only" : ""} 
                               />
                            <label></label>
                            <label htmlFor="mandatoryOnReject" style={{ 
                                width: "auto !important", 
                                color: (values.elementType === "StaticText" || formData.elementType === "StaticText") ? "gray" : "#212529"
                          }}> {renderFormatMsg("Mandatory on Reject", "Mandatory on Reject")}</label>
                          </div>
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>

                    <Field name="readOnly" type="checkbox">
                      {({ input, meta }) => (
                        <div className="col-lg-6">
                          <div className="checkbox">
                            <input
                              {...input}
                              id="readOnly"
                              type="checkbox"
                              disabled={values.elementType === "RedialButton" || 
                              values.elementType === "SaveButtonLine" || 
                              formData.elementType === "StaticText" ||
                              values.elementType === "StaticText"}
                              className={(
                              values.elementType === "RedialButton" || 
                              values.elementType === "SaveButtonLine" || 
                              formData.elementType === "StaticText" || 
                              values.elementType === "StaticText" ? 
                              "read-only" : 
                              "")}
                              
                            />

                            <label></label>
                            <label htmlFor="readOnly" style={{ width: "auto !important", color: 
                            values.elementType === "RedialButton" || values.elementType === "SaveButtonLine" 
                            || values.elementType === "StaticText" ||
                            formData.elementType === "StaticText"  ? "gray" : "#212529" }}> {renderFormatMsg("Read Only", "Read Only")} </label>
                          </div>
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>

                    <Field name="endsInput" type="checkbox">
                      {({ input, meta }) => (
                        <div className="col-lg-6">
                          <div className="checkbox">
                            <input {...input} id="endsInput" 
                              disabled={values.elementType === "StaticText" || formData.elementType === "StaticText"}
                              className={values.elementType === "StaticText" || formData.elementType === "StaticText" ? "read-only" : ""}
                            />
                            <label></label>
                            <label htmlFor="endsInput" style={{ width: "auto !important" , 
                          
                          color: (values.elementType === "StaticText" || formData.elementType === "StaticText") ? "gray" : "#212529"
                          }}> {renderFormatMsg("Ends input", "Ends input")} </label>
                          </div>
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>

                    <Field name="saveOnReject" type="checkbox">
                      {({ input, meta }) => (
                        <div className="col-lg-6">
                          <div className="checkbox">
                            <input
                              {...input}
                              disabled={values.elementType === "StaticText" || formData.elementType === "StaticText"}
                              className={values.elementType === "StaticText" || formData.elementType === "StaticText" ? "read-only" : ""}
                              id="saveOnReject"
                            />
                            <label></label>
                            <label
                              htmlFor="saveOnReject"
                              style={{ width: "auto !important", 
                              color: (values.elementType === "StaticText" || formData.elementType === "StaticText") ? "gray" : "#212529"
                            }}
                            >
                              {" "}{renderFormatMsg("SaveValuesOnReject", "Save Values On Reject")}
                            </label>
                          </div>
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>

                    <div className='col-lg-6'></div>

                    <div className='col-lg-6'>
                      {values && values.values && values.values.length > 0 &&
                        <div className="col-lg-12 mt-3 pl-0">
                          <label style={{ width: "auto !important", fontWeight: "500" }}>{renderFormatMsg("Values", "Values")}</label>

                          {eleValues}

                        </div>
                      }

                      <div className="col-lg-12 mt-3 pl-0">
                        <div
                          onClick={() => addValue(values)}
                          className={"col-lg-4 align-items-center btn btn-light d-flex " + (values.elementType === "WrongNumberButton" || values.elementType === "TwoColumnTextRow" || values.elementType === "TwoColumnEmailRow" || values.elementType === "TwoColumnPhoneRow" || values.elementType === "TwoColumnNumberRow" || values.elementType === "TwoColumnDateRow" || values.elementType === "HTMLElement" || values.elementType === "SaveButtonLine" ? "read-only" : "")}
                          style={{ width: "130px" }}

                        >
                          <span>{renderFormatMsg("addValue", "Add Value")}</span>
                          <img className="ml-2" src={PlusIcon} alt="PlusIcon" />
                        </div>
                      </div>
                    </div>



                    <InputField
                      colClass="col-lg-6 mt-3"
                      name={"valuePath"}
                      type="text"
                      id="ValuePath"
                      defaultLabel="Path To Values"
                      placeholder={"Path To Values"}
                      disabled={values.elementType === "RedialButton" || values.elementType === "SaveButtonLine" || values.elementType === "WrongNumberButton" || values.elementType === "StaticText" || formData.elementType === "StaticText" }
                      className={"form-control " + (values.elementType === "RedialButton" || values.elementType === "SaveButtonLine" || values.elementType === "WrongNumberButton" 
                      || values.elementType === "StaticText" || formData.elementType === "StaticText" 
                      ? "read-only" : "")}
                    />

                    
                    

                  </div>
                </div>
                    <div className="row">
                    <div className="col-lg-12">
                      <Field name="fieldDescription">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("Field Description", "Field Description")} </label>
                            <CKEditor
                              data={input.value}
                              editor={ClassicEditor}
                              config={{
                                removePlugins: ['Link', 'Indent', 'Outdent', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                input.onChange(data)
                              }}
                              onBlur={(event, editor) => {
                                const data = editor.getData();
                                input.onBlur(data)
                              }}
                            />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                      <Field name="description">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("Description", "Description")} </label>
                            <CKEditor
                              data={input.value}
                              editor={ClassicEditor}
                              config={{
                                removePlugins: ['Link', 'Indent', 'Outdent', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                input.onChange(data)
                              }}
                              onBlur={(event, editor) => {
                                const data = editor.getData();
                                input.onBlur(data)
                              }}
                            />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>
                </div>
                      
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div >
  )
}

export default React.memo(HandleElements)
