import React, { useState } from 'react'
import { injectIntl } from 'react-intl';

function ScheduleTable({ intl, data, setFieldValue, disableForm, t, validTimeSelection, disableIntrestedBtn, setFieldError }) {

  // var dummy = {
  //   "days": [{
  //     "day": "ПТ 09-23",
  //     "max": 1,
  //     "min": 9
  //   }, {
  //     "day": "СБ 09 - 23",
  //     "max": 1,
  //     "min": 9
  //   }, {
  //     "day": "ВС 09 - 23",
  //     "max": 1,
  //     "min": 9
  //   }],
  //   "lines": ["Славянский бульвар", "Пионерская"],
  //   "configuration": {
  //     "max_sel_lines": 1
  //   }
  // }
  // var dummy = {
  //   days: [
  //     {
  //       day: "fri",
  //       min_from: 15,
  //       max_from: 3,
  //       min_to: 15,
  //       max_to: 4,
  //       from: 18,
  //       to: 22,
  //     }
  //   ],
  //   lines: ["Zone 1", "Zone 2"],
  //   configuration: {
  //     max_sel_lines: 1,
  //     // from_readonly: true
  //   },
  // }

  var data2 = Object.assign({}, data);
  var temp = data2.days
  var newDays = []
  temp.forEach(o => { newDays.push({ ...o, fromValue: o.from || "", toValue: o.to || "" }) })

  data2.days = newDays

  const [timeselections, setTimeselection] = useState(data2)
  const [error, setError] = useState("")

  var _dayTime = []
  timeselections.days.forEach(o => { _dayTime.push({ day: o.day, from: o.from || "", to: o.to || "", lines: [], isValidTo: true, isValidFrom: true }) })
  const [dayTime, setDayTime] = useState(_dayTime)


  const drawSelectBoxOptions = (day, type = "from", index) => {

    var fromTime = dayTime[index].from
    // temp.push(1);
    if (type === "from") {
      let temp = []
      for (let index = type === "from" ? day.min_from : day.min_from + 1; index <= 24; index++) {
        temp.push(index)
      }
      let nextDayTime = []
      if (day.max_from >= 1 && day.max_from <= 4) {
        [...new Array(day.max_from)].forEach((item, index) => {
          nextDayTime.push(index + 1)
          temp.push(index + 1)
        })
      }

      return temp.map((o, index) => {
        // if (timeselections.configuration.from_readonly) {
        //   return <option value={o} disabled={"true"}>{o}</option >
        // } else {
        return <option value={o}>{o}</option >
        // }
        // return <option value={o}>{o}</option >
      }
      )
    } else {
      let temp = []
      for (let index = day.min_to; index <= 24; index++) {
        temp.push(index)
      }
      let nextDayTime = []
      if (day.max_to >= 1 && day.max_to <= 4) {
        [...new Array(day.max_to)].forEach((item, index) => {
          nextDayTime.push(index + 1)
          temp.push(index + 1)
        })
      }

      return temp.map((o, index) => {
        if (timeselections.configuration && timeselections.configuration && timeselections.configuration.from_readonly) {
          return <option value={o} >{o}</option>
        }
        else if (o <= fromTime && nextDayTime.indexOf(o) < 0 && (timeselections.configuration && !timeselections.configuration.from_readonly)) {
          return <option value={o} disabled={true}>{o}</option>
        } else {
          if (fromTime >= 1 && fromTime <= 4) {
            // for next day selection
            if (o <= fromTime) {
              return <option value={o} disabled={true}>{o}</option>
            } else {
              if (o > 4) {
                return <option value={o} disabled={true}>{o}</option>
              } else {
                return <option value={o}>{o}</option>
              }
            }
          } else {
            // when select current day
            return <option value={o} >{o}</option>
          }
        }
      }
      )
    }
  }





  const changeTimeHandler = (e, type, day_index) => {
    var _dayTime = [...dayTime];
    var _timeselections = { ...timeselections };
    if (type === "from") {
      _timeselections.days[day_index].fromValue = parseInt(e.target.value)
      if (parseInt(e.target.value) >= _timeselections.days[day_index].toValue || (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 4 && parseInt(e.target.value) >= _timeselections.days[day_index].toValue)) {
        _timeselections.days[day_index].toValue = ""
        _dayTime[day_index]["to"] = ""
      }
    } else {
      _timeselections.days[day_index].toValue = parseInt(e.target.value)
    }
    if (_timeselections.lines && _timeselections.lines.length === 1 && _dayTime[day_index].lines.indexOf(_timeselections.lines[0]) < 0) {

      _dayTime[day_index].lines.push(_timeselections.lines[0])
    }
    setTimeselection(_timeselections)
    if (parseInt(e.target.value))
      _dayTime[day_index][type] = parseInt(e.target.value)
    setDayTime(_dayTime)
    updateFormValue(_dayTime)
    // setFieldValue('disableIntrestedBtn', false)
    isEnableButton()
  }

  const updateFormValue = (_dayTime) => {
    var tempDays = []
    _dayTime.forEach(day => {
      tempDays.push({
        day: day.day,
        from: day.from || "",
        to: day.to || "",
        lines: day.lines
      })
    })
    setFieldValue('days', tempDays)
  }


  const selectCheckbox = (e, line, day) => {
    var _dayTime = [...dayTime];
    if (e.target.checked) {
      if (_dayTime[day].lines.length >= timeselections.configuration.max_sel_lines) {
        setError(`Can't select more than ${timeselections.configuration.max_sel_lines} timezone for a day`)
        setTimeout(() => {
          setError(``)
        }, 4000);
        e.preventDefault();
      } else {
        _dayTime[day].lines.push(line)
      }
    } else {
      // setFieldValue('disableIntrestedBtn', false)
      var index = _dayTime[day].lines.indexOf(line);
      _dayTime[day].lines.splice(index, 1)
    }
    setDayTime(_dayTime)
    updateFormValue(_dayTime)
    isEnableButton()
  }

  // const isEnableButton = () => {
  //   var isDisabled = validTimeSelection
  //   dayTime.forEach(day => {
  //     // day.line
  //     // day.from
  //     // day.to
  //     day.isValidFrom = false
  //     day.isValidTo = false
  //     if (day.lines.length > 0) {
  //       if (parseInt(day.from) > 0 && parseInt(day.to) > 0) {
  //         isDisabled = false
  //         day.isValidTo = true
  //         day.isValidFrom = true
  //       } else {
  //         if (parseInt(day.from) < 0) {
  //           day.isValidFrom = false
  //         } else {
  //           isDisabled = true
  //         }
  //         if (parseInt(day.to) < 0) {
  //           day.isValidTo = false
  //         } else {
  //           isDisabled = true
  //         }
  //       }
  //     }
  //     if (parseInt(day.from) > 0) {
  //       day.isValidFrom = true
  //     } else {
  //       isDisabled = true
  //     }
  //     if (parseInt(day.to) > 0) {
  //       day.isValidTo = true
  //     } else {
  //       isDisabled = true
  //     }
  //   });

  //   setDayTime(dayTime)
  //   if (isDisabled) {
  //     setFieldError('validTimeSelection', "invalid time")
  //   }
  //   setFieldValue('disableIntrestedBtn', isDisabled)
  //   setFieldValue('validTimeSelection', !isDisabled)
  // }

  /**
   *
   * old code
   */
  // const isEnableButton = () => {
  //   var isDisabled = disableIntrestedBtn
  //   var anyLineChecked = false

  //   dayTime.forEach(day => {
  //     day.isValidFrom = false
  //     day.isValidTo = false
  //     if (day.lines.length > 0) {
  //       anyLineChecked = true
  //       // if (day.from >= day.toValue || day.from >= 1 && day.from <= 4 && day.from >= day.toValue) {
  //       //   isDisabled = true
  //       //   setFieldValue('disableIntrestedBtn', isDisabled)
  //       // }
  //       if (parseInt(day.from) > 0 && parseInt(day.to) > 0) {
  //         isDisabled = false
  //         day.isValidTo = true
  //         day.isValidFrom = true
  //       } else {
  //         isDisabled = true
  //         if (parseInt(day.from) < 0) {
  //           day.isValidFrom = false
  //         }
  //         if (parseInt(day.to) < 0) {
  //           day.isValidTo = false
  //         }
  //       }
  //     } else {
  //       day.isValidFrom = true
  //       day.isValidTo = true
  //       setFieldValue('disableIntrestedBtn', false)
  //       // setFieldValue('validTimeSelection', true)

  //     }
  //   });
  //   if (anyLineChecked) {
  //     setFieldValue('disableIntrestedBtn', isDisabled)
  //     // setFieldValue('validTimeSelection', !isDisabled)
  //   } else {
  //     setFieldValue('disableIntrestedBtn', true)
  //     // setFieldValue('validTimeSelection', false)
  //   }
  //   setDayTime(dayTime)
  // }

  /**
   * new code from 22-3-2021 (changes in validation on timeselection table)
   */
  const isEnableButton = () => {
    // eslint-disable-next-line no-unused-vars
    var isDisabled = disableIntrestedBtn
    var isValid = true

    dayTime.forEach(day => {
      day.isValidFrom = true
      day.isValidTo = true
      day.isValid = true
      if (day.lines.length > 0 || day.from || day.to) {
        if (parseInt(day.from) > 0 && parseInt(day.to) > 0 && day.lines.length > 0) {
          isDisabled = false
          day.isValidTo = true
          day.isValidFrom = true
          day.isValid = true
        } else {
          isDisabled = true
          day.isValidTo = false
          day.isValidFrom = false
          day.isValid = false
          isValid = false
        }
      }
    });

    dayTime.forEach(o => {
      if (!o.isValid) {
        isValid = false
      }
    });

    setFieldValue('disableIntrestedBtn', !isValid)

    setDayTime(dayTime)
  }

  const formatMessage = (id) => intl.formatMessage({
    id,
  });

  return (
    <div>
      <table border="1" className="table table-striped w-100">
        <thead>
          <tr>
            <td></td>
            {
              timeselections && timeselections.days.map((day, index) =>
                <td key={index}>
                  <div><span><b>{day.day}</b></span></div>
                  {/* <pre>
                    {
                      JSON.stringify(dayTime, null, 2)
                    }
                  </pre> */}

                  <div className="d-flex">

                    {
                      (timeselections.configuration && timeselections.configuration.from_readonly) ?
                        <select style={{ width: "30%" }} value={day.from} disabled={true} className={`form-control`}>
                          <option value={day.from} >{day.from}</option>
                        </select>
                        :
                        <select value={day.fromValue} disabled={(day.readonly || disableForm || (timeselections.configuration && timeselections.configuration.from_readonly)) && "true"} onChange={(e) => changeTimeHandler(e, "from", index)}
                          className={`form-control ${dayTime[index].lines.length > 0 && !dayTime[index].isValidFrom && "border-danger"}`} style={{ width: "30%" }}>
                          <option value={""} disabled > {formatMessage("from")} </option>
                          {drawSelectBoxOptions(day, "from", index)}
                        </select>
                    }

                    -
                    <select className={`form-control ${dayTime[index].lines.length > 0 && !dayTime[index].isValidTo && "border-danger"}`} disabled={(day.readonly || disableForm) && true} value={day.toValue} onChange={(e) => changeTimeHandler(e, "to", index)} style={{ width: "30%" }}>
                      <option value={""} disabled >{formatMessage("to")}</option>
                      {drawSelectBoxOptions(day, "to", index)}
                    </select>
                  </div>
                </td>
              )
            }
          </tr>
        </thead>
        <tbody>
          {
            timeselections && timeselections.lines && timeselections.lines.map((line, line_index) =>
              <tr key={line_index}>
                <td><b>{line}</b></td>
                {
                  timeselections && timeselections.days.map((check, day_index) =>
                    <td key={day_index}>
                      {/* {
                        JSON.stringify(timeselections.days[day_index], null, 2)
                      } */}
                      <input
                        disabled={(timeselections.days[day_index].readonly || disableForm) && true}
                        checked={dayTime[day_index].lines.indexOf(line) >= 0 ? true : false}
                        type="checkbox" style={{ height: 15, width: 15, cursor: "pointer" }}
                        onClick={(e) => selectCheckbox(e, line, day_index)}
                        value={`${line_index},${day_index}`}
                        name={`${line_index},${day_index}`} />
                    </td>
                  )
                }
              </tr>
            )
          }
        </tbody>
      </table>
      { error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  )
}

export default injectIntl(ScheduleTable)
