import React, { useContext, useEffect, useState } from 'react'
import { addReportToPromotionGroup, getPromotionGroupData, getReportDefinition, promotionGroupReportList, removeReportToPromotionGroup } from '../../services/toolsAndSettingsPagesApi';
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastError, toastSuccess, } from '../../shared/funs';
import { findIndex, get } from 'lodash';

import { AppContext } from '../../context/AppContext';
import Error from "../../components/Error/Error";
import { PAGE_SIZE } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';

const PromotionGroupReports = (props) => {
  const [reportDefinition, setReportDefinition] = useState([])
  const [promotionGroup, setPromotionGroup] = useState([])
  const [promotionGroupReport, setPromotionGroupReport] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })


  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_CONSTANTS").screenId))

  }, [dataArray])

  useEffect(() => {
    reportListIds()
    getPromotionGroupData({
      search: props.match.params.id
    }).then(res => {
      console.info('res => ', res);
      setPromotionGroup(res.promotionGroup && res.promotionGroup[0] ? res.promotionGroup[0] : null)
    })
  }, [props.match.params.id])

  const reportListIds = () => {
    promotionGroupReportList().then(resp => {
      setPromotionGroupReport(resp.response)
    }).catch(err => {
      console.info('err => ', err);
    })
  }
  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
      "search": "Promotion Group"
    }
    getReportDefinition(param).then(res => {
      setLoading(false)
      setReportDefinition(res.report)
      setTotalPage(res.totalPage)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setReportDefinition([])
    })
  }




  const checkIfReportAlreadyAdded = (reportId) => {
    var index = findIndex(promotionGroupReport, { reportId, promotionGroupId: props.match.params.id });
    if (index >= 0) {
      return promotionGroupReport[index]
    } else {
      return false
    }
  }

  const handleChecked = (reportId, action = false) => {
    if (!action) {
      let data = {
        promotionGroupId: props.match.params.id,
        reportId
      }

      addReportToPromotionGroup(data).then(res => {
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        reportListIds()
      }).catch(err => {
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      })
    } else {
      console.info('action => ', action);
      removeReportToPromotionGroup(action.promotionGroupReportId).then(res => {
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        reportListIds()
      }).catch(err => {
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      })
    }
  }



  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
      accessor: 'name',
      minWidth: 100,
      maxWidth: 200,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Script", "Script")}</div>,
      accessor: 'script',
      minWidth: 200,
      maxWidth: 380,
      width: 250,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Scope", "Scope")}</div>,
      accessor: 'scope',
      minWidth: 80,
      maxWidth: 180,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: renderFormatMsg("AddReportToPromotionType", "Add Report"),
      width: 200,
      Cell: (props) => <div className="checkbox">
        <input
          type="checkbox"
          name="status"
          style={{ cursor: `${!pageReadOnly.write && `not-allowed`}` }}
          defaultChecked={checkIfReportAlreadyAdded(props.original.reportId) ? true : false}
          onChange={() => handleChecked(props.original.reportId, checkIfReportAlreadyAdded(props.original.reportId))} />
        <label></label>
      </div>
    }
  ]


  const fetchData = (state) => {
    getData({ page: state.page, search: searchValue })
    setPageIndex(state.page)
  }

  return (
    <div className="main-content">
      
      <PageHeading headingId={"LinkingReportsToPromotionGroups"} backURL={`/promotion-group`}
        isLoading={isLoading} >
        <div>{promotionGroup.name}</div>
      </PageHeading>
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
            </div>
            <ReactTable data={reportDefinition} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div >
  )
}
export default React.memo(PromotionGroupReports)
