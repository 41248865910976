import { Button, Form, FormControl } from 'react-bootstrap';

import React from 'react'
import SearchIcon from "../../images/search.svg";

export default function SearchBox({ searchHandler, className, value }) {

  return (
    <Form className={`search-boxs ${className}`}>
      <FormControl
        type="text"
        defaultValue={value}
        placeholder="Search"
        onChange={searchHandler}
      />
      <Button variant="light">
        <img src={SearchIcon} alt="SearchIcon" />
      </Button>
    </Form>
  )
}
