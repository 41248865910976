import React, { useContext, useEffect, useState } from "react";
import {
  checkPermission,
  findPermission,
  permissionErrorCheck,
  renderFormatMsg,
  toastError,
  toastSuccess,
} from "../../shared/funs";

import { AppContext } from "../../context/AppContext";
import { Button } from "react-bootstrap";
import Error from "../Error/Error";
import { Link } from "react-router-dom";
import LinkButton from "../Buttons/LinkButton";
import { PAGE_SIZE } from "../../constant";
import PageHeading from "../PageHeading/PageHeading";
import ReactTable from "../ReactTable/ReactTable";
import SearchBox from "../SearchBox/SearchBox";
import { confirmAlert } from "react-confirm-alert";
import { deleteForms } from "../../services/sidebarApi";
import { get } from "lodash";
import { getForms } from "../../services/toolsAndSettingsPagesApi";
import useCloneForm from "../../v2/core/services/forms/useCloneForm";

const Forms = () => {
  const [forms, setForms] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({
    read: true,
    write: false,
  });

  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    setPageReadOnly(
      checkPermission(dataArray, findPermission("CREATE_EDIT_FORMS").screenId)
    );
  }, [dataArray]);

  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search);
    setLoading(true);
    let param = {
      page: page + 1,
      display: PAGE_SIZE,
    };
    if (search.length > 0) {
      param.search = search;
    }
    getForms(param)
      .then((res) => {
        setLoading(false);
        setForms(res.items);
        setTotalPage(res.totalPage);
      })
      .catch((err) => {
        setError(
          permissionErrorCheck(
            "Something went wrong! please try after some time!"
          )
        );
        setLoading(false);
        setForms([]);
      });
  };
  const fetchData = (state) => {
    getData({ page: state.page, search: searchValue });
    setPageIndex(state.page);
  };

  const onSuccess = () => {
    toastSuccess("Form Clone Successful")
    getData({
      page: 0,
      search: ""
    })
  }
  const onError = () => {
    toastError("Form Clone Failed")
  }
  const { postCloneForm, loading } = useCloneForm(onSuccess, onError);

  const removeForms = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteForms("id", id)
              .then((res) => {
                var _forms = Object.assign([], forms);
                _forms = _forms.filter((data) => data.formId !== id);
                setForms(_forms);
                toastSuccess(
                  renderFormatMsg(
                    "DELETE_SUCCESS_MSG",
                    "Record deleted successfully"
                  )
                );
                setLoading(false);
              })
              .catch((err) => {
                setError(
                  permissionErrorCheck(
                    "Something went wrong! please try after some time!"
                  )
                );
                setLoading(false);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const columns = [
    {
      Header: (
        <div className="text-left">
          {renderFormatMsg("ClientName", "ClientName")}
        </div>
      ),
      accessor: "clientName",
      resizable: true,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: (
        <div className="text-left">
          {renderFormatMsg("FormName", "FormName")}
        </div>
      ),
      accessor: "name",
      resizable: true,

      style: { justifyContent: "flex-start" },
    },
    {
      Header: renderFormatMsg("Delete", "Delete"),
      accessor: "Delete",
      resizable: true,

      Cell: (props) => (
        <Link
          to="#"
          onClick={() => removeForms(props.original.formId)}
          className="delete-icon"
        >
          {renderFormatMsg("Delete", "Delete")}
        </Link>
      ),
    },
  ];

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: "Edit",
      resizable: true,

      Cell: (props) => (
        <Link to={`/forms/Edit/${props.original.formId}`} className="edit-icon">
          {renderFormatMsg("Edit", "Edit")}
        </Link>
      ),
    });
  }

  columns.push({
    Header: (
      <div className="text-left">{renderFormatMsg("Sections", "Sections")}</div>
    ),
    resizable: false,
    width: 200,
    style: { justifyContent: "flex-start" },
    Cell: (props) => (
      <Link to={`/sections/${props.original.formId}`}>
        {" "}
        {renderFormatMsg("Details", "Details")} ...
      </Link>
    ),
  });
  columns.push({
    Header: (
      <div className="text-left">
        {renderFormatMsg("Duplicate", "Duplicate")}
      </div>
    ),
    resizable: true,
    style: { justifyContent: "flex-start" },
    Cell: (props) => (
      <Button
        disabled={loading}
        onClick={() => {
          postCloneForm(props.original.formId);
        }}
      >
        Duplicate Form
      </Button>
    ),
  });

  const SearchHandler = (e) => {
    setPageIndex(0);
    getData({ page: 0, search: e.target.value });
  };

  return (
    <div className="main-content">
      <PageHeading headingId={"Forms"} isLoading={isLoading} />
      <div className="card">
        {error ? (
          <Error msg={error} />
        ) : (
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} />
              {pageReadOnly.write && (
                <LinkButton
                  classes={"ml-auto"}
                  to="/forms/Add"
                  id="AddForm"
                  defaultText="Add Form"
                />
              )}
            </div>
            <ReactTable
              data={forms}
              columns={columns}
              isLoading={isLoading}
              onFetchData={fetchData}
              pages={totalPage}
              page={pageIndex}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default React.memo(Forms);
