import "./style.scss"

import { Area, AreaChart, Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import React, { Component } from 'react'
import {
  getDashboadData,
  getDashboadDataForAbsoluteOOS,
  getDashboadDataForCoverage,
  getDashboadDataForDiscountedProducts,
  getDashboadDataForRealOOS,
  getDashboadDataForRealizedMargin,
  getDashboadDataForSalePrice,
  getDashboadDataForSaleUnit,
  getDashboadDataForSellOut,
  getDashboadDataForSpeedOfSales
} from '../../services/sidebarApi';
import { intToString, renderFormatMsg } from '../../shared/funs';

import Calendar from '../../components/Calendar';
import DiscountCardChart from '../../components/Graphs/DiscountCardChart';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import { get } from 'lodash';
import { getBrand } from '../../services/toolsAndSettingsPagesApi';

const data = [
  {
    name: '29. Sep', count: 2.5,
  },
  {
    name: '30. Sep', count: 1.5
  },
  {
    name: '1. Oct', count: 0.5
  },
  {
    name: '2. Oct', count: 2.5
  },
  {
    name: '3. Oct', count: 4.5
  },
  {
    name: '4. Oct', count: 2.5
  },
  {
    name: '5. Oct', count: 1.5
  },
  {
    name: '6. Oct', count: 0.5
  },
  {
    name: '7. Oct', count: 1.8
  },
  {
    name: '8. Oct', count: 2.0
  },
];


export default class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardReportDateRange: "week",
      discSales: null,
      salesInUnit: null,
      salesInPrice: null,
      realizedMargin: null,
      sellOut: null,
      realOOS: null,
      speedOfSales: null,
      coverage: null,
      absoluteOOS: null,
      discountedProducts: null,
      showGraph: [],
      selectedCard: "",
      activeGraphData: null,
      selectedBrand: "All brands",
      selectedBrandId: null,
      graphData: [],
      brands: [],
      discSalesLoader: false,
      salesInUnitLoader: false,
      salesInPriceLoader: false,
      realizedMarginLoader: false,
      sellOutLoader: false,
      realOOSLoader: false,
      speedOfSalesLoader: false,
      coverageLoader: false,
      absoluteOOSLoader: false,
      discountedProductsLoader: false,
      brandsLoader: false,
    }
  }
  render() {
    const { brands, sellOut, selectedBrand,
      realOOS,
      speedOfSales,
      coverage,
      absoluteOOS,
      discountedProducts, realizedMargin, salesInUnit, salesInPrice, graphData, showGraph, cardReportDateRange,
      salesInUnitLoader,
      salesInPriceLoader,
      realizedMarginLoader,
      sellOutLoader,
      realOOSLoader,
      speedOfSalesLoader,
      coverageLoader,
      absoluteOOSLoader,
      discountedProductsLoader,
    } = this.state



    return (
      <div className="overview-main">
        <div className="overall">
          <div className="heading">
            <h6>
              {renderFormatMsg("Overview.Heading.OverallKPIdynamics", "Overall KPI dynamics")}

              <span className={`firstSpan ${cardReportDateRange === "year" && "active"}`}
                onClick={() => cardReportDateRange !== "year" && this.cardDateRangeHandler("year")}>
                {renderFormatMsg("Overview.ThisYearVsLastYear", "this year vs last year")}</span>
              <span className="secondSpan">/</span>

              <span className={`thirdSpan ${cardReportDateRange === "week" && "active"}`}
                onClick={() => cardReportDateRange !== "week" && this.cardDateRangeHandler("week")}>
                {renderFormatMsg("Overview.ThisWeekVsLastWeek", "this week vs last week")}
              </span>
            </h6>
            <div className="brandSelectBox">
              <DropDownCustom dropdownData={brands} selectedData={selectedBrand} handleData={(data) => { this.setState({ selectedBrand: data.name, selectedBrandId: data.brandId }, () => { this.getData() }); }} name="name" />
            </div>
          </div>

          <div className="card-container">

            <div className={`card ${salesInPriceLoader && `shine`} grid ${showGraph.indexOf('sales-in-price') >= 0 ? "active" : ""}`} onClick={() => this.showGraphHandler('sales-in-price', get(salesInPrice, 'Data', []))}>
              {
                <>
                  <div className="d-flex flex-sm-column justify-content-center px-2">
                    <span className="font-weight-bold">{renderFormatMsg("Overview.Sales", "Sales")}</span>
                  </div>
                  <div className="d-flex flex-sm-column justify-content-center px-2">
                    <div><span className="c-gray">{renderFormatMsg("Overview.inPrices", "in prices")}</span></div>
                  </div>
                  <div className="d-flex flex-sm-column justify-content-center px-2">
                    <div>{intToString(get(salesInPrice, 'CurrentValue.Value', false))}. ₽</div>
                  </div>
                  <div className="d-flex flex-sm-column justify-content-center px-2 ml-auto">
                    <div className="chip" style={{ backgroundColor: get(salesInPrice, 'Change.Color', "") }}>
                      {get(salesInPrice, 'Change.Value', "")} {get(salesInPrice, 'Change.Sign', "")}</div>
                  </div>

                  <div className="d-flex flex-sm-column justify-content-center w-100 minHeight">
                    {!salesInPriceLoader && get(salesInPrice, 'CurrentValue.From', false) && <div className="d-flex justify-content-start px-2 font-italic">
                      <div className="font15">{get(salesInPrice, 'CurrentValue.From', "")} - {get(salesInPrice, 'CurrentValue.To', "")}</div>
                    </div>}
                  </div>
                  <div className="graphWrapper">
                    {!salesInPriceLoader && <ResponsiveContainer>
                      <BarChart data={get(salesInPrice, 'Data', [])}>
                        <Bar dataKey="Value" fill="#8884d8" />
                        <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                      </BarChart>
                    </ResponsiveContainer>}
                  </div>
                </>
              }
            </div>

            <div className={`card ${salesInPriceLoader && `shine`} ${showGraph.indexOf('sales-in-unit') >= 0 ? " active" : ""}`} onClick={() => this.showGraphHandler('sales-in-unit', get(salesInUnit, 'Data', []))}>
              <div className="d-flex flex-sm-column justify-content-center px-2">
                <span className="font-weight-bold">{renderFormatMsg("Overview.Sales", "Sales")}</span>
              </div>
              <div className="d-flex flex-sm-column justify-content-center px-2">
                <div><span className="c-gray">{renderFormatMsg("Overview.inUnits", "in units")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center px-2">
                <div>{intToString(get(salesInUnit, 'CurrentValue.Value', false))}</div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center px-2 ml-auto">
                <div>
                  <div className="chip" style={{ backgroundColor: get(salesInUnit, 'Change.Color', "") }}>
                    {get(salesInUnit, 'Change.Value', "")} {get(salesInUnit, 'Change.Sign', "")}</div>
                </div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center w-100 minHeight">
                {!salesInUnitLoader && get(salesInUnit, 'CurrentValue.From', false) && <div className="d-flex justify-content-start px-2 font-italic">
                  <div className="font15">{get(salesInUnit, 'CurrentValue.From', "")} - {get(salesInUnit, 'CurrentValue.To', "")}</div>
                </div>}
              </div>
              <div className="graphWrapper">
                {!salesInUnitLoader && <ResponsiveContainer>
                  <BarChart data={get(salesInUnit, 'Data', [])}>
                    <Bar dataKey="Value" fill="#8884d8" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </BarChart>
                </ResponsiveContainer>}
              </div>
            </div>


            <div className={`card ${sellOutLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center px-2">
                <div><span className="heading">{renderFormatMsg("Overview.SellOut", "Sell-out")} 62%</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.TotalSold", "Total sold")}/{renderFormatMsg("Overview.Purchased", "purchased")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center px-2 ml-auto">
                <div><div className="chip" style={{ backgroundColor: get(sellOut, 'Change.Color', "") }}>
                  {get(sellOut, 'Change.Value', "")} {!sellOutLoader && get(sellOut, 'Change.Sign', "")}</div></div>
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="graphWrapper">
                {!sellOutLoader && <ResponsiveContainer>
                  <AreaChart data={get(sellOut, 'Data', [])}>
                    <Area type="monotone" dataKey="Value" stroke="#82ca9d" fill="#82ca9d" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </AreaChart>
                </ResponsiveContainer>}
              </div>
            </div>



            <div className={`card ${realOOSLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.RealStockOuts", "Real stock outs")} 24%</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.Absolute", "Absolute")} ({renderFormatMsg("Overview.ShowReal", "Show Real")})</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip" style={{ backgroundColor: get(realOOS, 'Change.Color', "") }}>
                  {get(realOOS, 'Change.Value', "")} {!realOOSLoader && get(realOOS, 'Change.Sign', "")}</div></div>
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="graphWrapper">
                {!realOOSLoader && <ResponsiveContainer>
                  <AreaChart data={get(realOOS, 'Data', [])}>
                    <Area type="monotone" dataKey="Value" stroke="#82ca9d" fill="#82ca9d" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </AreaChart>
                </ResponsiveContainer>}
              </div>
            </div>

            <div className={`card ${speedOfSalesLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.SpeedsOfSales", "Speeds-of-sales")} 24%</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.Absolute", "Absolute")} ({renderFormatMsg("Overview.ShowReal", "Show Real")})</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip" style={{ backgroundColor: get(speedOfSales, 'Change.Color', "") }}>
                  {get(speedOfSales, 'Change.Value', "")} {get(speedOfSales, 'Change.Sign', "")}</div></div>
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="graphWrapper">
                {!speedOfSalesLoader && <ResponsiveContainer>
                  <AreaChart data={get(speedOfSales, 'Data', [])}>
                    <Area type="monotone" dataKey="Value" stroke="#82ca9d" fill="#82ca9d" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </AreaChart>
                </ResponsiveContainer>}
              </div>
            </div>

            <div className={`card ${coverageLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.Coverage", "Coverage")} 14.2 {renderFormatMsg("Overview.Weeks", "weeks")}</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.FromTotalProductsInStock", "Inc. stock in stores & WH")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                {!coverageLoader && <div>
                  <div className="chip" style={{ backgroundColor: get(coverage, 'Change.Color', "") }}>
                    {get(coverage, 'Change.Value', "")} {get(coverage, 'Change.Sign', "")}</div>
                </div>}
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="graphWrapper">
                {!coverageLoader && <ResponsiveContainer>
                  <LineChart data={get(coverage, 'Data', [])}>
                    <Line type="monotone" dataKey="Value" stroke="#8884d8" strokeWidth={2} />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </LineChart>
                </ResponsiveContainer>}
              </div>
            </div>


            <div className={`card ${realizedMarginLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center px-2">
                <div><span className="heading">{renderFormatMsg("Overview.RealizedMarginLosses", "Realized margin losses")} 14%</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.IncStockInStoresWH", "From total products in stock")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center px-0 ml-auto">
                {!realizedMarginLoader && <div>
                  <div className="chip m-2" style={{ backgroundColor: get(realizedMargin, 'Change.Color', "") }}>
                    {get(realizedMargin, 'Change.Value', "")} {get(realizedMargin, 'Change.Sign', "")}</div>
                </div>}
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center w-100 minHeight">
                {!realizedMarginLoader && get(realizedMargin, 'CurrentValue.From', false) && <div className="d-flex justify-content-start px-2 font-italic">
                  <div className="font15">{get(realizedMargin, 'CurrentValue.From', "")} - {get(realizedMargin, 'CurrentValue.To', "")}</div>
                </div>}
              </div>
              <div className="graphWrapper">
                {!realizedMarginLoader && <ResponsiveContainer>
                  <BarChart data={get(realizedMargin, 'Data', [])}>
                    <Bar dataKey="Value" fill="#8884d8" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </BarChart>
                </ResponsiveContainer>}
              </div>
            </div>


            <div className={`card ${absoluteOOSLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.AbsoluteOutOfStock", "Absolute out of stock")} 24%</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.Absolute", "Absolute")} ({renderFormatMsg("Overview.ShowReal", "Show Real")})</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                {!absoluteOOSLoader && <div>
                  <div className="chip m-2" style={{ backgroundColor: get(absoluteOOS, 'Change.Color', "") }}>
                    {get(absoluteOOS, 'Change.Value', "")} {get(absoluteOOS, 'Change.Sign', "")}</div>
                </div>}
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="graphWrapper">
                {!absoluteOOSLoader && <ResponsiveContainer>
                  <AreaChart data={get(absoluteOOS, 'Data', [])}>
                    <Area type="monotone" dataKey="Value" stroke="#82ca9d" fill="#82ca9d" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </AreaChart>
                </ResponsiveContainer>}
              </div>
            </div>


            <div className={`card ${discountedProductsLoader && `shine`}`}>
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.DiscountedProducts", "Discounted products")} 14%</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.IncStockInStoresWH", "From total products in stock")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                {!discountedProductsLoader && <div><div className="chip m-2" style={{ backgroundColor: get(discountedProducts, 'Change.Color', "") }}>
                  {get(discountedProducts, 'Change.Value', "")} {get(discountedProducts, 'Change.Sign', "")}</div>
                </div>}
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
              <div className="graphWrapper">
                {!discountedProductsLoader && <ResponsiveContainer>
                  <BarChart data={get(discountedProducts, 'Data', [])}>
                    <Bar dataKey="Value" fill="#8884d8" />
                    <Tooltip cursor={{ fill: 'transparent' }} content={this.CustomTooltip} />
                  </BarChart>
                </ResponsiveContainer>}
              </div>
            </div>
          </div>
        </div>

        {
          showGraph.length > 0 && <DiscountCardChart data={graphData} content={this.CustomTooltip} />
        }

        <div className="replenishment-box">
          <h3>{renderFormatMsg("Overview.ReplenishmentExecution", "Replenishment Execution")}: 28 vs 27 week <span className="c-gray">(08-14.07.19)</span></h3>

          <div className="card-container">
            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">32 {renderFormatMsg("Overview.TransfersMade", "transfers made")}</span></div>
                <div><span className="c-gray">29 {renderFormatMsg("Overview.StoresReplenished", "stores replenished")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip-ver c-gray text-center">31</div></div>
                <div><div className="chip-ver c-gray text-center">on 27 {renderFormatMsg("Overview.Week", "week")}</div></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.Heading.RealStockouts", "Real Stockouts")} 2.8%</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip-ver font-weight text-center">+0.2%</div></div>
                <div><div className="chip-ver c-gray text-center">vs 27 {renderFormatMsg("Overview.Week", "week")}</div></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">45% {renderFormatMsg("Overview.UnitsSold", "units sold")} <span className="c-gray">{renderFormatMsg("Overview.FromGoodsReplenishedOn", "from goods replenished")} on 27 {renderFormatMsg("Overview.Week", "week")}</span></span></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">3150 {renderFormatMsg("Overview.UnitsTransferred", "units transferred")}</span></div>
                <div><span className="c-gray">{renderFormatMsg("Overview.TransferredValue", "Transferred value")}: 12.2 mln.₽</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip-ver c-gray text-center">3241</div></div>
                <div><div className="chip-ver c-gray text-center">
                  {renderFormatMsg("Overview.On", "on")}&nbsp;
                  27 {renderFormatMsg("Overview.Week", "week")}</div></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.CoverageInStoresWeeks", "Coverage in stores ")} 7.2 {renderFormatMsg("Overview.Weeks", "weeks")} </span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip-ver font-weight text-center">-4.2%</div></div>
                <div><div className="chip-ver c-gray text-center">vs 27 {renderFormatMsg("Overview.Week", "week")}</div></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading"><span className="c-gray">{renderFormatMsg("Overview.ReplenishmentOn", "Replenishment on")} 27 {renderFormatMsg("Overview.Effort", "effort")}:</span> 432 012 ₽</span></div>
              </div>
            </div>
          </div>
        </div>

        <div className="real-box card p-3">
          <div className="heading">
            <div className="left">
              <h3>{renderFormatMsg("Overview.Heading.RealStockouts", "Real Stockouts")}</h3>
            </div>
            <div className="right">
              <div>Saturday, Oct 8 2.2 & -37.50% vs. {renderFormatMsg("Overview.Average", "Average")}</div>
            </div>
          </div>
          <div className="search-cond d-flex justify-content-between">
            <div className="left">
              <button className="active">6m</button>
              <button>YTD</button>
              <button>1y</button>
              <button>All</button>
            </div>
            <div className="right d-flex align-items-center w-25">
              <span className="label">{renderFormatMsg("Overview.From", "From")}From</span> <Calendar /> <span className="label">{renderFormatMsg("Overview.To", "To")}</span><Calendar />
            </div>
          </div>
          <div className="maingraph">

            <ResponsiveContainer>
              <AreaChart
                data={data}
                margin={{
                  top: 10, right: 30, left: 0, bottom: 0,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis label={{ value: 'Weeks', angle: -90, position: 'insideLeft' }} />
                <Tooltip cursor={{ fill: 'transparent' }} />
                <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="replenishment-box other-general">
          <h3>{renderFormatMsg("Overview.Heading.OtherGeneralDynamics", "Other general dynamics")} — {renderFormatMsg("Overview.Today", "today")} <span className="c-gray">(12.05.2020)</span> vs {renderFormatMsg("Overview.LastWeek", "last year")} <span className="c-gray">(12.05.2019)</span></h3>
          <div className="card-container">
            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.Opened", "Opened")} 5 &nbsp;
                {renderFormatMsg("Overview.NewStores", "new stores")},&nbsp;
                {renderFormatMsg("Overview.Closed", "closed")} 2</span></div>
              </div>
            </div>

            <div className="card">

              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.Introduced", "Introduced")} 921</span></div>
                <div><span className="">{renderFormatMsg("Overview.NewProducts", "new products")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip green">+15%</div></div>
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.Generated", "Generated")} 9321 </span></div>
                <div><span className="">{renderFormatMsg("Overview.ReplenishmentOrders", "replenishment orders")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip gray">+12%</div></div>
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
            </div>

            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
                <div><span className="heading">{renderFormatMsg("Overview.ReceivedToWH", "Received to WH ")}</span></div>
                <div><span className="">23002 {renderFormatMsg("Overview.Units", "units")}</span></div>
              </div>
              <div className="d-flex flex-sm-column justify-content-center p-2 ml-auto">
                <div><div className="chip">+5%</div></div>
                <div><div className="chip-ver c-gray">vs 12.05.19</div></div>
              </div>
            </div>


            <div className="card">
              <div className="d-flex flex-sm-column justify-content-center p-2">
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  showGraphHandler = (card, data) => {

    const { showGraph } = this.state;
    var _showGraph = Object.assign(showGraph);
    var index = showGraph.indexOf(card);

    if (index >= 0) {
      _showGraph.splice(index, 1);
    } else {
      _showGraph = [card]
    }
    this.setState({ showGraph: _showGraph, graphData: data });
  }

  CustomTooltip = (payload) => {
    var data = get(payload, "payload[0].payload", null);
    return (<div className="custom-tooltip">
      < div>{data && intToString(data.Value)} / {data && data.Sign}</ div>
      <div></div>
    </div>)
  }

  cardDateRangeHandler = (range) => {
    this.setState({ cardReportDateRange: range }, () => {
      this.getData();
    })
  }

  getData = async () => {
    const { cardReportDateRange, selectedBrandId } = this.state

    this.setState({ brandsLoader: true }, async () => {
      try {
        var brands = await getBrand();
        brands = brands && brands.brands ? brands.brands : []
        this.setState({ brands: [{ brandId: null, name: "All brands" }, ...brands], brandsLoader: false })

      } catch (error) {
        this.setState({ brandsLoader: false })
      }
    })


    this.setState({ discSalesLoader: true }, async () => {
      try {
        var discSales = await getDashboadData({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ discSales, discSalesLoader: false })

      } catch (error) {
        this.setState({ discSalesLoader: false })

      }
    })
    this.setState({ salesInUnitLoader: true }, async () => {
      try {
        var salesInUnit = await getDashboadDataForSaleUnit({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ salesInUnit, salesInUnitLoader: false })

      } catch (error) {
        this.setState({ salesInUnitLoader: false })

      }
    })
    this.setState({ salesInPriceLoader: true }, async () => {
      try {
        var salesInPrice = await getDashboadDataForSalePrice({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ salesInPrice, salesInPriceLoader: false })

      } catch (error) {
        this.setState({ salesInPriceLoader: false })

      }
    })
    this.setState({ realizedMarginLoader: true }, async () => {
      try {
        var realizedMargin = await getDashboadDataForRealizedMargin({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ realizedMargin, realizedMarginLoader: false })

      } catch (error) {
        this.setState({ realizedMarginLoader: false })

      }
    })
    this.setState({ sellOutLoader: true }, async () => {
      try {
        var sellOut = await getDashboadDataForSellOut({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ sellOut, sellOutLoader: false })

      } catch (error) {
        this.setState({ sellOutLoader: false })

      }
    })
    this.setState({ realOOSLoader: true }, async () => {
      try {
        var realOOS = await getDashboadDataForRealOOS({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ realOOS, realOOSLoader: false })

      } catch (error) {
        this.setState({ realOOSLoader: false })

      }
    })
    this.setState({ speedOfSalesLoader: true }, async () => {
      try {
        var speedOfSales = await getDashboadDataForSpeedOfSales({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ speedOfSales, speedOfSalesLoader: false })

      } catch (error) {
        this.setState({ speedOfSalesLoader: false })

      }
    })
    this.setState({ coverageLoader: true }, async () => {
      try {
        var coverage = await getDashboadDataForCoverage({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ coverage, coverageLoader: false })

      } catch (error) {
        this.setState({ coverageLoader: false })

      }
    })
    this.setState({ absoluteOOSLoader: true }, async () => {
      try {
        var absoluteOOS = await getDashboadDataForAbsoluteOOS({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ absoluteOOS, absoluteOOSLoader: false })

      } catch (error) {
        this.setState({ absoluteOOSLoader: false })

      }
    })
    this.setState({ discountedProductsLoader: true }, async () => {
      try {
        var discountedProducts = await getDashboadDataForDiscountedProducts({ dateRange: cardReportDateRange, brandId: selectedBrandId });
        this.setState({ discountedProducts, discountedProductsLoader: false })

      } catch (error) {
        this.setState({ discountedProductsLoader: false })

      }
    })

  }

  async componentDidMount() {
    this.getData();
  }

}
