import { APIResponse } from "../../repoTypes";
import { ConfirmPromotionParams } from "../types";
import { HOST_API } from "constant";
import { PROMOTION } from "../endpoint";
import axiosInstance from "services/axiosInstance";

export const confirmPromotionToRepo = (
  params: ConfirmPromotionParams
): APIResponse<string> =>
  axiosInstance.get(`${HOST_API}/${PROMOTION.confirmPromotion}`, { params });
