import "../components/Layout/Header/Header.scss";
import "../components/Modal/Modal.scss";

import { LANGUAGES, LONG_DATE_FORMAT_OPTIONS, SHORT_DATE_FORMAT_OPTIONS } from '../constant'
import { NavLink, withRouter } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { renderFormatMsg, toastError, toastSuccess } from "../shared/funs"

import { AppContext } from '../context/AppContext';
import { Dropdown } from 'react-bootstrap'
import { get } from 'lodash';
import { getUserDetails } from '../services/userAuthentication';
import { updateUserProfile } from '../services/userApi';

const UserDetail = (props) => {
  const { userDetails, setUserDetails, handleLangauge } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [formValue, setFormValue] = useState(null)
  const [viewOpen, setViewOpen] = useState(false);
  const [error, setError] = useState(false);
  const getUser = useCallback(async() => {
   try {
    const userDetails = await getUserDetails()
    let user = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      mobileNumber: userDetails.mobileNumber,
      language: userDetails.language,
      ShortDate: userDetails.shortDateFormat,
      LongDate: userDetails.longDateFormat,
      userId: userDetails.userId,
      email: userDetails.email,
      username: userDetails.username,
    }
    setUserDetails(userDetails);
    setFormValue(user);
   }
   catch(err){
    setError(err);
   }
  }, [
    setUserDetails,
  ])
  /*
 getUserDetails()
      .then((response) => {
        var user = {
          firstName: response.firstName,
          lastName: response.lastName,
          mobileNumber: response.mobileNumber,
          language: response.language,
          ShortDate: response.shortDateFormat,
          LongDate: response.longDateFormat,
          userId: response.userId,
          email: response.email,
          username: response.username,
        }
        setUserDetails(response);
        setFormValue(user);
      })
      .catch((error) => {
        setError(error);
      });
  */
  

  useEffect(() => {
    getUser()
  }, [getUser]);
  
  const handleLogout = () => {
    localStorage.clear();
    props.history.push("/login");
  };

  const handleEditUser = async () => {
    let object = {
      "firstName": get(formValue, "firstName"),
      "lastName": get(formValue, "lastName"),
      "mobileNumber": get(formValue, "mobileNumber"),
      "Language": get(formValue, "language"),
      "userId": get(formValue, "userId"),
      "shortDateFormat": get(formValue, "ShortDate"),
      "longDateFormat": get(formValue, "LongDate"),
      "email": get(formValue, "email"),
      "username": get(formValue, "username"),

    }
    setIsLoading(true);
    await updateUserProfile(object).then(res => {
      setIsLoading(false);
      setUserDetails({ ...res, accesses: userDetails.accesses, });
      handleLangauge(formValue.language);
      removeOpenClass();
      setViewOpen(false);
      toastSuccess("User setting updated");
    }).catch(err => {
      console.info(`err => `, err);
      toastError("User setting not updated");
      setViewOpen(false);
      setIsLoading(false);
      removeOpenClass();
    })
  }

  const checkValidation = () => {
    return (formValue === null || !formValue.firstName || !formValue.lastName || !formValue.mobileNumber || !formValue.language || !formValue.ShortDate || !formValue.LongDate);
  }
  const handleForm = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value })
  }

  const openViewDetail = () => {
    var div_1 = document.querySelector(".user-setting-main");
    var div_2 = document.querySelector(".view-code-user");
    div_1.classList.add("open");
    div_2.classList.add("open");
    setViewOpen(true);
  };
  const removeOpenClass = () => {
    var div_1 = document.querySelector(".user-setting-main");
    var div_2 = document.querySelector(".view-code-user");
    div_1.classList.toggle("open");
    div_2.classList.toggle("open");
  };

  const closeBox = () => {
    setViewOpen(false);
    document.getElementById("user").click();
    var div_1 = document.querySelector(".user-setting-main");
    var div_2 = document.querySelector(".view-code-user");
    div_1.classList.remove("open");
    div_2.classList.remove("open");
  };


  return (
    <React.Fragment>
      <Dropdown className="user-box">
        <Dropdown.Toggle id="user" className="email-label">
          {!error ? <><p>
            {`${get(userDetails, "firstName", "")} ${get(userDetails, "lastName", "")}`}
            <span>{get(userDetails, "email")}</span>
          </p>
            <img src={get(userDetails, "profile.photoUrl")} alt="UserImage" /></> : <p>Admin</p>}
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="notification">
          <div className="horizontal" style={{ height: `${viewOpen ? "480px" : "auto"}` }}>
            <div className="notification-main user-setting-main">
              <ul>

                <li>
                  <div onClick={(e) => { openViewDetail(); e.preventDefault(); }}>
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.Settings", "Settings")}
                  </div>
                  <i className="icon-expand ml-auto"></i>
                </li>

                <li>
                  <NavLink to={`/change-password`} onClick={closeBox}>
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.ChangePassword", "Change Password")}
                  </NavLink>
                </li>

                <li>
                  <div>
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.LeaveFeedback", "Leave Feedback")}
                  </div>
                </li>

                <li>
                  <div onClick={() => handleLogout()} style={{ cursor: "pointer" }}>
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.Logout", "Logout")}
                  </div>
                </li>
              </ul>

              <ul className="worldwide-boxs">

                <li>
                  <div>
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.CustomerId", "Customer ID")}
                    : <span>493-057-1492</span>
                  </div>
                </li>

              </ul>
            </div>
            <div className="view-code view-code-user">
              {viewOpen &&
                <div className="notification-boxs">
                  <div>
                    <button
                      type="button"
                      className="icon-close close-btn"
                      onClick={closeBox}
                    ></button>
                    <h2>{renderFormatMsg("UserSettings", "User Settings")}</h2>
                  </div>
                  <div className="content">
                    <div className="frm_full">
                      <div className="frm_grp">
                        <lable>{renderFormatMsg("FirstName", "First Name")}</lable>
                        <input defaultValue={get(userDetails, "firstName")} type="text" name="firstName" onChange={(e) => handleForm(e)} />
                      </div>
                      <div className="frm_grp">
                        <lable>{renderFormatMsg("LastName", "Last Name")}</lable>
                        <input defaultValue={get(userDetails, "lastName")} type="text" name="lastName" onChange={(e) => handleForm(e)} />
                      </div>
                      <div className="frm_grp full-width">
                        <lable>{renderFormatMsg("MobilePhone", "Mobile phone")}</lable>
                        <input defaultValue={get(userDetails, "mobileNumber")} type="text" name="mobileNumber" onChange={(e) => handleForm(e)} />
                      </div>
                      <div className="frm_grp full-width">
                        <lable>{renderFormatMsg("Language", "Language")}</lable>
                        <select name="language" onChange={e => handleForm(e)} >
                          {
                            LANGUAGES.map(lang =>
                              <option value={lang.value} selected={formValue && formValue.language === lang.value}> {lang.label} </option>
                            )
                          }
                        </select>
                      </div>
                      <div className="frm_grp full-width">
                        <lable>{renderFormatMsg("ShortDateFormat", "Short Date Format")}</lable>
                        <select name="ShortDate" defaultValue={formValue && formValue.ShortDate} onChange={e => handleForm(e)} >
                          {
                            SHORT_DATE_FORMAT_OPTIONS.map((opt, index) =>
                              <option key={index} value={opt.value}> {opt.label} </option>
                            )
                          }
                        </select>
                      </div>
                      <div className="frm_grp full-width">
                        <lable>{renderFormatMsg("LongDateFormat", "Long Date Format")}</lable>
                        <select name="LongDate" defaultValue={formValue && formValue.LongDate} onChange={e => handleForm(e)} >
                          {
                            LONG_DATE_FORMAT_OPTIONS.map((opt, index) =>
                              <option key={index} value={opt.value}> {opt.label} </option>
                            )
                          }
                        </select>
                      </div>
                      <div class="btn_pop">
                        <button
                          className="btn_white"
                          onClick={() => {
                            removeOpenClass();
                            setViewOpen(false);
                          }
                          }>
                          {renderFormatMsg("Cancel", "Cancel")}
                        </button>
                        <button
                          className="btn_grey"
                          onClick={() => handleEditUser()}
                          disabled={checkValidation()}
                        >
                          {isLoading ? renderFormatMsg("Editing", "Editing") : renderFormatMsg("Edit", "Edit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment >
  )
}

export default React.memo(withRouter(UserDetail));
