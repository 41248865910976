import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import React, { Suspense, lazy, useContext } from "react";
import { Route, Switch } from "react-router-dom";

import { AppContext } from "./context/AppContext";
import { IntlProvider } from "react-intl";
import Routes from "./routes";
import StartCall from "./v2/views/layouts/startCall";

const Login = lazy(() => import('./view/Login/Login'));

if (window.location.protocol === 'https:') {
  console.log = () => { }
  console.info = () => { }
  console.error = () => { }
  console.warn = () => { }
}


const App = ({ messages }) => {
  const { language } = useContext(AppContext);
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route exact path="/start-call" component={StartCall}/>
          <Routes />
        </Switch>
      </Suspense>
    </IntlProvider>
  );
}

export default React.memo(App);
