import {
  IValidConditionalElement,
  SectionParams,
} from "v2/core/repository/forms/types";
import { useCallback, useEffect, useState } from "react";

import { getValidConditionalElementsFromRepo } from "v2/core/repository/forms/getValidConditionalElementsFromRepo";

const useGetValidConditionalElements = (
  id = "00000000-0000-0000-0000-000000000000",
  formId: string,
  lazy = false
) => {
  const [validElements, setValidElements] = useState<
    IValidConditionalElement[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getConditionalValidElements = useCallback(async () => {
    setLoading(true);
    const params: SectionParams = {
      sectionId: id,
      formId,
    };
    try {
      const response = await getValidConditionalElementsFromRepo(params);
      const validElements = response as unknown as IValidConditionalElement[];
      setValidElements(validElements);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [formId, id]);

  useEffect(() => {
    if (!lazy) getConditionalValidElements();
  }, [getConditionalValidElements, lazy]);

  return {
    loading,
    error,
    getConditionalValidElements,
    validElements,
  };
};

export default useGetValidConditionalElements;
