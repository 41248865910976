import { Field, Form } from 'react-final-form';
import React, { useContext, useEffect, useState } from 'react'
import { addNewClient, getClientData, updateClientData } from '../../services/toolsAndSettingsPagesApi'
import { checkPermission, findPermission, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ActiveFromAndToDate from '../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import { AppContext } from '../../context/AppContext';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import { checkConfirmationRecipient } from '../PromotionType/util/checkConfirmationRecipient';
import { get } from 'lodash';

export default function HandleClient() {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const clientId = params.clientId;
  const [formValue, setFormValue] = useState(null)
  const { userDetails } = useContext(AppContext)
  const [, setPageReadOnly] = useState({ read: true, write: false })

  let dataArray = get(userDetails, "accesses")



  var heading = "AddClient";

  if (location.pathname.includes("Edit")) {
    heading = "EditClient";
  }


  useEffect(() => {
    if(dataArray){
      var permission =  checkPermission(dataArray, findPermission("CREATE_EDIT_CLIENT").screenId)
      setPageReadOnly(permission)
      if (!permission.write) {
      history.goBack();
       }

    }
    if (location.pathname.includes("Edit") && clientId) {
      let param = { "page": 1, "display": 1, "search": params.clientId }
      getClientData(param).then(res => {
        setFormValue(res.clients[0])
      }).catch(err => {
        console.info('err => ', err);
      })
    }
  }, [
    dataArray,
    location.pathname,
    params.clientId,
    history,
    clientId
  ])

  const handleAddClient = (data) => {
    const object = {
      "managerEMail": data.managerEMail,
      "clientName": data.clientName,
      "activeFrom": data.activeFrom,
      "activeTo": data.activeTo
    }
   
    addNewClient(object).then(res => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully"))
      history.push("/client")
    }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record add fail")))
    
  }

  const handleEditForm = (data) => {
    const object = {
      "clientId": data.clientId,
      "managerEMail": data.managerEMail,
      "clientName": data.clientName,
      "activeFrom": data.activeFrom,
      "activeTo": data.activeTo
    }

    updateClientData(object).then(res => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      history.push("/client")
    }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
  }
  
  const handleValidation = values => {
    const errors = {};
    if (values.managerEMail) {
      const checkEmail = checkConfirmationRecipient(values.managerEMail, "Invalid email address(es) found in the Manager Email recipients:", false)
      if(!checkEmail){
        errors.managerEMail = "Invalid email address(es) found in the Manager Email recipients:"
      }
    }
    if(!values.clientName){
      errors.clientName = "Client Name is required"
    }
    if(!values.activeFrom){
      errors.activeFrom = "Active From is required"
    }
    if(!values.activeTo){
      errors.activeTo = "Active To is required"
    }
    if(values.activeFrom && values.activeTo){
      if(values.activeFrom > values.activeTo){
        // compare dates
        const activeFrom = new Date(values.activeFrom)
        const activeTo = new Date(values.activeTo)
        if(activeFrom > activeTo){
          errors.activeFrom = "Active From date should be less than Active To date"
        }
      }
    }
    return errors;
  }
  
  const isEdit = location.pathname.includes("Edit") && clientId

  return (
    <div className="main-content">
    
      <PageHeading headingId={heading} baseURL="/client" />
      <div className="card p-3">
        <Form onSubmit={isEdit ? handleEditForm : handleAddClient}
          initialValues={formValue}
          validate={handleValidation}
          
        >
          {({ handleSubmit, submitting }) => {
            return (
              <>
                <form onSubmit={handleSubmit} className="row align-items-end form">
                <Field name="clientName">
                    {({ input, meta }) => (
                      <div className="form-group col-sm-6">
                        <label> {renderFormatMsg("clientPageTable.ClientName", "Client Name")} </label>
                        <input {...input} type="text" placeholder="Client Name" className="form-control" />
                        {meta.error && meta.touched && (<span>{meta.error}</span>)}
                      </div>
                    )}
                  </Field>
                  <InputField
                    colClass="col-lg-6"
                    name="managerEMail"
                    id="ManagerEmail"
                    type="email"
                    defaultLabel="ManagerEmail"
                    placeholder={"Manager Email"}
                    className="form-control"
                  />
                 
                  <div className="col-6">
                    <ActiveFromAndToDate />
                  </div>
                  <div className="col-6">
                  </div>
                  <div className="col-12 d-flex">
                    <div className="submit_btn d-flex justify-content-end w-100 mx-3 mt-1">
                      <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary"
                      >
                        {renderFormatMsg("Save", "Save")}
                      </button>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </Form>
      </div>
    </div >

  )
}
