import React from "react";
import { renderFormatMsg } from "shared/funs";
type CellProps = {
  value: string;
};
export const getUnfinishedTaskColumns = (changeFontSize: string) => [
  {
    Header: (
      <div className="text-left">
        {renderFormatMsg("AgentName", "Agent Name")}
      </div>
    ),
    accessor: "agentName",
    resizable: false,
    style: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      fontSize: `${changeFontSize}`,
      justifyContent: "flex-start",
    },
    headerClassName: "xyz",
    className: "abc",
  },
  {
    Header: (
      <div className="text-left">
        {renderFormatMsg("AgentPhone", "Agent Phone")}
      </div>
    ),
    accessor: "agentPhone",
    resizable: false,
    style: { justifyContent: "flex-start", fontSize: `${changeFontSize}` },
  },
  {
    Header: (
      <div className="text-left">
        {renderFormatMsg("Promotion", "Promotion")}
      </div>
    ),
    accessor: "promotionDescription",
    resizable: false,
    style: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      fontSize: `${changeFontSize}`,
      justifyContent: "flex-start",
    },
  },
  {
    Header: (
      <div className="text-left">
        {renderFormatMsg("CallRecordID", "Call Record ID")}
      </div>
    ),
    accessor: "callRecordId",
    resizable: false,
    style: { fontSize: `${changeFontSize}`, justifyContent: "flex-start" },
  },
  {
    Header: <div>{renderFormatMsg("TaskURL", "Task URL")}</div>,
    accessor: "taskUrl",
    Cell: (props: CellProps) => {
      return (
        <a
          className="text-primary"
          href={props.value}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontSize: `${changeFontSize}`,
            justifyContent: "flex-start",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          Open
        </a>
      );
    },
  },
];
