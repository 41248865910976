import { HOST_API } from "../constant";
import axiosInstance from "./axiosInstance";
import { getToken } from "../shared/funs";

export const get = async (url, params) => {
  if (getToken()) {
    return await axiosInstance.get(`${HOST_API}/${url}`, { params });
  } else {
    // eslint-disable-next-line no-throw-literal
    throw "No token Found";
  }
};
export const post = async (url, data, onProgress) => {
  if (getToken()) {
    return await axiosInstance.post(`${HOST_API}/${url}`, data, {
      onUploadProgress: (progressEvent) => {
        if (onProgress) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          onProgress(percent);
        }
      },
      progressEvent: "upload",
    });
  } else {
    throw new Error("No token Found");
  }
};

export const put = async (url, data = {}) => {
  if (getToken()) {
    return await axiosInstance.put(`${HOST_API}/${url}`, data);
  } else {
    // eslint-disable-next-line no-throw-literal
    throw "No token Found";
  }
};

export const deleted = async (url) => {
  if (getToken()) {
    return await axiosInstance.delete(`${HOST_API}/${url}`);
  } else {
    // eslint-disable-next-line no-throw-literal
    throw "No token Found";
  }
};
