import React, { useCallback, useEffect, useState } from 'react'
import { addUpdateUserNotification, blockedNotification, getAllNotifications, getHasUpdateNotification } from '../services/notificationAPI';
import { renderFormatMsg, toastError } from "./../shared/funs"

import BackIcon from "../images/back-icon.svg";
import { Dropdown } from 'react-bootstrap'
import LampIcon from "../images/lamp.svg";
import { Link } from 'react-router-dom'
import { NOTIFICATIONS_INTERVAL } from '../constant';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';

const NotificationDetail = () => {
  const [notification, setNotification] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [viewDetailData, setViewDetailData] = useState(false);
  const [hasUpdateNotification, SetHasUpdateNotification] = useState(false);

  useEffect(() => {
    getHasUpdateNotification()
      .then((response) => {
        SetHasUpdateNotification(response && response.isUpdateAvailable ? response.isUpdateAvailable : false);
      })
      .catch(() => {
        toastError('Cannot get has update notification');
      });

    //check every 10 mins
    setInterval(() => {
      getHasUpdateNotification().then((response) => {
        SetHasUpdateNotification(response && response.isUpdateAvailable ? response.isUpdateAvailable : false);
      });
    }, NOTIFICATIONS_INTERVAL);

  }, []);


const getNotifications = useCallback(async () => {
  const params = {
    page,
    display: 10,
    status: true
  }
  

  setIsLoading(true);
  try {
    const resp = await getAllNotifications(params)
    if(resp.notification){
      setNotification([...notification, ...resp.notification]);
    } 
    else setHasMore(page < resp.totalPage);
  } 
    catch (error) {
      setHasMore(false);
    }
    setIsLoading(false);
     
  }, [page, notification]);

  useEffect(() => {
    getNotifications()
  }, [getNotifications]);




  const openViewDetail = (data) => {
    var div_1 = document.querySelector(".notification-main");
    var div_2 = document.querySelector(".view-code");
    div_1.classList.add("open");
    div_2.classList.add("open");
    setViewOpen(true);
    setViewDetailData(data);
  };

  const removeOpenClass = () => {
    var div_1 = document.querySelector(".notification-main");
    var div_2 = document.querySelector(".view-code");
    div_1.classList.remove("open");
    div_2.classList.remove("open");
  };

  const closeBox = () => {
    document.getElementById("notification").click();
  };

  const dismissNotification = (id) => {
    addUpdateUserNotification(id)
      .then((response) => {
        getAllNotifications().then((res) => {
          setNotification(res.notifications);
          setViewDetailData(null);
          removeOpenClass();
        });
      })
      .catch((error) => {
      });
  };

  const handleBlockedNotification = async (notificationTypeId) => {
    await blockedNotification(notificationTypeId)
      .then((response) => {
        removeOpenClass();
      })
      .catch((error) => {
        toastError("This notification is already blocked !");
      });
  };
  return (
    <Dropdown className="notification">
      <Dropdown.Toggle id="notification">
        <i className="icon-notification" onClick={() => SetHasUpdateNotification(false)} ></i>
        {hasUpdateNotification && <span>!</span>}
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight id="notification_box">
        <button
          type="button"
          className="icon-close close-btn dropdown-toggle"
          onClick={closeBox}
        ></button>
        <h2> {renderFormatMsg("header.NotificationsOverview.Heading", "Notifications")} </h2>
        <Scrollbars autoHide style={{ width: "100%", height: 400 }}>
          <div className="horizontal" style={{ overflowX: "unset" }}>
            <div className="notification-main">
              {notification && notification.length > 0 ?
                notification.map((data, i) => (
                  <div key={i} className="notification-boxs">
                    {data.type === "Error" ? (
                      <i className="icon-question"></i>
                    ) : data.type === "Success" ? (
                      <img src={LampIcon} alt="LampIcon" />
                    ) : (
                      ""
                    )}
                    <div className="content">
                      <h3>{data.headline}</h3>
                      <p dangerouslySetInnerHTML={{ __html: data.payload.messageToDisplay, }} ></p>
                      <div className="detail-box">
                        <span className="view" onClick={() => openViewDetail(data)} >
                          <i className="icon-view"></i>
                          {renderFormatMsg("header.NotificationsOverview.View", "View")}
                        </span>
                        <span className="dismiss" onClick={() => dismissNotification(data.notificationId)} >
                          <i className="icon-dislike"></i>
                          {renderFormatMsg("header.NotificationsOverview.Dismiss", "Dismiss")}
                        </span>
                        <span className="calendar ml-auto">
                          <i className="icon-calendar"></i>
                          {moment(data.timestamp).format("DD.MM.YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                )) : "No notifications available"
              }
              {hasMore &&
                <div className={"d-flex justify-content-center"} >
                  <span style={{
                    cursor: "pointer",
                    fontSize: "13px",
                    marginBottom: "15px",
                    textDecoration: "underline",
                    color: "#6045e3"
                  }} onClick={() => setPage(page + 1)}>{isLoading ? "Loading.." : "Load more"}</span>
                </div>
              }
            </div>

            <div className="view-code">
              <div className="back-button" onClick={removeOpenClass}>
                <img src={BackIcon} alt="BackIcon" />
              </div>
              {viewOpen && viewDetailData !== null && (
                <div className="notification-boxs">
                  {
                    viewDetailData.type === "Error" ? <i className="icon-question"></i>
                      : viewDetailData.type === "Success" && <img src={LampIcon} alt="LampIcon" />
                  }

                  <div className="content">
                    <h3>{viewDetailData && viewDetailData.headline}</h3>
                    <p>
                      <span>
                        {renderFormatMsg("header.NotificationDetail.Detected", "Detected")} : </span>
                      {moment(
                        viewDetailData && viewDetailData.timestamp
                      ).format("DD.MM.YYYY HH:mm A")}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          viewDetailData && viewDetailData.payload.messageToDisplay,
                      }}
                    ></p>
                    <div className="detail-box">
                      <span
                        className="dismiss"
                        onClick={() => dismissNotification(viewDetailData && viewDetailData.notificationId)}
                      >
                        <i className="icon-dislike"></i>
                        {renderFormatMsg("header.NotificationDetail.Dismiss", "Dismiss")}
                      </span>
                      <span
                        href="#"
                        className="dismiss ml-auto"
                        onClick={() =>
                          handleBlockedNotification(
                            viewDetailData &&
                            viewDetailData.notificationTypeId
                          )
                        }
                      >
                        {renderFormatMsg("header.NotificationDetail.DontReportAgain", "DON´T REPORT THIS AGAIN")} </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Scrollbars>
        <Link onClick={() => closeBox()} to="/notifications-settings" className="configure-btn">
          <i className="icon-right-arrow"></i>
          {renderFormatMsg("header.NotificationOverview.ConfigureNotification", "Configure Notification Settings")}
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default React.memo(NotificationDetail)
