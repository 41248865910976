import { DateRangeStatus, checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastError, toastSuccess, updateActiveFromAndToRow } from "../../shared/funs"
import React, { useContext, useEffect, useState } from 'react'
import { debounce, get } from "lodash"
import { getClientData, updateClientData } from '../../services/toolsAndSettingsPagesApi';

import { AppContext } from "../../context/AppContext";
import Calendar from "../../components/Calendar"
import Error from "../../components/Error/Error"
import { Link } from "react-router-dom"
import LinkButton from '../../components/Buttons/LinkButton';
import { PAGE_SIZE } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';

const ClientPage = () => {
    const { userDetails } = useContext(AppContext)
    const [clientData, setClientData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [error, setError] = useState(false)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_CLIENT").screenId))
    }, [dataArray])

    const getData = (page = 0, search = "") => {
        setSearchValue(search)
        let param = {
            "page": page + 1,
            "display": PAGE_SIZE,
            "search": search
        }
        getClientData(param).then(res => {
            setClientData(res.clients.length > 0 ? res.clients : [])
            setTotalPage(res.totalPage)
        }).catch(err => {
            console.info('err =>', err);
            setError(permissionErrorCheck(err))
        })
    }


    const sendQuery = (object, data) => {
        updateClientData(object).then(res => {
            setClientData(data.data)
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
        }).catch(error => {
            toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
        })
    };

    const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);



    const handleChangeDate = async (date, details, key) => {

        var data = updateActiveFromAndToRow(clientData, key, date, { "clientId": details.clientId });

        delayedQuery(data.payload, data)
    }

    const columns = [{
        Header: <div className="text-left">
            {renderFormatMsg("clientPageTable.ClientID", "Client ID")}</div>,
        accessor: 'clientId',
        resizable: false,
        style: { justifyContent: "flex-start" }
    }, {
        Header: <div className="text-left">
            {renderFormatMsg("clientPageTable.ClientName", "Client Name")}</div>,
        accessor: 'clientName',
        resizable: false,
        style: { justifyContent: "flex-start" }
    }, {
        Header: renderFormatMsg("ActiveFrom", "Active From"),
        accessor: 'activeFrom',
        resizable: false,
        Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
            <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="activeFrom" selectedDate={new Date(props.original.activeFrom)}
                maxDate={new Date(props.original.activeTo)}
                onSelect={data => handleChangeDate(data, props.original, "activeFrom")}
            />
        </div>
    },
    {
        Header: renderFormatMsg("ActiveTo", "Active to"),
        resizable: false,
        accessor: 'activeTo',
        Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
            <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="activeTo" selectedDate={new Date(props.original.activeTo)} minDate={new Date(props.original.activeFrom)} onSelect={data => handleChangeDate(data, props.original, "activeTo")} /></div>
    },]

    if (pageReadOnly.write) {
        columns.push({
            Header: pageReadOnly.write && renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            resizable: false,
            Cell: props => pageReadOnly.write && <Link to={`/client/Edit/${props.original.clientId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
        })
    }
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData(state.page, searchValue)
        setPageIndex(state.page)
    }

    const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }

    return (
        <div className="main-content">
           
            <PageHeading headingId={"clientPageTable.Heading"} />
            <div className="card">

                {error ? <Error msg={error} /> : <>
                    <div className="card-header">
                        <SearchBox searchHandler={SearchHandler} />
                        {pageReadOnly.write && <LinkButton disabled={!pageReadOnly.write} to="/client/Add" id="AddClient" defaultText="Add Client" />}
                    </div>
                    <ReactTable data={clientData} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} /></>}
            </div>
        </div >
    )
}
export default React.memo(ClientPage);
