import { ERROR_OPTIONS, WARNING_OPTIONS } from '../../constant';
import React, { useContext, useState } from 'react'
import { permissionErrorCheck, renderFormatMsg } from '../../shared/funs';

import { AppContext } from '../../context/AppContext';
import Error from "../../components/Error/Error";
import { Link } from "react-router-dom"
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import SelectDropDown from '../../components/SelectDropDown/SelectDropDown';
import { debounce } from '@material-ui/core';
import { get } from 'lodash';
import { getPromotionDetails } from '../../services/toolsAndSettingsPagesApi';
import moment from 'moment';
import queryString from 'query-string';

const PAGE_SIZE = 20;

const PromotionDetails = (props) => {

  const [promotionDetails, setPromotionDetails] = useState([])
  const [lastState, setLastState] = useState({ page: 0, display: PAGE_SIZE })
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [, setSearchParam] = useState({});


  let longDateFormat = get(userDetails, "longDateFormat", "DD.MM.YYYY HH:mm a")


  

  const getData = async (data) => {

    let param = {
      promoId: props.match.params.id,
      phone: data.search,
      hasWarning: data.hasWarning,
      hasErrors: data.hasErrors,
      page: data.page,
      display: data.display,
    }

    setSearchParam(data)
    getPromotionDetails(param).then(res => {

      setLoading(false)
      setPromotionDetails(res.items)
      setTotalPage(res.totalPage)
      setError(false)
    }).catch(err => {
      setError(permissionErrorCheck(err))
      setLoading(false)
      setPromotionDetails([])
    })
  }



  const fetchData = (state) => {

    var interval_id = window.setInterval(() => { }, 9999); // Get a reference to the last

    for (var i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    setPageIndex(state.page)
    setLastState(state)

    //getting change page number from state
    getData({ page: state.page + 1, display: PAGE_SIZE, search: state.search, hasWarning: state.hasWarning, hasErrors: state.hasErrors })
  }

 

  const delayedQuery = debounce((state) => fetchData(state), 1000);

  const SearchHandler = (e) => {
    // console.info('e.target.value => ', e.target.value);

    setPageIndex(0);
    setLastState({
      ...lastState, search: e.target.value
    })
    // fetchData({ page: 0, display: PAGE_SIZE, search: e.target.value })
    delayedQuery({ ...lastState, search: e.target.value });
  }


  const handleWarningChange = (e) => {

    if (e.target.value === '') {
      // delayedQuery({ page: 0, display: PAGE_SIZE });
      delete lastState.hasWarning
    } else {
      delayedQuery({ ...lastState, hasWarning: e.target.value });
    }
  }

  const handleErrorChange = (e) => {

    if (e.target.value === '') {
      // delayedQuery({ page: 0, display: PAGE_SIZE });
      delete lastState.hasErrors
    } else {
      delayedQuery({ ...lastState, hasErrors: e.target.value });
    }
  }

  const generateWarningOptions = () => {
    var options = []
    WARNING_OPTIONS.map((option, index) =>
      options.push(<option value={option.value}>{option.label}</option>)
    )
    return options;
  }

  const generateErrorOptions = () => {
    var options = []
    ERROR_OPTIONS.map((option, index) =>
      options.push(<option value={option.value}>{option.label}</option>)
    )
    return options;
  }

  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Phone", "Phone")}</div>,
      accessor: 'phone',
      minWidth: 130,
      maxWidth: 250,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("LastChange", "Last Change")}</div>,
      accessor: 'lastChange',
      minWidth: 150,
      maxWidth: 250,
      resizable: false,
      style: { justifyContent: "flex-start" },
      Cell: props => <div>{get(props, "original.lastChange") && moment(props.original.lastChange).format(longDateFormat)}</div>
    },
    {
      Header: renderFormatMsg("Warnings", "Warnings"),
      accessor: 'warningCount',
      resizable: false,
      width: 130,
      maxWidth: 200,
    }, {
      Header: renderFormatMsg("Errors", "Errors"),
      accessor: 'errorCount',
      resizable: false,
      width: 130,
      maxWidth: 200,
    },
    {
      Header: renderFormatMsg("Details", "Details"),
      accessor: 'details',
      resizable: false,
      width: 130,
      maxWidth: 200,
      Cell: propsPhoneDetail => <Link to={`/promotions/details/${props.match.params.id}/${propsPhoneDetail.original.taskId}`}>{renderFormatMsg("Details", "Details")}...</Link>
    },
  ]

  const parsed = queryString.parse(props.location.search);

  return (
    <div className="main-content">
      <PageHeading headingId="Promotion Details" isLoading={isLoading} backURL={`/promotions`} />

      <div className="card promotion">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <div className='row'>

                <SearchBox searchHandler={SearchHandler} value={parsed.search} className="col-lg-3 col-md-3 col-sm-12 mr-0" />
                <SelectDropDown handleChange={handleWarningChange} options={generateWarningOptions()} name="Show Warnings" className="col-lg-3 col-md-3 col-sm-12 mr-0" />
                <SelectDropDown handleChange={handleErrorChange} options={generateErrorOptions()} name="Show Errors" className="col-lg-3 col-md-3 col-sm-12 mr-0" />
              </div>
            </div>

            <ReactTable data={promotionDetails} columns={columns} isLoading={isLoading} onFetchData={(state) => fetchData({ ...state, search: parsed.search })} pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div >
  )
}
export default React.memo(PromotionDetails)
