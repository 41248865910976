import React, { useContext, useEffect, useState } from 'react'
import ReactTable from '../../components/ReactTable/ReactTable';
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastSuccess, } from '../../shared/funs';
import Error from "../../components/Error/Error";
import { deleteConstants, getConstants } from '../../services/sidebarApi';
import { PAGE_SIZE } from '../../constant';
import { Link } from 'react-router-dom';
import LinkButton from '../../components/Buttons/LinkButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';


const Constants = () => {
  const [constants, setConstants] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_CONSTANTS").screenId))
  }, [dataArray])


  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
    }
    getConstants(param).then(res => {
      setLoading(false)
      setConstants(res.constants)
      setTotalPage(res.totalPage)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setConstants([])
    })
  }


  const removeConstants = (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteConstants('constantId', id).then(res => {
              var _constants = Object.assign([], constants);
              _constants = _constants.filter(data => data.constantId !== id)
              setConstants(_constants)
              toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
              setLoading(false)
            }).catch(err => {
              setError(permissionErrorCheck("Something went wrong! please try after some time!"))
              setLoading(false)
            })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("clientPageTable.ClientName", "Client Name")}</div>,
      accessor: 'clientName',
      minWidth: 150,
      maxWidth: 200,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
      accessor: 'constantName',
      minWidth: 100,
      maxWidth: 200,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Description", "Description")}</div>,
      accessor: 'description',
      minWidth: 200,
      maxWidth: 380,
      width: 250,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Type", "Type")}</div>,
      accessor: 'type',
      minWidth: 120,
      maxWidth: 120,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },


  ]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      resizable: false,
      width: 120,
      Cell: props => <Link to={`/constants/Edit/${props.original.constantId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }

  columns.push({
    Header: renderFormatMsg("Delete", "Delete"),
    accessor: 'Delete',
    resizable: false,
    width: 120,
    Cell: props => <Link to="#" onClick={() => removeConstants(props.original.constantId)} className="delete-icon">{renderFormatMsg("Delete", "Delete")}</Link>
  })

  const fetchData = (state, instance) => {
    getData({ page: state.page, search: searchValue })
    setPageIndex(state.page)
  }

  return (
    <div className="main-content">
      <PageHeading headingId="Constants" isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              {pageReadOnly.write && <LinkButton classes={"ml-auto"} to="/constants/Add" id="AddConstant" defaultText="Add Constant" />}
            </div>
            <ReactTable data={constants} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div>
  )
}
export default React.memo(Constants)
