import { Field, Form } from 'react-final-form';
import React, { useEffect, useState } from 'react'
import { addForm, getClientData, getFormById, updateForm } from '../../services/toolsAndSettingsPagesApi';
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import Error from '../Error/Error';
import FieldErrorMessage from '../FieldErrorMessage';
import InputField from '../Fields/InputField';
import PageHeading from '../PageHeading/PageHeading';
import Select from 'react-select'
import { get } from "lodash";

const HandleForms = (props) => {
  const [variable, setVariable] = useState(null)
  const [clients, setClients] = useState([])
  const [error, setError] = useState(false)

  var heading = "EditForm"

  var values = {};


  if (props.location.pathname.includes("Edit")) {
    values = {
      clientId: get(variable, "clientId", ""),
      formId: get(variable, "formId", ""),
      name: get(variable, "name", ""),
      hasSuccessButton: get(variable, "hasSuccessButton", ""),
      hasRejectButton: get(variable, "hasRejectButton", ""),
    }
  } else {
    heading = "AddForm"
    values = {
      formId: "",
      name: "",
      hasSuccessButton: "",
      hasRejectButton: ""
    }
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getFormById({ id: props.match.params.id }).then(res => {
        console.log("res =>",res)
        setVariable(res)
      }).catch(err => {
        setVariable(null)
        setError(permissionErrorCheck(get(err, "data.value.errors.description", "Something went wrong, please try after some time!")))
      })
    }
  }, [
    props.location.pathname,
    props.match.params.id
  ])

  const handleEditForm = async (data) => {
    let object = {
      formId: props.match.params.id,
      clientId: data.clientId,
      name: data.name,
      hasSuccessButton: data.hasSuccessButton,
      hasRejectButton: data.hasRejectButton,
    }
    console.info('object => ', object);

    updateForm(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      props.history.push("/forms")
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })

  }


  const handleAddForm = async (data) => {
    let object = {
      "clientId": data.clientId,
      // "formId": data.formId,
      "name": data.name,
      "hasSuccessButton": data.hasSuccessButton,
      "hasRejectButton": data.hasRejectButton,
    }
    console.info('object => ', object);

    addForm(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully"))
      props.history.push("/forms")
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }

  const validation = (data) => {
    let errors = {}
    if (!data.clientId || data.clientId === "") {
      errors.clientId = "Required";
    }
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    
    return errors
  }

  const getClients = (search = "") => {
    getClientData({
      page: 0,
      display: 9999999,
      search: search
    }).then(clients => {
      var tempClient = []
      clients.clients.forEach(client => {
        tempClient.push({
          value: client.clientName,
          label: client.clientName,
          clientId: client.clientId
        })
      })
      setClients(tempClient)
    }).catch(err => {
    })
  }

  useEffect(() => {
    var allClient = []
    getClientData({
      page: 0,
      display: 9999999,
    }).then(clients => {

      clients.clients.forEach(client => {
        allClient.push({
          value: client.clientName,
          label: client.clientName,
          clientId: client.clientId
        })
      })
      setClients(allClient)

    }).catch(err => {
    })
  },[])


  

  return (
    <div className="main-content">
     
      <PageHeading headingId={heading} backURL={"/forms"} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Edit") ? handleEditForm : handleAddForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="row">
                   
                    <div className="col-lg-6">
                      <Field name="clientId">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("ClientName", "Client Name")} </label>
                            <Select
                              value={clients.filter(option => option.clientId === input.value)}
                              onChange={(value) => input.onChange(value.clientId)}
                              onInputChange={(inputValue) => {
                                getClients(inputValue)
                              }
                              } options={clients} />


                            {meta.error && meta.touched && (<span>{meta.error}</span>)}
                          </div>
                        )}
                      </Field>
                    </div>
                   
                    <InputField
                      name={"name"}
                      type="text"
                      colClass="col-md-6"
                      id="FormName"
                      defaultLabel="FormName"
                      placeholder={"Form Name"}
                    />

                 

                   <Field name="hasSuccessButton" type="checkbox">
                        {({ input, meta }) => (
                          <div className="col-lg-6">
                            <div class="checkbox">

                              <input {...input} id="hasSuccessButton" />
                              
                              <label></label>
                              <lable for="hasSuccessButton" style={{ width: "auto !important" }}> {renderFormatMsg("SuccessButton", "SuccessButton")} </lable>
                            </div>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>

                   <Field name="hasRejectButton" type="checkbox">
                        {({ input, meta }) => (
                          <div className="col-lg-6">
                            <div class="checkbox">
                              <input {...input} id="hasRejectButton" />
                              <label></label>
                              <lable for="hasRejectButton" style={{ width: "auto !important" }}> {renderFormatMsg("RejectButton", "RejectButton")} </lable>
                            </div>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>

                  
                  </div>
                </div>

                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandleForms)
