import { toastError, toastSuccess } from "../../../../shared/funs";
import { useCallback, useState } from "react";

import { ReportFieldPayload } from "./../../repository/report/types";
import { updateReportFieldByColumnId } from "./../../repository/report/updateReportFieldByColumnId";

const useUpdateReportFieldById = (
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  const [loading, setLoading] = useState(false);
  const updateReportField = useCallback(
    async (data: ReportFieldPayload) => {
      setLoading(true);
      try {
        await updateReportFieldByColumnId(data);
        toastSuccess("Field updated successfully");
        onSuccess?.();
      } catch (error) {
        toastError("Error updating report field");
        onError?.(error as Error);
      }
      setLoading(false);
    },
    [onError, onSuccess]
  );
  return {
    loading,
    updateReportField,
  };
};

export default useUpdateReportFieldById;
