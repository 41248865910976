import { Link } from "react-router-dom";
import React from "react";
import { injectIntl } from "react-intl";
const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14.722"
    viewBox="0 0 18 14.722"
  >
    <path
      id="Path_173"
      data-name="Path 173"
      d="M100.9,10.635,99.75,9.478l-5.39,5.39V0H92.723V14.868L87.332,9.476l-1.151,1.157L93.541,18Z"
      transform="translate(18 -86.181) rotate(90)"
      fill="var(--color-primary-hover)"
    />
  </svg>
);

type PageHeadingProps = {
  intl: any;
  headingId: string;
  isLoading?: boolean;
  backURL?: string;
  children?: any;
  onClick?: any;
};

const PageHeading = ({
  intl,
  headingId,
  isLoading = false,
  backURL,
  children,
  onClick,
}: PageHeadingProps) => {
  const formatMessage = (id: string) => intl.formatMessage({ id });
  return (
    <div className={`title ${isLoading && "severityChangedColor"}`}>
      <div className="heading d-flex">
        {backURL && (
          <Link className="back-btn" to={backURL}>
            <BackIcon />
          </Link>
        )}
        {onClick && (
          <div onClick={onClick} className="back-btn cursor-pointer">
            <BackIcon />
          </div>
        )}
        <span>{formatMessage(headingId)}</span>
      </div>
      {children}
    </div>
  );
};

export default injectIntl(React.memo(PageHeading));
