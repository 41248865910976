import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  checkPermission,
  findPermission,
  permissionErrorCheck,
  renderFormatMsg,
  toastSuccess,
} from "../../shared/funs";
import {
  deleteSections,
  getSections,
  updateSectionOrder,
} from "../../services/toolsAndSettingsPagesApi";

import { AppContext } from "../../context/AppContext";
import Error from "../Error/Error";
import LinkButton from "../Buttons/LinkButton";
import { PAGE_SIZE } from "../../constant";
import PageHeading from "../PageHeading/PageHeading";
import SearchBox from "../SearchBox/SearchBox";
import { confirmAlert } from "react-confirm-alert";
import { get } from "lodash";

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  position: "fixed !important",
});

const getListStyle = (isDraggingOver) => ({
  padding: 8,
  width: 250,
});

const queryAttr = "data-rbd-drag-handle-draggable-id";

const Sections = (props) => {
  const [sections, setSections] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({
    read: true,
    write: false,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setSections((items) =>
      reorder(items, result.source.index, result.destination.index)
    );
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    var _result = result.map((o, index) => {
      return { ...o, sequence: pageIndex * PAGE_SIZE + index + 1 };
    });

    reorderHandler(_result);
    setPageIndex(0);

    return _result;
  };

  const onDragUpdate = (update) => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
  };

  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    setPageReadOnly(
      checkPermission(
        dataArray,
        findPermission("CREATE_EDIT_CONSTANTS").screenId
      )
    );
  }, [dataArray]);

  const getData = useCallback(
    async ({ page = 0, search = "" }) => {
      setSearchValue(search);
      setLoading(true);
      let param = {
        page: page + 1,
        display: PAGE_SIZE,
        formId: props.match.params.id,
      };
      if (search.length > 0) {
        param.search = search;
      }
      getSections(param)
        .then((res) => {
          console.info("res =>", res);

          setLoading(false);
          setSections(res.items);
          setTotalPage(res.totalPage);
        })
        .catch((err) => {
          setError(
            permissionErrorCheck(
              "Something went wrong! please try after some time!"
            )
          );
          setLoading(false);
          setSections([]);
        });
    },
    [props.match.params.id]
  );
  useEffect(() => {
    getData({ page: 0 });
  }, [getData]);

  const removeSections = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteSections({ key: "id", id })
              .then((resp) => {
                toastSuccess(
                  renderFormatMsg(
                    "DELETE_SUCCESS_MSG",
                    "Record deleted successfully"
                  )
                );
                getData({ page: 0 });
                setLoading(false);
                setPageIndex(0);
              })
              .catch((err) => {
                setLoading(false);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const reorderHandler = (data) => {
    var payload = data.map((o) => {
      return {
        sequence: o.sequence,
        itemId: o.sectionId,
      };
    });

    updateSectionOrder({ newSequence: payload })
      .then((resp) => {
        getData({ page: 0, search: searchValue });
      })
      .catch((err) => {});
  };

  const SearchHandler = (e) => {
    setPageIndex(0);
    getData({ page: 0, search: e.target.value });
  };

  const nextClick = () => {
    if (pageIndex < totalPage - 1) {
      getData({ page: pageIndex + 1, search: searchValue });
      setPageIndex(pageIndex + 1);
    }
  };

  const prevClick = () => {
    if (pageIndex !== 0) {
      getData({ page: pageIndex - 1, search: searchValue });
      setPageIndex(pageIndex - 1);
    }
  };

  return (
    <div className="main-content">
      <PageHeading
        headingId={"Sections"}
        isLoading={isLoading}
        backURL="/forms"
      />
      <div className="card">
        {error ? (
          <Error msg={error} />
        ) : (
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} />
              {pageReadOnly.write && (
                <LinkButton
                  classes={"ml-auto"}
                  to={`/sections/Add/${props.match.params.id}`}
                  id="AddSection"
                  defaultText="Add Section"
                />
              )}
            </div>

            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <Droppable droppableId="table">
                {(provided, snapshot) => (
                  <table className="table table-striped">
                    <thead>
                      <th>{renderFormatMsg("Name", "Name")}</th>
                      <th>{renderFormatMsg("Title", "Title")}</th>
                      <th>{renderFormatMsg("Type", "Type")}</th>
                      <th>{renderFormatMsg("Edit", "Edit")}</th>
                      <th>{renderFormatMsg("Delete", "Delete")}</th>
                      <th>{renderFormatMsg("Elements", "Elements")}</th>
                    </thead>
                    <tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {sections.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`item-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td className="rt-th text-left">{item.name}</td>
                              <td className="rt-th text-left">{item.title}</td>
                              <td className="rt-th text-left">{item.type}</td>

                              <td
                                className="rt-th text-left"
                                onClick={() =>
                                  props.history.push(
                                    `/sections/Edit/${props.match.params.id}/${item.sectionId}`
                                  )
                                }
                              >
                                <div className="rt-td">
                                  <i className="edit-icon"></i>
                                  {renderFormatMsg("Edit", "Edit")}
                                </div>
                              </td>

                              <td
                                className="rt-th text-left"
                                onClick={() => removeSections(item.sectionId)}
                              >
                                <div className="rt-td">
                                  <i className="delete-icon"></i>
                                  {renderFormatMsg("Delete", "Delete")}
                                </div>
                              </td>

                              <td
                                className="rt-th text-left"
                                onClick={() =>
                                  props.history.push(
                                    `/elements/${props.match.params.id}/${item.sectionId}`
                                  )
                                }
                              >
                                <div className="rt-td">
                                  {renderFormatMsg("Details", "Details")} ...
                                </div>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>

            <div className="card-footer bg-transparent p-0">
              <div className="row m-1 pagination-row-custom">
                <div
                  onClick={() => prevClick()}
                  className={
                    "col-lg-3 align-items-center btn " +
                    (pageIndex === 0 ? "read-only-custom" : "btn-light-custom")
                  }
                >
                  <span>{renderFormatMsg("previous", "Previous")}</span>
                </div>
                <div className="form-row col-lg-6 text-center">
                  <div className="col-lg-5 pt-2" style={{ textAlign: "end" }}>
                    <span>Page</span>
                  </div>

                  <div className="col-lg-2">
                    <input
                      type="number"
                      className="form-control"
                      value={pageIndex < totalPage ? pageIndex + 1 : totalPage}
                    />
                  </div>

                  <div className="col-lg-5 pt-2" style={{ textAlign: "start" }}>
                    <span>of {totalPage}</span>
                  </div>
                </div>
                <div
                  onClick={() => nextClick()}
                  className={
                    "col-lg-3 align-items-center btn " +
                    (pageIndex + 1 === totalPage
                      ? "read-only-custom"
                      : "btn-light-custom")
                  }
                >
                  <span>{renderFormatMsg("next", "Next")}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default React.memo(Sections);
