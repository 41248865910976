import { Field, Form } from 'react-final-form';
import React, { useContext, useEffect, useState } from 'react'
import { addPromotionType, getClientData, getDialer, getForms, getPromotionTypeById, getReportDefinition, updatePromotionType } from '../../services/toolsAndSettingsPagesApi';
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';
import { find, get } from "lodash";
import { generateClassificationOptions, generateDialerOptions, generateFinalReportOption, generateFormsOption, generateOptionsForTarget, generatePushLogicOptions, generateReportLogicOptions } from './util/generateOptions';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { AppContext } from '../../context/AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Error from '../../components/Error/Error';
import FieldErrorMessage from '../../components/FieldErrorMessage';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import Select from 'react-select'
import SelectField from '../../components/Fields/SelectField';
import { checkConfirmationRecipient } from './util/checkConfirmationRecipient';
import moment from 'moment';
import { promoFormValidation } from './util/promoFormValidation';
import queryString from 'query-string';

const HandlePromotionType = () => {
  const { userDetails } = useContext(AppContext)
  const location = useLocation()
  const history = useHistory()
  const params = useParams();
  const [variable, setVariable] = useState(null)
  const [formValue, setFormValue] = useState(null)
  const [error, setError] = useState(false)
  const [clients, setClients] = useState([])
  const [reports, setReports] = useState([])
  const [forms, setForms] = useState([])
  const [dialer, setDialer] = useState([])
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

  let dataArray = get(userDetails, "accesses")
  var heading = "EditPromotionType.Heading"

  if (location.pathname.includes("Add")) {
    heading = "AddPromotionType"
  }

  useEffect(() => {
    var values = {};
    if (location.pathname.includes("Edit")) {
      const constants = get(variable, "constants.constant", [])
      const autoPushTime = get(variable, "autoPushTime", "") ? moment(get(variable, "autoPushTime", "00:00"), "h:mm:ss").format("HH:mm") : null;
      const autoReportTime = get(variable, "autoReportTime", "") ? moment(get(variable, "autoReportTime", "00:00"), "h:mm:ss").format("HH:mm") : null;
      values = {
        clientName: get(variable, "clientName", ""),
        clientId: get(variable, "clientId", ""),
        name: get(variable, "name", ""),
        description: get(variable, "description", ""),
        type: get(variable, "type", ""),
        bucket: get(variable, "bucket", ""),
        pushLogic: get(variable, "pushLogic", ""),
        reportLogic: get(variable, "reportLogic", ""),
        fromDay: get(variable, "fromDay", ""),
        toDay: get(variable, "toDay", ""),
        target: get(variable, "target", ""),
        dialerId: get(variable, "dialerId", ""),
        prefix: get(variable, "prefix", ""),
        receiver: get(variable, "receiver", ""),
        inactivityTimeForReport: get(variable, "inactivityTimeForReport", ""),
        customerCampaignId: get(variable, "customerCampaignId", ""),
        maxCallCount: get(variable, "maxCallCount", ""),
        formID: get(variable, "formID", ""),
        class: get(variable, "class", ""),
        scriptTitle: get(variable, "scriptTitle", ""),
        shortCallLimit: get(variable, "shortCallLimit"),
        script: get(variable, "script", ""),
        preProcessing: get(variable, "preProcessing", ""),
        confirmationRecipients: get(variable, "confirmationRecipients", ""),
        clearBucketBeforePush: get(variable, "clearBucketBeforePush", ""),
        constantName: get(variable, "constantName", ""),
        finalReportId: get(variable, "finalReportId", ""),
        autoPushTime,
        autoReportTime,
        constants,
        constantDescription: get(variable, "constantDescription", ""),
        constantType: get(variable, "constantType", ""),
      }
      setPageReadOnly(checkPermission(dataArray, findPermission("EDIT_PROMOTION").screenId))
    } else {
      values = {
        clientName: "",
        name: "",
        description: "",
        type: ""
      }
      setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_PROMOTION_TYPES").screenId))
    }
    setFormValue(values)
  }, [dataArray, variable, location.pathname])

  useEffect(() => {
    let param = {
      "page": 0 + 1,
      "display": 999999,
    }
    getReportDefinition(param).then(res => {
      setReports(res.report)
    }).catch(() => {
      setReports([])
    })
  }, [])

  //For generate Form
  useEffect(() => {
    let param = {
      "page": 0 + 1,
      "display": 999999,
    }
    getForms(param).then(res => {
      setForms(res.items)
    }).catch(() => {
      setForms([])
    })
  }, [])

  // For generate Dialer
  useEffect(() => {
    let param = {
      "page": 0 + 1,
      "display": 999999,
    }
    getDialer(param).then(res => {
      setDialer(res)
    }).catch(() => {
      setDialer([])
    })
  }, [])

  const getClients = (search = "") => {
    getClientData({
      page: 1,
      display: 100,
      search: search
    }).then(clients => {
      var tempClient = []
      clients.clients.forEach(client => {
        tempClient.push({
          value: client.clientName,
          label: client.clientName,
          clientId: client.clientId
        })
      })
      setClients(tempClient)
    }).catch(() => {
      toastError('Error in fetching clients')
    })

  }

  useEffect(() => {
    if (location.pathname.includes("Edit")) {
      getPromotionTypeById({
        promotionTypeId: params.id
      }).then(res => {
        var allClient = []
        getClientData({
          page: 0,
          display: 100,
        }).then(clients => {

          clients.clients.forEach(client => {
            allClient.push({
              value: client.clientName,
              label: client.clientName,
              clientId: client.clientId
            })
          })

          if (!find(allClient, { clientId: res.clientId })) {
            allClient.push({
              value: res.clientName,
              label: res.clientName,
              clientId: res.clientId
            })
          }
          setClients(allClient)

        }).catch(err => {
          allClient.push({
            value: res.clientName,
            label: res.clientName,
            clientId: res.clientId
          })
          setClients(allClient)
        })
        setVariable(res)
      }).catch(err => {
        setVariable(null)
        setError(permissionErrorCheck(err))
      })
    } else {
      getClients()
    }
  }, [location.pathname, params.id])

  const handleEditForm =  (data) => {
    var obj = {
      "promotionTypeId": params.id,
      "clientId": data.clientId,
      "name": data.name,
      "description": data.description,
      "bucket": data.bucket,
      "pushLogic": data.pushLogic,
      "fromDay": data.fromDay,
      "toDay": data.toDay,
      "target": data.target,
      "dialerId": data.dialerId,
      "prefix": data.prefix,
      "receiver": data.receiver,
      "inactivityTimeForReport": data.inactivityTimeForReport,
      "customerCampaignId": data.customerCampaignId,
      "maxCallCount": data.maxCallCount,
      "formID": data.formID,
      "class": data.class,
      "scriptTitle": data.scriptTitle,
      "shortCallLimit": data.shortCallLimit,
      "script": data.script,
      "autoPushTime": data.autoPushTime ? moment(data.autoPushTime, "h:mm").format("HH:mm:ss") : null,
      "autoReportTime": data.autoReportTime ? moment(data.autoReportTime, "h:mm").format("HH:mm:ss") : null,
      "finalReportId": data.finalReportId,
      "preProcessing": data.preProcessing,
      "clearBucketBeforePush": data.clearBucketBeforePush,
        
    }
    if(checkConfirmationRecipient(data.confirmationRecipients)){
      obj.confirmationRecipients = data.confirmationRecipients
    }
    else return


    
    if (data.constants && data.constants.length > 0) {
      var _constants = [...data.constants];
      _constants.forEach(o => {
        if (o.type === "list") {
          if (o.value && typeof o.value === "object") {
            var temp = []
            o.value.forEach(o => { temp.push(o && o.label ? o.label : o) })
            o.value = [].concat(temp)
          }
        } else {
          o.value = [].concat(o.value)
        }
      })
      obj.constants = {
        constant: _constants
      }

    }

    updatePromotionType(obj).then(() => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      history.push("/promotion-type")
    }).catch(() => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated failed"))
    })
  }

  const handleAddForm = (data) => {
    addPromotionType(data).then(() => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully"))
      history.push("/promotion-type")
    }).catch(() => {
      toastSuccess(renderFormatMsg("ADD_FAILED_MSG", "Record added failed"))
    })
  }
  const parsed = queryString.parse(location.search);

  return (
    <div className="main-content">
  
      <PageHeading headingId={heading} backURL={`/promotion-type?search=${parsed.search}`} />
   
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form
          onSubmit={location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={formValue} validate={promoFormValidation}>
          {({ handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="clientId">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("ClientName", "Client Name")} </label>
                            <Select
                              isDisabled={!pageReadOnly.write}
                              value={clients.filter(option => option.clientId === input.value)}
                              onChange={(value) => input.onChange(value.clientId)}
                              onInputChange={(inputValue) => { getClients(inputValue) }}
                              options={clients}
                            />

                            {meta.error && meta.touched && (<span>{meta.error}</span>)}
                          </div>
                        )}
                      </Field>
                    </div>
                  
                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="Name"
                      defaultLabel="Script Title"
                      placeholder={"Prefix"}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">
                 
                    <InputField
                      colClass="col-lg-6"
                      name={"description"}
                      type="text"
                      id="Description"
                      defaultLabel="Description"
                      placeholder={"Description"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
               

                    <InputField
                      colClass="col-lg-6"
                      name={"prefix"}
                      type="text"
                      id="Prefix"
                      defaultLabel="Prefix"
                      placeholder={"Prefix"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />

               
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                  
                        <InputField
                          colClass="col-lg-6"
                          name={"fromDay"}
                          type="text"
                          id="FromDay"
                          defaultLabel="FromDay"
                          placeholder={"From"}
                          readOnly={!pageReadOnly.write}
                          className={`${!pageReadOnly.write && `read-only`} form-control`}
                        />
                        
                        <InputField
                          colClass="col-lg-6"
                          name={"toDay"}
                          type="number"
                          id="ToDay"
                          defaultLabel="To Day"
                          placeholder={"To Day"}
                          readOnly={values.readOnly}
                          className={`${values.readOnly && `read-only`} form-control`}
                        />
                      </div>
                    </div>

                    <SelectField
                      colClass="col-lg-6"
                      name="target"
                      options={generateOptionsForTarget()}
                      id="Target"
                      defaultLabel={"target"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}

                    />
                    
                  </div>
                  <div className="row">
                    

                    <SelectField
                      colClass="col-lg-6"
                      name="dialerId"
                      options={generateDialerOptions(dialer)}
                      id="Dialer"
                      defaultLabel={"Dialer"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"bucket"}
                      type="text"
                      id="Bucket"
                      defaultLabel="Bucket"
                      placeholder={"Bucket"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">

                    <SelectField
                      colClass="col-lg-6"
                      name="pushLogic"
                      options={generatePushLogicOptions()}
                      id="HowToPushToTheDialer"
                      defaultLabel={"How to push to the dialer"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}

                    />

                    <div className="col-lg-6">
                      <Field name="clearBucketBeforePush" type="checkbox">
                        {({ input, meta }) => (
                          <div style={{ marginTop: "25px" }}>
                            <div className="checkbox d-flex">
                              <input {...input} id="clearBucketBeforePush" />
                              <label/>
                              <label for="deleteBucketBeforePushing" style={{ width: "auto !important" }}> {renderFormatMsg("deleteBucketBeforePushing", "Delete Bucket Before Pushing")} </label>
                            </div>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>

                  </div>

                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"customerCampaignId"}
                      type="text"
                      id="CustomerCampaignId"
                      defaultLabel="Customer Campaign Id"
                      placeholder={"Customer Campaign Id"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                  
                    <InputField
                      colClass="col-lg-6"
                      name={"maxCallCount"}
                      type="text"
                      id="maxCallCount"
                      defaultLabel="Max. Call Count"
                      placeholder={"Max. Call Count"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                  </div>


                  <div className='row'>
                    <SelectField
                      colClass="col-lg-6"
                      name="formID"
                      options={generateFormsOption(forms)}
                      id="Form"
                      defaultLabel={"Form"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                    <SelectField
                      colClass="col-lg-6"
                      name="class"
                      options={generateClassificationOptions()}
                      id="Classification"
                      defaultLabel={"Classification"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                  </div>


                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"scriptTitle"}
                      type="text"
                      id="ScriptTitle"
                      defaultLabel="Script Title"
                      placeholder={"Script Title"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"shortCallLimit"}
                      id="ShortCallLimit"
                      defaultLabel="Short Call Limit [sec]"
                      placeholder={"Short Call Limit [sec]"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Field name="script">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("Script", "Script")} </label>
                            <CKEditor
                              disabled={!pageReadOnly.write}
                              data={input.value}
                              editor={ClassicEditor}
                              config={{
                                removePlugins: ['Link', 'Indent', 'Outdent', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                input.onChange(data)
                              }}
                              onBlur={(event, editor) => {
                                const data = editor.getData();
                                input.onBlur(data)
                              }}
                            />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>


                  <div className="row">

                    <SelectField
                      colClass="col-lg-6"
                      name="reportLogic"
                      options={generateReportLogicOptions()}
                      id="reportLogic"
                      defaultLabel={"How to generate the final report"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}

                    />

                    <SelectField
                      colClass="col-lg-6"
                      name="finalReportId"
                      options={generateFinalReportOption(reports)}
                      id="finalReport"
                      defaultLabel={"Final Report"}
                      disabled={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`} form-control`}

                    />

                  </div>


                  <div className="row">
                    <InputField
                      colClass="col-lg-6"
                      name={"autoReportTime"}
                      type="text"
                      id="autoReportTime"
                      defaultLabel="Time of autom. Report"
                      placeholder={"HH:MM"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"inactivityTimeForReport"}
                      type="number"
                      id="inactivityTimeForReport"
                      defaultLabel="Inactivity time until final report should be sent [Min.]"
                      placeholder="Inactivity time until final report should be sent [Min.]"
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />

                   

                    <InputField
                      colClass="col-lg-6"
                      name={"autoPushTime"}
                      type="text"
                      id="autoPushTime"
                      defaultLabel="Time of autom. Push to Dailer"
                      placeholder={"HH:MM"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"receiver"}
                      type="text"
                      id="Receiver"
                      defaultLabel="Receiver Name"
                      placeholder={"Receiver Name"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">
                    
                    <InputField
                      colClass="col-lg-6"
                      name={"preProcessing"}
                      type="text"
                      id="PreProcessing"
                      defaultLabel="Pre Processing"
                      placeholder={"Pre Processing"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                     <InputField
                      colClass="col-lg-6"
                      name={"confirmationRecipients"}
                      type="text"
                      id="ConfirmationRecipients"
                      defaultLabel="Confirmation Recipients"
                      placeholder="Confirmation Recipients"
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                    
                  </div>

                </div>
        

                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div >
  )
}

export default HandlePromotionType
