import { Field, Form } from 'react-final-form';
import React, { useContext, useEffect, useState } from 'react'
import { addPromotionGroup, getPromotionGroupData, updatePromotionGroup } from '../../services/toolsAndSettingsPagesApi';
import { permissionErrorCheck, renderFormatMsg, toastSuccess } from '../../shared/funs';

import { AppContext } from '../../context/AppContext';
import Error from '../../components/Error/Error';
import FieldErrorMessage from '../../components/FieldErrorMessage';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import SelectField from '../../components/Fields/SelectField';
import { get } from "lodash";

const HandlePromotionGroup = (props) => {
  const [promotionGroup, setPromotionGroup] = useState(null)
  const [formValue, setFormValue] = useState(null)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly,] = useState({ read: true, write: true })

  let dataArray = get(userDetails, "accesses")
  var heading = "PromotionGroup.EditPromotionGroup";

  if (props.location.pathname.includes("Add")) {
    heading = "PromotionGroup.AddPromotionGroup";
  }

  useEffect(() => {

    var values = {};
    if (props.location.pathname.includes("Edit")) {
      values = {
        name: get(promotionGroup, "name", ""),
        isActive: get(promotionGroup, "isActive", ""),
        type: get(promotionGroup, "type", ""),
      }
      // setPageReadOnly(checkPermission(dataArray, findPermission("EDIT_PROMOTION").screenId))
    } else {
      values = {
        name: "",
        isActive: "",
        type: ""
      }
      // setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_PROMOTION_TYPES").screenId))
    }
    setFormValue(values)
  }, [dataArray, promotionGroup, props.location.pathname])



  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getPromotionGroupData({
        search: props.match.params.id
      }).then(res => {
        console.info('res => ', res);
        setPromotionGroup(res.promotionGroup && res.promotionGroup[0] ? res.promotionGroup[0] : null)
      }).catch(err => {
        setPromotionGroup(null)
        setError(permissionErrorCheck(err))
      })
    }
  }, [
    props.location.pathname,
    props.match.params.id
  ])

  const handleEditForm = async (data) => {
    var obj = {
      name: data.name,
      promotionGroupId: props.match.params.id,
      isActive: data.isActive,
      type: data.type
    }

    updatePromotionGroup(obj).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/promotion-group")
    }).catch(err => {
      toastSuccess(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated failed"))
    })
  }


  const handleAddForm = async (data) => {
    addPromotionGroup(data).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/promotion-group")
    }).catch(err => {
      toastSuccess(renderFormatMsg("ADD_FAILED_MSG", "Record added failed"))
    })
  }

  const validation = (data) => {
    let errors = {}
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    return errors
  }

  const generateOptions = () => {
    var options = [<option value={""}>{"Select Type"}</option>]

    var optionsArray = ["MCC HoReCa #1", "MCC HoReCa #2", "MCC Promo", "Diagram 4", "Diagram 5", "Diagram 6"]
    optionsArray.map((option, index) =>
      options.push(<option value={option}>{option}</option>)
    )
    return options;
  }
  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/promotion-group"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading headingId={heading} backURL={`/promotion-group`} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form
          onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={formValue} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values, errors }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("Name", "Name")} </lable>
                            <input {...input} type="text" readOnly={!pageReadOnly.write} placeholder="Name" className={`${!pageReadOnly.write && `read-only`} form-control`} />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="Name"
                      defaultLabel="Name"
                      placeholder={"Name"}
                      readOnly={!pageReadOnly.write}
                      className={`${!pageReadOnly.write && `read-only`} form-control`}
                    />
                  </div>
                  <div className="row">
                    {/* <InputField
                      colClass="col-lg-6"
                      name={"type"}
                      type="text"
                      id="Type"
                      defaultLabel="Type"
                    /> */}
                    <SelectField
                      colClass="col-lg-6"
                      name="type"
                      options={generateOptions()}
                      id="Type"
                    />
                    {/* <div className="col-lg-6">
                      <Field name="type">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("Type", "Type")} </lable>
                            <select  {...input} defaultValue={input.value} className="form-control">
                              <option value={``}>Select Type</option>
                              <option value={`MCC HoReCa #1`}>MCC HoReCa #1</option>
                              <option value={`MCC HoReCa #2`}>MCC HoReCa #2</option>
                              <option value={`MCC Promo`}>MCC Promo</option>
                              <option value={`Diagram 4`}>Diagram 4</option>
                              <option value={`Diagram 5`}>Diagram 5</option>
                              <option value={`Diagram 6`}>Diagram 6</option>
                            </select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                  </div>


                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="isActive">
                        {({ input, meta }) => (
                          <div className="form-group">

                            <div class="checkbox">

                              <lable for="checkbox1" style={{ width: "auto !important" }}> {renderFormatMsg("Active", "Active")} </lable>

                              <input {...input} type="checkbox" checked={input.value} readOnly={!pageReadOnly.write} className={`${!pageReadOnly.write && `read-only`}`} />
                              
                              <label ></label>
                            </div>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>



                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandlePromotionGroup)
