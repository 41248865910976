import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastSuccess, } from '../../shared/funs';
import { debounce, get } from 'lodash';
import { deleteElements, getElements, updateElementOrder } from '../../services/toolsAndSettingsPagesApi';
import { useHistory, useLocation } from 'react-router-dom';

import { AppContext } from '../../context/AppContext';
import Error from "../../components/Error/Error";
import LinkButton from '../../components/Buttons/LinkButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import SearchBox from '../../components/SearchBox/SearchBox';
import { confirmAlert } from 'react-confirm-alert';
import queryString from 'query-string';

const PAGE_SIZE = 50;
const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  position: "fixed !important"
});

const getListStyle = isDraggingOver => ({
  padding: 8,
  width: 250
});

const queryAttr = "data-rbd-drag-handle-draggable-id";



const Elements = (props) => {
  const location = useLocation()
  const history = useHistory()
  const parsed = queryString.parse(location.search);
  const [elements, setElements] = useState([])
  const [searchValue, setSearchValue] = useState(parsed.search || "")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (Object.keys(parsed).length > 0) setSearchValue(parsed.search);
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchValue) {
      params.append("search", searchValue);
    } else {
      params.delete("search");
    }
    history.push({ search: params.toString() });
  }, [history, searchValue]);
  const onDragEnd = result => {

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setElements(items => reorder(items, result.source.index, result.destination.index));
    // setElements(items => reorder(items, (pageIndex * 10) + result.source.index, (pageIndex * 10) + result.destination.index));
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {


    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    var _result = result.map((o, index) => { return { ...o, sequence: ((pageIndex * PAGE_SIZE) + index) + 1 } })

    reorderHandler(_result)
    setPageIndex(0)
    return _result;
  };

  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;


    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
  };

  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_CONSTANTS").screenId))
  }, [dataArray])

 

  const getData = useCallback(async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      page: page + 1,
      display: PAGE_SIZE,
      sectionId: props.match.params.sectionId,
    }
    if (search.length > 0) {
      param.search = search
    }
    getElements(param).then(res => {
      setLoading(false)

      setElements(res.items)
      setTotalPage(res.totalPage)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setElements([])
    })
  },[props.match.params.sectionId])
  
  useEffect(() => {
    const params = {
      page: pageIndex,
      search: searchValue
    }
    getData(params)
  }, [getData, pageIndex, searchValue])
  
  const removeElements = (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteElements({ key: "id", id }).then(resp => {
              toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted successfully"))
              getData({ page: 0 })
              setLoading(false)
              setPageIndex(0)
            }).catch(err => {
              setLoading(false)
            })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const reorderHandler = (data) => {

    var payload = data.map(o => {
      return {
        sequence: o.sequence,
        itemId: o.elementId
      }
    })

    updateElementOrder({ newSequence: payload }).then(resp => {
      getData({ page: 0, search: searchValue })
    }).catch(err => { })
  }
  const fetchData = (state, instance) => {
    getData({ page: state.page, search: state.search });
    setPageIndex(state.page);
  };
  const delayedQuery = debounce((state) => fetchData(state), 1000);

  const searchHandler = useCallback((e) => {
    setPageIndex(0);
    delayedQuery({ page: 0, display: PAGE_SIZE, search: e.target.value });
  }, [
    delayedQuery,
    
  ]);
  const nextClick = () => {
    if (pageIndex < totalPage - 1) {
      getData({ page: pageIndex + 1, search: searchValue })
      setPageIndex(pageIndex + 1)
    }
  }

  const prevClick = () => {
    if (pageIndex !== 0) {
      getData({ page: pageIndex - 1, search: searchValue })
      setPageIndex(pageIndex - 1)
    }
  }

  return (
    <div className="main-content">

      <PageHeading headingId={"Elements"} isLoading={isLoading} backURL={`/sections/${props.match.params.id}`} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={searchHandler}  value={parsed.search} />
              {pageReadOnly.write && <LinkButton classes={"ml-auto"} to={`/elements/${props.match.params.id}/${props.match.params.sectionId}/Add?search=${searchValue}`} id="AddElement" defaultText="Add Element" />}
            </div>

            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <Droppable droppableId="table">
                {(provided, snapshot) => (
                  <table className="table table-striped">
                    <thead>
                      <th>{renderFormatMsg("Name", "Name")}</th>
                      <th>{renderFormatMsg("Type", "Type")}</th>
                      <th>{renderFormatMsg("Edit", "Edit")}</th>
                      <th>{renderFormatMsg("Delete", "Delete")}</th>
                    </thead>

                    <tbody {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>

                      {elements.map((item, index) => (
                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                          {(provided, snapshot) => (
                            <tr ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td className="rt-th text-left">{item.name}</td>
                              <td className="rt-th text-left">{item.elementType}</td>

                              <td className="rt-th text-left" onClick={() => props.history.push(`/elements/Edit/${props.match.params.id}/${props.match.params.sectionId}/${item.elementId}?search=${searchValue}`)}>
                                <div className="rt-td">
                                  <i className="edit-icon"></i>
                                  {renderFormatMsg("Edit", "Edit")}
                                </div>
                              </td>

                              <td className="rt-th text-left" onClick={() => removeElements(item.elementId)}><div className="rt-td"><i className="delete-icon"></i>{renderFormatMsg("Delete", "Delete")}</div></td>

                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>

            <div class="card-footer bg-transparent p-0">
              <div className='row m-1 pagination-row-custom'>
                <div
                  onClick={() => prevClick()}
                  className={"col-lg-3 align-items-center btn " + (pageIndex === 0 ? "read-only-custom" : "btn-light-custom")}
                >
                  <span>{renderFormatMsg("previous", "Previous")}</span>
                </div>
                <div className='form-row col-lg-6 text-center'>
                  <div className='col-lg-5 pt-2' style={{ textAlign: "end" }}>
                    <span>Page</span>
                  </div>

                  <div className='col-lg-2'>
                    <input type='number' className='form-control' value={pageIndex < totalPage ? pageIndex + 1 : totalPage} />
                  </div>

                  <div className="col-lg-5 pt-2" style={{ textAlign: "start" }}>
                    <span>of {totalPage}</span>
                  </div>
                </div>
                <div
                  onClick={() => nextClick()}
                  className={"col-lg-3 align-items-center btn " + (pageIndex + 1 === totalPage ? "read-only-custom" : "btn-light-custom")}
                >
                  <span>{renderFormatMsg("next", "Next")}</span>
                </div>
              </div>

            </div>

           

          </>
        }
      </div>
    </div >
  )
}
export default React.memo(Elements)
