const ru = {
  translations: {
    "customer_data": "Данные о клиенте",
    "company_name": "Название Компании",
    "personnal_cabinet": "Личный кабинет",
    "current_customer_name": "Имя клиента",
    "phone_of_the_customer": "Номер телефона клиента",
    "wrong_number": "Номер не принадлежит клиенту",
    "received_information": "Внесите информацию",
    "type_of_customer": "Цель закупок",
    "company": "Для бизнеса",
    "private_person": "Для личных нужд",
    "agreement_for_communication": "Согласие на дальнейшие рассылки",
    "yes": "Да",
    "no": "Нет",
    "email": "E-mail",
    "do_not_call_me_ever_again": "Отказ от дальнейших комуникаций",
    "not_interested": "Не заинтересован",
    "interested": "Заинтересован",
    "call_back_later": "Отказ от разговора / Перезвонить позже",
    "call_back_in_1_hour": "Перезвонить через 1 час",
    "call_back_in_2_hour": "Перезвонить через 2 часа",
    "call_back_in_4_hour": "Перезвонить через 4 часа",
    "call_back_in_6_hour": "Перезвонить через 6 часов",
    "call_back_tomorrow": "Перезвонить завтра",
    "information_about_the_promotion": "Информация об акции",
    "promotion_name": "Название акции",
    "discount": "Скидка",
    "plan_turnover": "План оборота",
    "fact_turnover": "Оборот фактов",
    "fulfillment": "Исполнение",
    "target_achievement": "Достижение цели",
    "missing_turnover": "Отсутствует оборот",
    "complains": "Жалоба",
    "feedback": "feedback",
    "can_we_call_to_investigate_reasons": "Если понадобится, можем ли мы перезвонить для уточнения?",
    "save_msg": "Данные сохранены, можно закрыть окно.",
    "error_msg": "что-то пошло не так, пожалуйста, еще раз",
    "schedule": "График",
    "from": "Из",
    "to": "Чтобы",
    "questions": "Вопросы",
    "AgreementForSendSMS": "Соглашение на отправку СМС",
    "SMSPhone": "SMS-телефон",
    "bonusAmount": "Бонусная сумма",
    "bonusExpiration": "Срок действия бонуса",
    "bonusRegistration": "Бонусная регистрация",
    "credit": "Кредит",
    "products": "Продукты",
    "name": "Имя",
    "category": "Категория",
    "action": "Действие",
    "loanTerm": "Срок кредитования, в месяцах",
    "requestedAmount": "Желаемая сумма кредита",
    "region": "Регион заявителя",
    "DOB": "DOB",
  }
}
export default ru;