import { toastError } from "../../../shared/funs";

export const checkConfirmationRecipient = (
  confirmationRecipients: string,
  message = "Invalid email address(es) found in the confirmation recipients:",
  showToastError = true
) => {
  // split confirmation recipient and check if it matches with the email pattern
  if (confirmationRecipients) {
    // trim white space in between
    const trimmedEmails = confirmationRecipients.replace(/\s/g, "");
    const emails = trimmedEmails.split(",");
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const invalidEmails: string[] = [];
    emails.forEach((email) => {
      if (!emailPattern.test(email)) {
        invalidEmails.push(email);
      }
    });
    if (invalidEmails.length > 0) {
      if (showToastError) toastError(message);
      return false;
    }
    return confirmationRecipients;
  }
};
