import { Dropdown, DropdownButton } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react'
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';
import { getPromotionGroupData, resendPromotionGroupReport, updatePromotionGroup } from '../../services/toolsAndSettingsPagesApi';

import { AppContext } from "../../context/AppContext";
import Error from "../../components/Error/Error"
import { Link } from "react-router-dom"
import LinkButton from '../../components/Buttons/LinkButton';
import { PAGE_SIZE } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { get } from "lodash"

const PromotionGroup = () => {
  const { userDetails } = useContext(AppContext)
  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [error, setError] = useState(false)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  const [isLoading, setLoading] = useState(false)

  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_PROMOTION_GROUP").screenId))
  }, [dataArray])

  const getData = (page = 0, search = "") => {
    setLoading(true)
    setSearchValue(search)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
      "search": search
    }
    getPromotionGroupData(param).then(res => {
      setLoading(false)
      setData(res.promotionGroup.length > 0 ? res.promotionGroup : [])
      setTotalPage(res.totalPage)
    }).catch(err => {
      setLoading(false)
      setError(permissionErrorCheck(err))
    })
  }

  const onChangeActiveState = (e, obj) => {
    var object = {
      name: obj.name,
      promotionGroupId: obj.promotionGroupId,
      isActive: e.target.checked
    }

    updatePromotionGroup(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    }).catch(err => {
      console.info('err => ', err);
      toastSuccess(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated failed"))
    })
  }

  const columns = [{
    Header: <div className="text-left">{renderFormatMsg("rolesTable.Name", "Name")}</div>,
    accessor: 'name',
    resizable: false,
    width: 350,
    maxWidth: 450,
    style: { justifyContent: "flex-start" }
  }, {
    Header: <div className="text-left">{renderFormatMsg("Type", "Type")}</div>,
    accessor: 'type',
    resizable: false,
    width: 150,
    maxWidth: 250,
    style: { justifyContent: "flex-start" }
  },
  {
    Header: <div className="text-left">{renderFormatMsg("Promotions", "Promotions")}</div>,
    resizable: false,
    width: 150,
    maxWidth: 250,
    Cell: props => <Link to={`/promotion-group/linked-promotion/reports/${props.original.promotionGroupId}`} > {renderFormatMsg("Promotions", "Promotions")}</Link>
  }, {
    Header: renderFormatMsg("Active", "Active"),
    // accessor: 'Edit',
    resizable: false,
    maxWidth: 100,
    Cell: props => <div class="checkbox"><input type="checkbox" defaultChecked={props.original.isActive} name="active" onClick={(e) => onChangeActiveState(e, props.original)} /><label for="checkbox1"></label></div>
  },
  {
    Header: <div className="text-left">{renderFormatMsg("LinkReport", "Link Report")}</div>,
    maxWidth: 150,
    resizable: false,
    style: { justifyContent: "flex-start" },
    Cell: props => <Link to={`/promotion-group/reports/${props.original.promotionGroupId}`} > {renderFormatMsg("LinkReport", "Link Report")}</Link>
  },
  // {
  //   Header: renderFormatMsg("Edit", "Edit"),
  //   accessor: 'Edit',
  //   resizable: false,
  //   maxWidth: 100,
  //   Cell: props => <Link to={`/promotion-group/Edit/${props.original.promotionGroupId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
  // },
  {
    Header: "Reports",
    resizable: false,
    className: "actionColumn",
    style: { overflow: "inherit !important" },
    width: 130,
    Cell: props => <DropdownButton
      // show={promotionOpenId === props.original.promotionId}
      key={'left'}
      id={`dropdown-button-drop-${'left'}`}
      variant="secondary"
      title={`...`}
    >
      {props.original.reports && props.original.reports.length > 0 ? <div>
        <span style={{ textDecoration: "underline", fontWeight: 600, padding: ".25rem 1.5rem" }}>Reports</span>
        <div style={{ overflowY: "auto", marginTop: 5 }}>
          {
            props.original.reports.map((report, index) =>
              <Dropdown.Item eventKey="4" onClick={() => { resendReport(report.reportId, props.original.promotionGroupId) }} >{report.reportName}</Dropdown.Item>
            )
          }
        </div>
      </div> : <div><span style={{ textDecoration: "underline", fontWeight: 600, padding: ".25rem 1.5rem" }}>No Reports</span></div>}

    </DropdownButton >
  },]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      resizable: false,
      maxWidth: 100,
      Cell: props => <Link to={`/promotion-group/Edit/${props.original.promotionGroupId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }

  const fetchData = (state, instance) => {
    //getting change page number from state
    getData(state.page, searchValue)
    setPageIndex(state.page)
  }

  const resendReport = (reportId, promotionGroupId) => {
    setLoading(true)
    resendPromotionGroupReport({ reportId, promotionGroupId }).then(res => {
      setLoading(false)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    }).catch(err => {
      setLoading(false)
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }



  const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }


  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("PromotionGroup", "Promotion Group")}
      </div> */}
      <PageHeading headingId={"PromotionGroup"} isLoading={isLoading} />

      <div className="card">

        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} />

              {pageReadOnly.write && <LinkButton classes={"ml-auto"} to="/promotion-group/Add" id="PromotionGroup.AddPromotionGroup" defaultText="Add Promotion Group" />}

            </div>
            <ReactTable data={data} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div>
  )
}

export default React.memo(PromotionGroup)
