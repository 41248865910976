import { Dropdown, DropdownButton, ProgressBar } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  checkPermission,
  findPermission,
  permissionErrorCheck,
  renderFormatMsg,
  toastError,
  toastSuccess,
} from "../../shared/funs";
import {
  createPromotionType,
  deletePromotionType,
  getPromotionType,
} from "../../services/toolsAndSettingsPagesApi";

import { AppContext } from "../../context/AppContext";
import Error from "../../components/Error/Error";
import LinkButton from "../../components/Buttons/LinkButton";
import { PAGE_SIZE } from "../../constant";
import PageHeading from "../../components/PageHeading/PageHeading";
import ReactTable from "../../components/ReactTable/ReactTable";
import SearchBox from "../../components/SearchBox/SearchBox";
import { confirmAlert } from "react-confirm-alert"; // Import
import { debounce } from "@material-ui/core";
import { get } from "lodash";
import queryString from "query-string";

const PromotionType = () => {
  const location = useLocation();
  const history = useHistory();
  const [uploadComplete, setUploadComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [promotionType, setPromotionType] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({
    read: true,
    write: false,
  });
  
  const parsed = queryString.parse(location.search);
  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    setPageReadOnly(
      checkPermission(
        dataArray,
        findPermission("CREATE_EDIT_PROMOTION_TYPES").screenId
      )
    );
  }, [dataArray]);
  
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (Object.keys(parsed).length > 0) setSearchValue(parsed.search);
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchValue) {
      params.append("search", searchValue);
    } else {
      params.delete("search");
    }
    history.push({ search: params.toString() });
  }, [history, searchValue]);

  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search);
    setLoading(true);
    let param = {
      page: page + 1,
      display: PAGE_SIZE,
      search: search,
    };
    getPromotionType(param)
      .then((res) => {
        setLoading(false);
        setPromotionType(res.promotiontype);
        setTotalPage(res.totalPage);
      })
      .catch((err) => {
        setError(permissionErrorCheck(err));
        setLoading(false);
        setPromotionType([]);
      });
  };

  const removePromotionType = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deletePromotionType("promotionTypeReportId", id)
              .then(() => {
                var _promotionType = Object.assign([], promotionType);
                _promotionType = _promotionType.filter(
                  (data) => data.promotionTypeId !== id
                );
                setPromotionType(_promotionType);
                toastSuccess(
                  renderFormatMsg(
                    "DELETE_SUCCESS_MSG",
                    "Record deleted successfully"
                  )
                );
              })
              .catch((err) => {
                toastError(
                  renderFormatMsg("DELETE_FAILED_MSG", "Record deleted failed")
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const onProgress = (uploadProgress) => {
    setProgress(uploadProgress);
  }

  const uploadFile = (e, id) => {
    const fileData = e.target.files[0];

    const formData = new FormData();
    formData.append("file", fileData);
    
    createPromotionType(id, formData, onProgress)
      .then((res) => {
        setUploadComplete(true);
          toastSuccess(
            renderFormatMsg(
              "FILE_UPLOAD_SUCCESS_MSG",
              "The file has been successfully uploaded"
            )
          );
      })
      .catch((error) => {
        toastError(
          renderFormatMsg("FILE_UPLOAD_FAIL_MSG", "The file uploaded fail")
        );
        console.info("error =>", error);
        setUploadComplete(false);
      });

  };

  const fetchData = (state) => {
    getData({ page: state.page, search: state.search });
    setPageIndex(state.page);
  };

  const columns = [
    {
      Header: (
        <div className="text-left">
          {renderFormatMsg("clientPageTable.ClientName", "Client Name")}
        </div>
      ),
      accessor: "clientName",
      minWidth: 150,
      maxWidth: 200,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: (
        <div className="text-left">{renderFormatMsg("Name", "Name")}</div>
      ),
      accessor: "name",
      minWidth: 150,
      maxWidth: 250,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: (
        <div className="text-left">
          {renderFormatMsg("Description", "Description")}
        </div>
      ),
      accessor: "description",
      maxWidth: 400,
      minWidth: 200,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: (
        <div className="text-left">{renderFormatMsg("Bucket", "Bucket")}</div>
      ),
      accessor: "bucket",
      maxWidth: 300,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: (
        <div className="text-left">
          {renderFormatMsg("Receiver", "Receiver")}
        </div>
      ),
      accessor: "receiver",
      maxWidth: 300,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: (
        <div className="text-left">
          {renderFormatMsg("LinkReport", "Link Report")}
        </div>
      ),
      maxWidth: 300,
      resizable: false,
      style: { justifyContent: "flex-start" },
      Cell: (props) => (
        <Link to={`/promotion-type/reports/${props.original.promotionTypeId}`}>
          {" "}
          {renderFormatMsg("LinkReport", "Link Report")}
        </Link>
      ),
    },
  ];

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: "Edit",
      resizable: false,
      maxWidth: 100,
      Cell: (props) => (
        <Link
          to={`/promotion-type/Edit/${props.original.promotionTypeId}?search=${searchValue}`}
          className="edit-icon"
        >
          {renderFormatMsg("Edit", "Edit")}
        </Link>
      ),
    });
  }

  columns.push({
    Header: renderFormatMsg("Delete", "Delete"),
    accessor: "Delete",
    resizable: false,
    maxWidth: 100,
    Cell: (props) => (
      <Link
        to="#"
        onClick={() => removePromotionType(props.original.promotionTypeId)}
        className="delete-icon"
      >
        {renderFormatMsg("Delete", "Delete")}
      </Link>
    ),
  });

  columns.push({
    Header: "",
    resizable: false,
    className: "actionColumn",
    style: { overflow: "inherit !important" },
    width: 40,
    Cell: (props) => (
      <DropdownButton
        key={"left"}
        id={`dropdown-button-drop-${"left"}`}
        variant="secondary"
        title={`...`}
      >
        <Dropdown.Item
          eventKey="1"
          as={Dropdown}
        >
          <label className="myLabel" id="myLabel">
            <input
              type="file"
              name={`file-${props.original.promotionTypeId}`}
              onChange={(e) => uploadFile(e, props.original.promotionTypeId)}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <span>{renderFormatMsg("UploadFile", "Upload File")}</span>
          </label>
      
        </Dropdown.Item>
      </DropdownButton>
    ),
  });
  const delayedQuery = debounce((state) => fetchData(state), 1000);

  const searchHandler = (e) => {
    setPageIndex(0);
    delayedQuery({ page: 0, display: PAGE_SIZE, search: e.target.value });
  };

  return (
    <div className="main-content">
      <PageHeading headingId={"PromotionType"} isLoading={isLoading} />
      <div className="card">
        {error ? (
          <Error msg={error} />
        ) : (
          <>
            <div className="card-header">
              <SearchBox searchHandler={searchHandler} value={parsed.search} />
             
                {pageReadOnly.write && (
                <LinkButton
                  classes={"ml-auto"}
                  to={`/promotion-type/Add?search=${searchValue}`}
                  id="AddPromotionType"
                  defaultText="Add Promotion Type"
                />
              )}
            </div>
            {
            (progress > 0 && progress <= 100 && !uploadComplete)  &&  <div className="container ">
              <ProgressBar  
                now={progress} 
                label={
                   `${progress}% Uploaded. ${progress === 100 ? 'Running post-processing...' : ''}`
                }
                max={100}
                min={0}
                />
              </div>
            }
            <ReactTable
              sortable={false}
              data={promotionType}
              columns={columns}
              isLoading={isLoading}
              onFetchData={(state) =>
                fetchData({ ...state, search: parsed.search })
              }
              pages={totalPage}
              page={pageIndex}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default React.memo(PromotionType);
