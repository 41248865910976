import { toastError, toastSuccess } from "shared/funs";
import { useCallback, useState } from "react";

import { ConfirmPromotionParams } from "v2/core/repository/promotion/types";
import { confirmPromotionToRepo } from "v2/core/repository/promotion/get/confirmPromotionToRepo";

const useConfirmPromotion = () => {
  const [loading, setLoading] = useState(false);

  const confirmPromotion = useCallback(async (id: string, email: string) => {
    setLoading(true);
    const params: ConfirmPromotionParams = {
      promotionId: id,
      email,
    };
    try {
      await confirmPromotionToRepo(params);
      toastSuccess("Promotion confirmed");
    } catch (error) {
      toastError("Confirm promotion failed");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    confirmPromotion,
  };
};

export default useConfirmPromotion;
