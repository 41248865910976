import { useCallback, useEffect, useState } from "react";

import { PromotionByIdParameter } from "v2/core/repository/promotion/types";
import { PromotionGetInfo } from "v2/core/apiTypes";
import { getPromotionInfoFromRepo } from "v2/core/repository/promotion/get/getPromotionInfoFromRepo";

const useGetPromotionInfoById = (id: string, lazy = false) => {
  const [data, setData] = useState<PromotionGetInfo>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getPromotionInfoById = useCallback(async () => {
    setLoading(true);
    const params: PromotionByIdParameter = {
      promotionId: id,
    };
    try {
      const response = await getPromotionInfoFromRepo(params);
      setData(response as PromotionGetInfo);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!lazy) getPromotionInfoById();
  }, [getPromotionInfoById, lazy]);

  return {
    loading,
    error,
    getPromotionInfoById,
    data,
  };
};

export default useGetPromotionInfoById;
