import React from "react";
import styles from "./errorCard.module.scss";
type ErrorCardProps = {
  msg: string;
};
const ErrorCard = ({ msg }: ErrorCardProps) => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.imgWrap}>
        <img src={"images/logo.png"} alt="test" />
      </div>
      <div className={styles.error_text}>
        {msg ? (
          <p dangerouslySetInnerHTML={{ __html: msg }} />
        ) : (
          <p>
            <div>
              <p>
                An unexpected error occurred. The information you have requested
                cannot be shown. We have automatically generated a ticket to our
                support staff.
              </p>
              <p>
                They will take care of the error. Once the error has been
                resolved, you will be sent a notification.
              </p>
              <p>We apologize for the inconvenience.</p>
            </div>
          </p>
        )}
      </div>
    </div>
  );
};

export default ErrorCard;
