import React, { useState } from "react";

import ErrorCard from "v2/views/components/errorCard";
import PageHeading from "v2/views/components/pageHeading";
import ReactTable from "components/ReactTable/ReactTable";
import UnfinishedTaskHeader from "./unfinishedTaskHeader";
import { getUnfinishedTaskColumns } from "./services/getColumns";
import useGetUnfinishedTask from "v2/core/services/callTask/useGetUnfinishedTask";

const UnfinishedTasks = () => {
  const [changeFontSize, setChangeFontSize] = useState(
    localStorage.getItem("currentFontSize") || "14px"
  );

  const {
    loading,
    data,
    error,
    searchHandler,
    handlePageChange,
    params,
    pageIndex,
  } = useGetUnfinishedTask();

  if (error)
    return (
      <div>
        <ErrorCard msg={typeof error === "string" ? error : ""} />
      </div>
    );

  const columns = getUnfinishedTaskColumns(changeFontSize);

  return (
    <div className="main-content">
      <PageHeading headingId={"UnfinishedTasks"} />
      <div className="card">
        <UnfinishedTaskHeader
          searchValue={params.q as string}
          searchHandler={searchHandler}
          changeFontSize={changeFontSize}
          setChangeFontSize={setChangeFontSize}
        />
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <ReactTable
            data={data?.items}
            columns={columns}
            isLoading={loading}
            pages={data?.totalPage}
            page={pageIndex - 1}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default UnfinishedTasks;
