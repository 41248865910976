import React from 'react'
import classes from './style.module.scss';
const Error = ({ msg }) => {
  return (
    <div className={classes.errorPage}>
      <div className={classes.imgWrap}><img src={require('../../images/logo.png')} alt="test" /></div>
      <div className={classes.error_text}>
        {msg ? <p dangerouslySetInnerHTML={{ __html: msg }} /> :
          <p>
            <div>
              <p>An unexpected error occurred. The information you have requested cannot be shown.
              We have automatically generated a ticket to our support staff.</p>
              <p>They will take care of the error. Once the error has been resolved, you will be sent a notification.</p>
              <p>We apologise for the inconvenience.</p>

            </div>
          </p>
        }
      </div>
    </div>
  )
}

export default Error
