import React, { ReactNode } from "react";
type ButtonProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  icon?: React.ReactNode;
};
const Button = ({ children, disabled, loading, onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="submit"
      className="btn btn-primary"
    >
      <>
        {loading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          children
        )}
      </>
    </button>
  );
};

Button.propTypes = {};

export default Button;
