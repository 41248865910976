import { Field, Form } from 'react-final-form';
import { HOST_API, SCOPE_OPTIONS } from '../../constant'
import React, { useEffect, useState } from 'react'
import { addReportDefinition, getReportDefinitionById, updateReportDefination } from '../../services/toolsAndSettingsPagesApi';
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import Axios from 'axios';
import Error from '../../components/Error/Error';
import FieldErrorMessage from '../../components/FieldErrorMessage';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import SelectField from '../../components/Fields/SelectField';
import { get } from "lodash";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const HandleReportDefinition = (props) => {
  const [variable, setVariable] = useState(null)
  const [error, setError] = useState(false)
  const location = useLocation()

  var heading = "EditReportDefinition"

  var values = {};
  if (props.location.pathname.includes("Edit")) {
    values = {
      name: get(variable, "name", ""),
      // script: get(variable, "script", ""),
      recipient: get(variable, "recipient", ""),
      scope: get(variable, "scope", ""),
      includeImport: get(variable, "includeImport", ""),
    }
  } else {
    heading = "AddReportDefinition"
    values = {
      name: "",
      // script: "",
      recipient: "",
      scope: "",
      includeImport: "",
    }
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getReportDefinitionById({ reportId: props.match.params.id }).then(res => {
        setVariable(res)
      }).catch(err => {
        setVariable(null)
        setError(permissionErrorCheck(get(err, "data.value.errors.description", "Something went wrong, please try after some time!")))
      })
    }
  }, [
    props.location.pathname,
    props.match.params.id
  ])

  const handleEditForm = async (data) => {

    let object = {
      "reportId": props.match.params.id,
      "name": data.name,
      // "script": data.script,
      "recipient": data.recipient,
      "scope": data.scope,
      "includeImport": data.includeImport,
    }
    console.info('object => ', object);

    updateReportDefination(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      props.history.push("/report-definition")
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })

  }


  const handleAddForm = async (data) => {
    let object = {
      "name": data.name,
      // "script": data.script,
      "recipient": data.recipient,
      "scope": data.scope,
      "includeImport": data.includeImport,
    }
    console.info('object => ', object);

    addReportDefinition(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully"))
      props.history.push("/report-definition")
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }


  const validation = async (data) => {
    let errors = {}
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }

    if (!data.recipient || data.recipient === "") {
      errors.recipient = "Required";
    } else {
      if (data.recipient && data.recipient.length) {
        let allEmails = data.recipient.trim().replace(" ", "").split(/[,;]/)
        if (allEmails.length) {
          let promises = allEmails.map(email => { return Axios.post(`${HOST_API}/api/v1/CallTask/email/`, { email }) })
          const promiseAll = Promise.allSettled(promises)

          let invalidEmails = await promiseAll.then(response => {
            let tempInvalidEmailList = [];
            response.forEach(resp => {
              if (resp.status === "rejected") {
                tempInvalidEmailList.push(JSON.parse(resp.reason.config.data).email)
              }
            })
            return tempInvalidEmailList
          }).catch(err => {
            console.log('promiseAll err =>', err);
          })
          if (invalidEmails.length) {
            errors.recipient = `Invalid ${invalidEmails.length > 1 ? `emails` : `email`
              }  ${invalidEmails.length > 1 ? `are` : `is`
              } ${invalidEmails.join(", ")} `
          }
        }
      }
    }


    return errors
  }

  const generateOptions = () => {
    var options = [<option value={""}>{"Select Scope"}</option>]
    SCOPE_OPTIONS.map((option, index) =>
      options.push(<option value={option.value}>{option.label}</option>)
    )
    return options;
  }
  const parsed = queryString.parse(location.search);


  return (
    <div className="main-content">
    
      <PageHeading headingId={heading} backURL={`/report-definition?search=${parsed.search}`} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Edit") ? handleEditForm : handleAddForm} initialValues={values} validate={validation}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="Name"
                      defaultLabel="Name"
                      placeholder={"Name"}
                    // readOnly={values.readOnly}
                    // className={`${ values.readOnly && `read-only` } form - control`}
                    />
                  </div>
                 
                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-12"
                      name={"recipient"}
                      type="text"
                      id="Email"
                      defaultLabel="Email"
                      placeholder={"Email"}
                    />
                  </div>
                  <div className="row">
                   
                    <SelectField
                      colClass="col-12 col-lg"
                      name="scope"
                      options={generateOptions()}
                      id="Scope"
                      defaultLabel={"Scope"}
                    />
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Field name="includeImport" type="checkbox">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <div class="checkbox">
                              <lable for="includeImport" style={{ width: "auto !important" }}> {renderFormatMsg("ExportAllImportColumns", "Export All Import Columns")} </lable>

                              <input {...input} />

                              <label ></label>
                            </div>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                </div>

                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandleReportDefinition)
