import {
  CLASSIFICATION_OPTIONS,
  PUSH_LOGIC_OPTIONS,
  REPORT_LOGIC_OPTIONS,
  TARGET_OPTIONS,
} from "constant";

import React from "react";

type OptionType = {
  value: string;
  label: string;
};
interface IReport {
  reportId: string;
  name: string;
}
interface IForms {
  formId: string;
  name: string;
}
interface IDialer {
  id: string;
  name: string;
}

export const generateOptions = (optionData: OptionType[]) => {
  let options = [<option value={""}>{"Select"}</option>];
  optionData.map((option) =>
    options.push(<option value={option.value}>{option.label}</option>)
  );
  return options;
};

export const generateOptionsForTarget = () => generateOptions(TARGET_OPTIONS);
export const generateClassificationOptions = () =>
  generateOptions(CLASSIFICATION_OPTIONS);
export const generateReportLogicOptions = () =>
  generateOptions(REPORT_LOGIC_OPTIONS);

export const generatePushLogicOptions = () =>
  generateOptions(PUSH_LOGIC_OPTIONS);

export const generateFinalReportOption = (reports: IReport[]) => {
  const reportOptions = reports.map((option) => ({
    value: option.reportId,
    label: option.name,
  }));
  return generateOptions(reportOptions);
};

export const generateFormsOption = (forms: IForms[]) => {
  const formOptions = forms.map((option) => ({
    value: option.formId,
    label: option.name,
  }));
  return generateOptions(formOptions);
};

export const generateDialerOptions = (dialer: IDialer[]) => {
  const dialerOptions = dialer.map((option) => ({
    value: option.id,
    label: option.name,
  }));
  return generateOptions(dialerOptions);
};
