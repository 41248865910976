import { DateRangeStatus, checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useHistory, useLocation } from "react-router-dom"
import React, { useContext, useEffect, useState } from 'react'
import { findIndex, get } from 'lodash';
import { getBucketEmptyStatus, getPromotions, getPushToDialerStatus, removePromotion, removePromotionBucket, resendPromotionReport, resetPromotionStatus, setFinish, setPushToDialer, updatePromotionStatus } from '../../services/toolsAndSettingsPagesApi';

import { AppContext } from '../../context/AppContext';
import Calendar from '../../components/Calendar';
import Error from "../../components/Error/Error";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { confirmAlert } from 'react-confirm-alert';
import { debounce } from '@material-ui/core';
import { faFont } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import queryString from 'query-string';

const PAGE_SIZE = 20;

const Promotions = (props) => {
  const location = useLocation();
  const history = useHistory()
  const { userDetails } = useContext(AppContext)
  const [promotions, setPromotions] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [intervalId, setIntervalId] = useState(null)
  const [lastState, setLastState] = useState({ page: 0, display: PAGE_SIZE, search: "" })
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  const [pushToDialerPermission, setPushToDialerPermission] = useState({ read: true, write: false })
  const [changeFontSize, setChangeFontSize] = useState(localStorage.getItem('currentFontSize') || "14px")


  let dataArray = get(userDetails, "accesses")
  let longDateFormat = get(userDetails, "longDateFormat", "DD.MM.YYYY HH:mm a")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_PROMOTIONS").screenId))
    setPushToDialerPermission(checkPermission(dataArray, findPermission("PROMOTION_PUSH_TO_DIALER").screenId))
  }, [dataArray])

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if(Object.keys(parsed).length > 0) setSearchValue(parsed.search)
  }, [location.search])

  const getData = async (data) => {
    setSearchValue(data.search || "")
    getPromotions(data).then(res => {
      setLoading(false)
      res.promotions.forEach((promotion, index) => {
        var buttonStatus = {
          pushToDialer: false,
          finish: false,
          pause: false,
          continue: false,
          delete: false,
          resendReport: false
        }
        switch (promotion.status) {
          case 1:
            buttonStatus.pushToDialer = true;
            buttonStatus.delete = true;
            buttonStatus.resendReport = false
            break;
          case 2:
            buttonStatus.finish = true;
            buttonStatus.pause = true;
            buttonStatus.resendReport = false
            break;
          case 3:
            buttonStatus.continue = true;
            buttonStatus.delete = true;
            buttonStatus.resendReport = false
            break;
          case 4:
            buttonStatus.resendReport = true
            break;
          case 5:
            buttonStatus.resendReport = false
            break;

          default:
            break;
        }

        promotion.buttonStatus = buttonStatus
      })
      setPromotions(res.promotions)
      setTotalPage(res.totalPage)
      setError(false)
    }).catch(err => {
      setError(permissionErrorCheck(err))
      setLoading(false)
      setPromotions([])
    })
  }

  const pushToDialer = (promotionId) => {

    getBucketEmptyStatus(promotionId).then(resp => {
      if (resp.data) {
        setPushToDialerHandler(promotionId)
      } else {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'The bucket for this promotion is not empty. Do you want to delete the leads in the bucket?',
          buttons: [
            {
              label: 'DELETE',
              onClick: () => {
                removePromotionBucket(promotionId).then(res => {
                  setPushToDialerHandler(promotionId)
                  toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
                }).catch(err => {
                  toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
                })
              }
            },
            {
              label: 'Don’t delete', onClick: () => {
                setPushToDialerHandler(promotionId)
              }
            }
          ]
        });
      }

    }).catch(() => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }

  const setPushToDialerHandler = (promotionId) => {
    setPushToDialer(promotionId).then(res => {
      setLoading(false)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      getPushToDialerStatus(promotionId).then(resp => {
        var _promotions = Object.assign([], promotions);
        var index = findIndex(_promotions, { promotionId })
        if (_promotions[index].status) {
          _promotions[index].status = resp.data
        }
        setPromotions(_promotions)
      }).catch(err => {
        console.info('err => ', err);
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      })

    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      setLoading(false)
    })
  }

  const finishHandler = (promotionId) => {
    setLoading(true)
    setFinish(promotionId).then(res => {
      setLoading(false)
      var _promotions = Object.assign([], promotions);
      var index = findIndex(_promotions, { promotionId })
      _promotions[index].finalized = true
      setPromotions(_promotions)
      toastSuccess("Finished")
    }).catch(err => {
      toastError("failed")
    })
  }

  const deletePromotion = (promotionId) => {
    fetchData(lastState)
    setLoading(true)
    removePromotion(promotionId).then(res => {
      setLoading(false)
      toastSuccess("Record Deleted")
    }).catch(err => {
      setLoading(false)
      toastError("Record not Deleted")
    })
  }

  const resetStatus = (promotionId) => {
    resetPromotionStatus(promotionId).then(res => {
      fetchData(lastState)
      toastSuccess("Status Reset")
    }).catch(err => {
      fetchData(lastState)
      toastError("Status not Reset")
    })
  }

  const resendReport = (reportId, promotionId) => {
    setLoading(true)
    fetchData(lastState)
    resendPromotionReport({ reportId, promotionId }).then(res => {
      setLoading(false)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
    }).catch(err => {
      setLoading(false)
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }

  const openDropDown = (status) => {
    clearInterval(intervalId);
  }

  const fetchData = (state) => {

    var interval_id = window.setInterval(() => { }, 9999); // Get a reference to the last
    for (var i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    setPageIndex(state.page)
    setLastState(state)


    getData({ page: state.page + 1, display: PAGE_SIZE, search: state.search })
    var interval = setInterval(() => {
      getData({ page: state.page + 1, display: PAGE_SIZE, search: state.search })
    }, 5000);
    setIntervalId(interval)
  }

  useEffect(() => {
    const params = new URLSearchParams()
    if (searchValue) {
      params.append("search", searchValue)
    } else {
      params.delete("search")
    }
    history.push({ search: params.toString() })
  }, [history, searchValue])

  const delayedQuery = debounce((state) => fetchData(state), 1000);

  const SearchHandler = (e) => {
    setPageIndex(0);
    delayedQuery({ page: 0, display: PAGE_SIZE, search: e.target.value });
  }

  const updateStatus = (id, status) => {
    delayedQuery(lastState);
    let obj = {
      promotionId: id,
      isActive: status
    }

    updatePromotionStatus(obj).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
    }).catch(err => {
      toastSuccess(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated failed"))
    })
  }


  const normalFont = () => {
    setChangeFontSize("14px")
    localStorage.setItem('currentFontSize', "14px")
  }
  const smallFont = () => {
    setChangeFontSize("12px")
    localStorage.setItem('currentFontSize', "12px")
  }
  const extraSmallFont = () => {
    setChangeFontSize("11px")
    localStorage.setItem('currentFontSize', "11px")
  }


  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Description", "Description")}</div>,
      accessor: 'description',
      minWidth: 130,
      maxWidth: 350,
      resizable: false,
      style: { justifyContent: "flex-start", fontSize: `${changeFontSize}` },
      headerClassName: "xyz",
      className: "abc"
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Bucket", "Bucket")}</div>,
      accessor: 'bucket',
      minWidth: 150,
      maxWidth: 350,
      resizable: false,
      style: { justifyContent: "flex-start", fontSize: `${changeFontSize}` },
    },
    {
      Header: renderFormatMsg("ActiveFrom", "Active From"),
      accessor: 'activeFrom',
      resizable: false,
      width: 130,
      maxWidth: 200,
      style: { fontSize: `${changeFontSize}` },
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar
          name="activeFrom"
          className={`read-only py-2 px-0`}
          disabled={true}
          selectedDate={new Date(props.original.activeFrom)}
          maxDate={new Date(props.original.activeTo)}
        />
      </div>
    }, {
      Header: renderFormatMsg("ActiveTo", "Active to"),
      accessor: 'activeTo',
      resizable: false,
      width: 130,
      maxWidth: 200,
      style: { fontSize: `${changeFontSize}` },
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar name="activeTo"
          className={`read-only py-2 px-0`}
          disabled={true}
          selectedDate={new Date(props.original.activeTo)}
          minDate={new Date(props.original.activeFrom)}
        />
      </div>
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Created", "Created")}</div>,
      accessor: 'createdAt',
      minWidth: 110,
      maxWidth: 180,
      resizable: false,
      style: { justifyContent: "flex-start", fontSize: `${changeFontSize}` },
      Cell: props => <div>{get(props, "original.createdAt") && moment(props.original.createdAt).format(longDateFormat)}</div>
    },
    {
      Header: renderFormatMsg("Status", "Status"),
      resizable: false,
      width: 100,
      style: { fontSize: `${changeFontSize}` },
      Cell: props => <span>{get(props.original, "totalRecordWithStatus1", "")} / {get(props.original, "totalRecordWithoutStatus1", "")}</span>
    },

  ]

  if (pushToDialerPermission.write) {
    columns.push({
      Header: renderFormatMsg("PushToDialer", "Push to dialer"),
      resizable: false,
      style: { justifyContent: "center", fontSize: `${changeFontSize}`  },
      width: 140,
      Cell: props => props.original.buttonStatus.pushToDialer ?
        <Link to="#" onClick={() => pushToDialer(props.original.promotionId)} className="add-icon">{renderFormatMsg("PushToDialer", "Push to dialer")}</Link> :
        <Link to="#" className="add-icon read-only-push">{renderFormatMsg("PushToDialer", "Push to dialer")}</Link>
    })
  }

  if (pageReadOnly.write) {
    columns.push({
      Header: pageReadOnly.write && renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      fixed: "left",
      resizable: false,
      width: 100,
      style: { fontSize: `${changeFontSize}` },
      Cell: props => pageReadOnly.write && <Link to={`/promotions/edit/${props.original.promotionId}?search=${searchValue}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }

  columns.push({
    Header: renderFormatMsg("finish", "Finish"),
    resizable: false,
    style: { justifyContent: "center", fontSize: `${changeFontSize}` },
    width: 100,
    Cell: props => props.original.buttonStatus.finish ? <Link to="#" onClick={() => finishHandler(props.original.promotionId)} className="add-icon">{renderFormatMsg("finish", "Finish")}</Link> : <Link to="#" className="add-icon read-only-push">{renderFormatMsg("finish", "Finish")}</Link>
  })

  columns.push({
    Header: "",
    resizable: false,
    className: "actionColumn",
    style: { overflow: "inherit !important", fontSize: `${changeFontSize}` },
    width: 40,
    Cell: props => <DropdownButton
      onClick={() => openDropDown(false)}
      key={'left'}
      id={`dropdown-button-drop-${'left'}`}
      variant="secondary"
      title={`...`}
    >
      <Dropdown.Item eventKey="1" disabled={!props.original.buttonStatus.pause} onClick={() => { openDropDown(true); updateStatus(props.original.promotionId, false) }} >{renderFormatMsg("Pause", "Pause")}</Dropdown.Item>

      <Dropdown.Item eventKey="2" disabled={!props.original.buttonStatus.continue} onClick={() => { openDropDown(true); updateStatus(props.original.promotionId, true) }} >{renderFormatMsg("Continue", "Continue")}</Dropdown.Item>

      <Dropdown.Item eventKey="3" disabled={!props.original.buttonStatus.delete} onClick={() => { openDropDown(true); deletePromotion(props.original.promotionId) }} >{renderFormatMsg("Delete", "Delete")}</Dropdown.Item>

      <Dropdown.Item eventKey="4" onClick={() => { openDropDown(true); resetStatus(props.original.promotionId) }} >{renderFormatMsg("ResetStatus", "Reset Status")}</Dropdown.Item>

      <Dropdown.Item eventKey="5" as={Link} to={`/promotions/details/${props.original.promotionId}`}>
        {renderFormatMsg("Details", "Details")}
      </Dropdown.Item>

      <Dropdown.Divider />
      {props.original.reports && props.original.reports.length > 0 ? <div>
        <span style={{ textDecoration: "underline", fontWeight: 600, padding: ".25rem 1.5rem" }}>Reports</span>
        <div style={{ overflowY: "auto", marginTop: 5 }}>

          {
            props.original.reports.map((report, index) =>
              <Dropdown.Item eventKey="4" onClick={() => { openDropDown(true); resendReport(report.reportId, props.original.promotionId) }} >{report.reportName}</Dropdown.Item>
            )
          }
        </div>
      </div> : <div><span style={{ textDecoration: "underline", fontWeight: 600, padding: ".25rem 1.5rem" }}>No Reports</span></div>}

    </DropdownButton >
  })

  const parsed = queryString.parse(props.location.search);

  return (
    <div className="main-content">
      <PageHeading headingId="Promotions" isLoading={isLoading} />

      <div className="card promotion">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} value={parsed.search} />

              <div>
                <div className='btn-group btn-group-toggle m-1'>

                  <div className={'btn btn-secondary ' + (changeFontSize === "14px" ? 'active' : '')} onClick={() => normalFont()} title='Normal Font'>
                    <FontAwesomeIcon icon={faFont} size='lg' />
                  </div>

                  <div className={'btn btn-secondary ' + (changeFontSize === "12px" ? 'active' : '')} onClick={() => smallFont()} title='Small Font'>
                    <FontAwesomeIcon icon={faFont} size='sm' />
                  </div>

                  <div className={'btn btn-secondary ' + (changeFontSize === "11px" ? 'active' : '')} onClick={() => extraSmallFont()} title='Extra Small Font'>
                    <FontAwesomeIcon icon={faFont} size='xs' />
                  </div>

                </div>
              </div>
            </div>

            <ReactTable data={promotions} columns={columns} isLoading={isLoading} onFetchData={(state) => fetchData({ ...state, search: parsed.search })} pages={totalPage} page={pageIndex} />

          </>
        }
      </div>
    </div >
  )
}
export default React.memo(Promotions)
