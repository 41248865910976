import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { RequestParams } from "v2/core/repository/callTask/types";
import { UnfinishedTaskPagination } from "v2/core/apiTypes";
import { debounce } from "lodash";
import { getUnfinishedTasksFromRepo } from "v2/core/repository/callTask/get/getUnfinishedTasksFromRepo";
import queryString from "query-string";

type ErrorType = Error & {
  status: number;
};
const PAGE_SIZE = 20;
type ParamsType = {
  page: string;
  q: string;
};

const useGetUnfinishedTask = () => {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search) as ParamsType;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | string | undefined>(undefined);
  const [data, setData] = useState<UnfinishedTaskPagination | undefined>(
    undefined
  );

  const [pageIndex, setPageIndex] = useState(
    params.page ? parseInt(params.page) : 1
  );

  const delayedPush = debounce((value) => {
    const state = {
      page: 1,
      q: value,
    };
    if (!value) {
      delete state.q;
    }

    history.replace(`?${queryString.stringify(state)}`);
  }, 1000);

  const searchHandler = useCallback(
    (value: string) => {
      setPageIndex(1);
      delayedPush(value);
    },
    [delayedPush]
  );

  const getUnfinishedTask = useCallback(async (params: RequestParams) => {
    setLoading(true);
    try {
      const response = await getUnfinishedTasksFromRepo(params);
      const data = response as UnfinishedTaskPagination;
      setData(data);
    } catch (error) {
      const errorResponse = error as ErrorType;
      if (
        errorResponse &&
        (errorResponse.status === 401 || errorResponse.status === 403)
      )
        setError("Access denied you don't have permission to access this page");
      else setError(errorResponse);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const page = (params.page || 1) as number;
    const search = (params.q || "") as string;
    const paramsPayload = {
      page,
      display: PAGE_SIZE,
      search,
    };
    getUnfinishedTask(paramsPayload);
  }, [getUnfinishedTask, params.page, params.q, pageIndex]);

  const handlePageChange = useCallback(
    (pI: number) => {
      setPageIndex(pI + 1);
      history.push(
        `/unfinished-tasks?page=${pI + 1}${params.q ? `&${params.q}` : ""}`
      );
    },
    [history, params.q]
  );

  return {
    loading,
    error,
    data,
    getUnfinishedTask,
    searchHandler,
    handlePageChange,
    params,
    pageIndex,
    setPageIndex,
  };
};

export default useGetUnfinishedTask;
