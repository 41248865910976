import { Button, Form, FormControl } from "react-bootstrap";

import React from "react";

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_98"
      data-name="Path 98"
      d="M15.8,14.865l-4.55-4.55a6.345,6.345,0,1,0-.943.943l4.55,4.55a.667.667,0,1,0,.943-.943ZM6.333,11.336a5,5,0,1,1,5-5A5.005,5.005,0,0,1,6.333,11.336Z"
      transform="translate(0 -0.003)"
    />
  </svg>
);

type SearchBoxProps = {
  searchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
};
const SearchBox = ({ searchHandler, className, value }: SearchBoxProps) => {
  return (
    <Form className={`search-boxs ${className}`}>
      <FormControl
        type="text"
        defaultValue={value}
        placeholder="Search"
        onChange={searchHandler}
      />
      <Button variant="light">
        <SearchIcon />
      </Button>
    </Form>
  );
};

export default SearchBox;
