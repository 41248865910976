import { DATE_FORMAT, TARGET_OPTIONS, ZONES } from '../../constant';
import { Field, Form } from 'react-final-form';
import React, { useEffect, useState } from 'react'
import { getDialer, getForms, getPromotion, getPromotionGroupData } from '../../services/toolsAndSettingsPagesApi';
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import ActiveFromAndToDate from '../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CreatableSelect from 'react-select/creatable';
import Error from '../../components/Error/Error';
import FieldErrorMessage from '../../components/FieldErrorMessage';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import SelectField from '../../components/Fields/SelectField';
import { get } from "lodash";
import moment from 'moment';
import queryString from 'query-string';
import { updatePromotion } from '../../services/sidebarApi';

const HandlePromotions = (props) => {
  const [promotion, setPromotion] = useState(null)
  const [error, setError] = useState(false)
  const [zones,] = useState(ZONES)
  const [promotionGroup, setPromotionGroup] = useState([])
  const [forms, setForms] = useState([])

  const [, setSelectedZones] = useState([])

  var heading = "Add Promotion";

  var values = {};
  if (props.location.pathname.includes("edit")) {
    heading = "Edit Promotion";
    var constants = get(promotion, "constants.constant", [])
    values = {
      clientName: get(promotion, "client.clientName"),
      bucket: get(promotion, "bucket"),
      scriptTitle: get(promotion, "scriptTitle", ""),
      maxCallCount: get(promotion, "maxCallCount", ""),
      script: get(promotion, "script", ""),
      target: get(promotion, "target"),
      dialerId: get(promotion, "dialerId"),
      formID: get(promotion, "formID"),
      prefix: get(promotion, "prefix"),
      description: get(promotion, "description"),
      activeFrom: get(promotion, "activeFrom"),
      activeTo: get(promotion, "activeTo"),
      promotionGroupId: get(promotion, "promotionGroupId"),
      constants,
      readOnly: get(promotion, "status", 0) !== 1 ? true : false,
      readOnlyConstant: get(promotion, "status", 0) > 1 ? true : false,
    }


  }


  useEffect(() => {
    getPromotion({
      promotionId: props.match.params.id
    }).then(data => {

      getPromotionGroupData({ display: 9999999, page: 1 }).then(res => {
        setPromotionGroup(res.promotionGroup.length > 0 ? res.promotionGroup : [])
      })
  
      setPromotion(data)
    }).catch(err => {
      setPromotion(null)
      setError(permissionErrorCheck(err))
    })
  }, [props.match.params.id])

  const handleForm = async (data) => {

    let object = {
      promotionId: props.match.params.id,
      description: data.description,
      formID: data.formID,
      dialerId: data.dialerId,
      bucket: data.bucket,
      prefix: data.prefix,
      target: data.target,
      promotionGroupId: data.promotionGroupId,
      scriptTitle: data.scriptTitle,
      maxCallCount: data.maxCallCount,
      script: data.script,
      activeFrom: moment(data.activeFrom).format(DATE_FORMAT),
      activeTo: moment(data.activeTo).format(DATE_FORMAT),

    }

    if (data.constants && data.constants.length > 0) {
      var _constants = [...data.constants];
      _constants.forEach(o => {
        if (o.type === "list") {
          if (o.value && typeof o.value === "object") {
            var temp = []
            o.value.forEach(o => { temp.push(o && o.label ? o.label : o) })
            o.value = [].concat(temp)
          }
        } else {
          o.value = [].concat(o.value)
        }
      })
      object.constants = {
        constant: _constants
      }

    }

    updatePromotion(object).then(() => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully"))
      setTimeout(() => {
        props.history.push("/promotions")
      }, 1000);
    }).catch(() => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
  }

  const validation = (data) => {
    let errors = {}
    if (!data.description || data.description === "") {
      errors.description = "Required";
    }
    if (!data.bucket || data.bucket === "") {
      errors.bucket = "Required";
    }

    if (!data.target || data.target === "") {
      errors.target = "Required";
    }

    return errors
  }

  const handleChange = (newValue) => {
    setSelectedZones(newValue)
  };

  const getFields = (values) => {
    var temp = []
    values.constants && values.constants.forEach((field, index) => {
      if (field.type === "number") {
        temp.push(<div className="col-lg-6">
          <Field name={`constants[${index}][value]`}>
            {({ input }) => (
              <div className="form-group">
                <label>{field.constantName}</label>
                <input
                  onChange={input.onChange}
                  defaultValue={get(field, "value[0]", "")}
                  readOnly={values.readOnlyConstant}
                  type="number" max={24}
                  min={1}
                  placeholder={field.description}
                  className={`form-control`} />
              </div>
            )}
          </Field>
        </div>)
      } else if (field.type === "list") {
        temp.push(<div className="col-lg-12">
          <Field name={`constants[${index}][value]`}>
            {({ input }) => (
              <div className="form-group">
                
                <label> {field.description} </label>
                <CreatableSelect
                  defaultValue={input.value && input.value.map(o => { return { label: o && o.label ? o.label : o, value: o && o.value ? o.value : o } })}
                  isDisabled={values.readOnlyConstant}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={(o) => handleChange(o, input.onChange)}
                  options={zones}
                  placeholder="Select zone(s)"
                />
              </div>
            )}
          </Field>
        </div>)
      }
    })

    return temp
  }

  const generateOptions = () => {
    var options = [<option value={""}>{"Select Target"}</option>]
    TARGET_OPTIONS.map((option) =>
      options.push(<option value={option.value}>{option.label}</option>)
    )
    return options;
  }

  const generateProductGroupOptions = (optionArray) => {
    var options = [<option value={""}>{"Select Target"}</option>]
    optionArray.map(({ promotionGroupId, name }) =>
      options.push(<option value={promotionGroupId}>{name}</option>)
    )
    return options;
  }
  
  const [dialer, setDialer] = useState([])
  
// For generate Dialer
useEffect(() => {
  let param = {
    "page": 0 + 1,
    "display": 999999,
  }
  getDialer(param).then(res => {

    setDialer(res)
  }).catch(() => {
    setDialer([])
  })
}, [])


  //For generate Form
  useEffect(() => {
    let param = {
      "page": 0 + 1,
      "display": 999999,
    }
    getForms(param).then(res => {
      setForms(res.items)
    }).catch(() => {
      setForms([])
    })
  }, [])


  const parsed = queryString.parse(props.location.search);
  const generateDialerOptions = () => {
    var options = [<option value={""}>{"Select Dialer"}</option>]
    dialer.map((option) =>
      options.push(<option value={option.id}>{option.name}</option>)
    )
    return options;
  }

  const generateFormsOption = () => {
    var options = [<option value={""}>{"Select Form"}</option>]
    forms.map((option) =>
      options.push(<option value={option.formId}>{option.name}</option>)
    )
    return options;
  }

  return (
    <div className="main-content">
   
      <PageHeading headingId={heading} backURL={`/promotions?search=${parsed.search}`} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                  
                    <InputField
                      colClass="col-lg-6"
                      name={"clientName"}
                      type="text"
                      id="ClientName"
                      defaultLabel="Client Name"
                      placeholder={"Client Name"}
                      readOnly
                      className="read-only form-control"
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"description"}
                      type="text"
                      id="Description"
                      defaultLabel="Description"
                      placeholder={"Description"}
                    />
                  </div>
                  <div className="row">
                  <SelectField
                      colClass="col-lg-6"
                      name="dialerId"
                      options={generateDialerOptions()}
                      id="Dialer"
                      defaultLabel={"Dialer"}
                      disabled={values.readOnly} 
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                     <InputField
                      colClass="col-lg-6"
                      name={"bucket"}
                      type="text"
                      id="Bucket"
                      defaultLabel="Bucket"
                      placeholder={"Bucket"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">
                    <InputField
                      colClass="col-lg-6"
                      name={"prefix"}
                      type="text"
                      id="Prefix"
                      defaultLabel="Prefix"
                      placeholder={"Prefix"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                     <SelectField
                      colClass="col-lg-6"
                      name="formID"
                      options={generateFormsOption()}
                      id="Form"
                      defaultLabel={"Form"}
                      disabled={values.readOnly} 
                      className={`${values.readOnly && `read-only`} form-control`}

                    />
                   
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <ActiveFromAndToDate readOnly={values.readOnly} />
                    </div>
                    
                    <SelectField
                      colClass="col-12 col-lg"
                      name="target"
                      options={generateOptions()}
                      id="Target"
                      defaultLabel={"Target"}
                      disabled={values.readOnly} 
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">
                   
                    <SelectField
                      colClass="col-12 col-lg"
                      name="promotionGroupId"
                      options={generateProductGroupOptions(promotionGroup)}
                      id="PromotionGroup"
                      defaultLabel={"Promotion Group"}
                      className={`${!values.readOnlyConstant && values.readOnly && `read-only`} form-control`}
                    />
                  </div>

                  <div className="row">
                    <InputField
                      colClass="col-lg-6"
                      name={"scriptTitle"}
                      type="text"
                      id="ScriptTitle"
                      defaultLabel="Script Title"
                      placeholder={"Script Title"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                    <InputField
                      colClass="col-lg-6"
                      name={"maxCallCount"}
                      type="text"
                      id="maxCallCount"
                      defaultLabel="Max. CallCount"
                      placeholder={"Max. CallCount"}
                      readOnly={values.readOnly}
                      className={`${values.readOnly && `read-only`} form-control`}
                    />
                   
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Field name="script">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("Script", "Script")} </label>
                            <CKEditor
                              disabled={values.readOnly}
                              data={input.value}
                              editor={ClassicEditor}
                              config={{
                                removePlugins: ['Link', 'Indent', 'Outdent', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                input.onChange(data)
                              }}
                            />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="row">
                    {values && values.constants && getFields(values)}
                  </div>


                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">{renderFormatMsg("Save", "Save")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandlePromotions)
