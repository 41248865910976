import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { deleteremoveRecruitingTasks, getOpenRecruitingTasks } from '../../services/sidebarApi';
import { permissionErrorCheck, renderFormatMsg, toastSuccess, } from '../../shared/funs';

import { DATE_TIME_FORMAT } from '../../constant';
import Error from "../../components/Error/Error";
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { confirmAlert } from 'react-confirm-alert';
import { debounce } from '@material-ui/core';
import moment from 'moment';
import queryString from 'query-string';

const PAGE_SIZE = 20;

const OpenRecruitingTasks = () => {
    const [openRecruitingTasks, setOpenRecruitingTasks] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const location = useLocation();
    const [totalPage, setTotalPage] = useState(0)
    const history = useHistory();
    const parsed = queryString.parse(location.search);
    const getData = async ({ page = 0, search = "" }) => {
        setSearchValue(search)
        setLoading(true)

        let param = {
            "page": page + 1,
        }

        if (search) {
            param.search = search
        }
        

        getOpenRecruitingTasks(param).then(res => {
            setOpenRecruitingTasks(res)
            setTotalPage(res.totalPage)
            setLoading(false)
        }).catch(err => {
            setError(permissionErrorCheck(err))
            setLoading(false)
            setOpenRecruitingTasks([])
        })
    }
    useEffect(() => {
        const params = new URLSearchParams()
        if (searchValue) {
          params.append("search", searchValue)
        } else {
          params.delete("search")
        }
        history.push({ search: params.toString() })
      }, [history, searchValue])

    const removeRecruitingTasks = (taskId) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteremoveRecruitingTasks(taskId).then(res => {
                            setLoading(false)
                            toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
                        }).catch(err => {
                            setError(permissionErrorCheck("Something went wrong! please try after some time!"))
                            setLoading(false)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const columns = [
        {
            Header: <div>{renderFormatMsg("TaskDefinitionId", "Task Definition Id")}</div>,
            accessor: 'taskDefinitionId',
            resizable: false,
        },
        {
            Header: <div>{renderFormatMsg("Href", "Href")}</div>,
            accessor: 'href',
            resizable: false,
            style: { whiteSpace: 'normal', wordBreak: 'break-word' },
            Cell: props =>
                <a href={`https://x5.skillaz.ru/tasks/${props.original.taskId}`}
                    rel="noopener noreferrer"
                target="_blank">
                    {`https://x5.skillaz.ru/tasks/${props.original.taskId}`}
                </a>
        },
        {
            Header: <div>{renderFormatMsg("Phone", "Phone")}</div>,
            accessor: 'phone',
            resizable: false,
            Cell: props =>
                <a 
                rel='noopener noreferrer'
                href={`https://x5.skillaz.ru/showcase?Text[]=${props.original.phone}`} target="_blank">
                    {props.original.phone}
                </a>
        },
        {
            Header: <div>{renderFormatMsg("Time", "Time")}</div>,
            accessor: 'time',
            resizable: false,
            Cell: props => <span>{moment(props.original.time).format(DATE_TIME_FORMAT)}</span>
        },
        {
            Header: <div>{renderFormatMsg("TimeZone", "Time Zone")}</div>,
            accessor: 'timezone',
            resizable: false,
        },
        {
            Header: <div>{renderFormatMsg("Delete", "Delete")}</div>,
            accessor: 'Delete',
            resizable: false,
            Cell: props => <Link to="#" onClick={() => removeRecruitingTasks(props.original.taskId)} className="delete-icon">{renderFormatMsg("Delete", "Delete")}</Link>
        }
    ]

    const fetchData = (state) => {
        getData({ page: state.page, search: state.search })
    }
    const delayedQuery = debounce((state) => fetchData(state), 1000);

    const searchHandler = (e) => {
        setPageIndex(0);
        delayedQuery({ page: 0, display: PAGE_SIZE, search: e.target.value });
      }

    return (
        <div className="main-content">
            <PageHeading headingId={"OpenRecruitingTasks"} isLoading={isLoading} />

            <div className="card">
                {error ? <Error msg={error} /> :
                    <>
                        <div className="card-header">
                        <SearchBox searchHandler={searchHandler} value={parsed.search} />

                        </div>
                        <ReactTable data={openRecruitingTasks} columns={columns} isLoading={isLoading} onFetchData={(state) => fetchData({ ...state, search: parsed.search })} pages={totalPage} page={pageIndex}  />
                    </>
                }
            </div>
        </div>
    )
}
export default React.memo(OpenRecruitingTasks)
