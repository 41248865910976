const en = {
  translations: {

    "customer_data": "Customer data",
    "company_name": "Company Name",
    "personnal_cabinet": "Personnal cabinet",
    "current_customer_name": "Current Customer Name",
    "phone_of_the_customer": "Phone of the customer",
    "wrong_number": "Wrong number",
    "received_information": "Received information",
    "type_of_customer": "Type of customer",
    "company": "Company",
    "private_person": "Private person",
    "agreement_for_communication": "Agreement for communication",
    "yes": "Yes",
    "no": "No",
    "email": "eMail",
    "do_not_call_me_ever_again": "Do not call me ever again",
    "not_interested": "Not interested",
    "interested": "Interested",
    "call_back_later": "Call Back later",
    "call_back_in_1_hour": "Call back in 1 hour",
    "call_back_in_2_hour": "Call back in 2 hour",
    "call_back_in_4_hour": "Call back in 4 hour",
    "call_back_in_6_hour": "Call back in 6 hour",
    "call_back_tomorrow": "Call back tomorrow",
    "information_about_the_promotion": "Information about the promotion",
    "promotion_name": "Promotion Name",
    "discount": "Discount",
    "plan_turnover": "Plan Turnover",
    "fact_turnover": "Fact Turnover",
    "fulfillment": "Fulfillment",
    "target_achievement": "Target Achievement",
    "missing_turnover": "Missing Turnover",
    "complains": "Complains",
    "feedback": "feedback",
    "can_we_call_to_investigate_reasons": "Can we call to investigate reasons",
    "save_msg": "The data is saved, you can close the window.",
    "error_msg": "something went wrong, please, try again",
    "schedule": "Schedule",
    "from": "From",
    "to": "To",
    "questions": "Questions",
    "AgreementForSendSMS": "Agreement for Send SMS",
    "SMSPhone": "SMS Phone",
    "bonusAmount": "Bonus Amount",
    "bonusExpiration": "Bonus Expiration",
    "bonusRegistration": "Bonus Registration",
    "credit": "Credit",
    "products": "Products",
    "name": "Name",
    "category": "Category",
    "action": "Action",
    "loanTerm": "Loan term, in months",
    "requestedAmount": "Requested Amount",
    "region": "Region",
    "DOB": "DOB",
  }
}

export default en;