import { AppContext, AppProvider } from "./AppContext";
import React, { createContext } from "react";
import { ToolsAndSettingContext, ToolsAndSettingProvider } from "./ToolsAndSettingContext";

import { withRouter } from "react-router-dom";

let MainContext;

const { Provider, Consumer } = (MainContext = createContext());

var MainProvider = ({ children, ...props }) => {
  return (
    <Provider
      value={{
        ...props,
      }}
    >
      <AppProvider {...props}><ToolsAndSettingProvider>{children}</ToolsAndSettingProvider></AppProvider>
    </Provider>
  );
};
MainProvider = withRouter(MainProvider);
export { MainProvider, Consumer as MainConsumer, MainContext, AppContext, ToolsAndSettingContext };
