import { useHistory, useLocation } from "react-router-dom";

import queryString from "query-string";
import { useCallback } from "react";

const useEditFieldFromReport = () => {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const redirectToEditField = useCallback(
    (id: string) =>
      history.push(
        `/report-definition/detail/edit-field/${id}${
          parsed.search ? `?search=${parsed.search}` : ""
        }`
      ),
    [history, parsed.search]
  );
  return {
    redirectToEditField,
  };
};

export default useEditFieldFromReport;
