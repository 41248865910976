import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react'
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, } from '../../shared/funs';
import { debounce, get } from 'lodash';

import { AppContext } from '../../context/AppContext';
import Error from "../../components/Error/Error";
import LinkButton from '../../components/Buttons/LinkButton';
import { PAGE_SIZE } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { getReportFields } from '../../services/toolsAndSettingsPagesApi';
import queryString from 'query-string';

const ReportFields = () => {
  const location = useLocation()
  const history = useHistory()
  const [reportFields, setReportFields] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  const parsed = queryString.parse(location.search);

  let dataArray = get(userDetails, "accesses")
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (Object.keys(parsed).length > 0) setSearchValue(parsed.search);
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchValue) {
      params.append("search", searchValue);
    } else {
      params.delete("search");
    }
    history.push({ search: params.toString() });
  }, [history, searchValue]);

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_REPORT_FIELDS").screenId))
  }, [dataArray])


  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      page: page + 1,
      display: PAGE_SIZE,
      search
    }
    getReportFields(param).then(res => {
      setLoading(false)
      setReportFields(res.reportfield)
      setTotalPage(res.totalPage)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setReportFields([])
    })
  }


  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
      accessor: 'name',
      resizable: true,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Path", "Path")}</div>,
      accessor: 'path',
      resizable: true,
      style: { justifyContent: "flex-start" },
      Cell: props => <div title={props.original.path}>{props.original.path}</div>
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Title", "Title")}</div>,
      accessor: 'title',
      resizable: true,
      style: { justifyContent: "flex-start" },
    },

  ]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      resizable: false,
      width: 120,
      Cell: props => <Link to={`/report-fields/Edit/${props.original.fieldId || 1}?search=${searchValue}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }

  const fetchData = (state) => {
    getData({ page: state.page, search: state.search })
    setPageIndex(state.page)
  }
  const delayedQuery = debounce((state) => fetchData(state), 1000);


  const searchHandler = (e) => {
    setPageIndex(0);
    delayedQuery({ page: 0, display: PAGE_SIZE, search: e.target.value });
  };

  return (
    <div className="main-content">
      <PageHeading headingId={"ReportFields"} isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={searchHandler} value={parsed.search} />
              {pageReadOnly.write && <LinkButton classes={"ml-auto"} to={`/report-fields/Add?search=${searchValue}`} id="AddReportFields" defaultText="Add Report Field" />}
            </div>
            <ReactTable data={reportFields} columns={columns} isLoading={isLoading} onFetchData={(state) =>
                fetchData({ ...state, search: parsed.search })
              } pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div>
  )
}
export default React.memo(ReportFields)
