import BackIcon from "../../images/back-icon.svg";
import { Link } from 'react-router-dom'
import React from 'react'
import { injectIntl } from 'react-intl';

const PageHeading = ({ intl, headingId, isLoading = false, backURL = '', children, onClick }) => {
    const formatMessage = (id) => intl.formatMessage({ id });
    return (
        <div className={`title ${isLoading && 'severityChangedColor'}`}>
            <div className="heading d-flex">
                {backURL && <Link className="back-btn" to={backURL}><img src={BackIcon} alt="BackIcon" /></Link>}
                {onClick && <div onClick={onClick} className="back-btn cursor-pointer" to={backURL}><img src={BackIcon} alt="BackIcon" /></div>}
                <span>{formatMessage(headingId)}</span>
            </div>
            {children}
        </div>
    )
}

export default injectIntl(React.memo(PageHeading))
