import { useHistory, useLocation, useParams } from "react-router-dom";

import Button from "../../v2/views/components/button";
import { Form } from "../../components/PromoFrontendForm";
import InputField from "../../v2/views/components/fields/inputField";
import PageHeading from "../../components/PageHeading/PageHeading";
import React from "react";
import { injectIntl } from "react-intl";
import queryString from "query-string";
import useGetReportFieldById from "../../v2/core/services/report/useGetReportFieldById";
import useUpdateReportFieldById from "../../v2/core/services/report/useUpdateReportFieldById";

type ParamsType = {
  id: string;
};
type FormValues = {
  reportFieldName: string;
  title: string;
};
const EditReportField = () => {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const params = useParams<ParamsType>();
  const columnId = params.id;
  const { loading, error, fieldData } = useGetReportFieldById(columnId);
  const { loading: updateLoading, updateReportField } =
    useUpdateReportFieldById(() =>
      history.push(`/report-definition/detail/Edit/${fieldData?.reportId}`)
    );
  const onSubmit = (values: FormValues) => {
    const payload = {
      columnId,
      title: values.title,
    };
    updateReportField(payload);
  };
  if (loading) return <div>Loading...</div>;
  const initialFormValues = {
    reportFieldName: fieldData?.reportFieldName,
    title: fieldData?.title,
  };
  return (
    <div className="main-content">
      <PageHeading
        headingId={"EditReportField"}
        onClick={() =>
          history.replace(
            `/report-definition/detail/Edit/${fieldData?.reportId}${
              parsed.search ? `?search=${parsed.search}` : ""
            }`
          )
        }
        children={<></>}
      />
      {error ? (
        "Error!"
      ) : (
        <div className="main-content">
          <div className="card p-3">
            <Form
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="row">
                  <div className="form-group col-md-6">
                    <InputField
                      name={"reportFieldName"}
                      placeholder={"Report Field Name"}
                      label={"Report Field Name"}
                      defaultLabel={"Report Field Name"}
                      id={"reportFieldName"}
                      readOnly={true}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <InputField
                      name={"title"}
                      placeholder={"Header"}
                      label={"Header"}
                      defaultLabel={"Header"}
                      id={"title"}
                      required={true}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Button
                      loading={updateLoading}
                      disabled={updateLoading}
                      type="submit"
                    >
                      Save Report Field
                    </Button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(EditReportField);
