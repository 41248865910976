import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { withRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./Header.scss";
import { renderFormatMsg } from "../../../shared/funs"
import UserDetail from "../../UserDetail";
import NotificationDetail from "../../NotificationDetail";
import ToolsAndSettingDetail from "../../ToolsAndSettingDetail";


const Header = (props) => {

  return (
    <header>
      <h1>
        {(props.location.pathname === "/call"
        ) ? renderFormatMsg("PromotionSolution", "Promotion Solution") : renderFormatMsg("header.Header", "Header")}
      </h1>
      <div className="reports-list">
        {(props.location.pathname !== "/call"
        ) && <ToolsAndSettingDetail />}
      </div>
      <div className="user-list">
        {(props.location.pathname !== "/call"
        ) && <><NotificationDetail />
            <UserDetail />
          </>}
      </div>
      <ToastContainer
        limit={1}
        position="bottom-right"
        transition={Zoom}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </header>
  );
};
export default React.memo(withRouter(Header));
