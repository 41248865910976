import axios from "axios";
import { getToken } from "../shared/funs";

const axiosInstance = axios.create({
  crossDomain: false,
  responseType: "json",
  headers: {
    'Content-Type': 'application/json',
  },
});


axiosInstance.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (response) {
    // some unnecessary defensive programming
    if (response !== undefined) {
      if (response.data) return response.data;
      return response;
    } else return {};
  },
  function (error) {
    try {
      if (error.response.status === 401) {
        localStorage.clear();
        // history.goBack();
        window.location.href = "/login";
      } else {
        return Promise.reject(error.response);
      }
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);


export default axiosInstance;
