import { useCallback, useState } from "react";

import { cloneReport } from "../../repository/report/cloneReport";

const useCloneReport = (onSuccess?: () => void, onError?: () => void) => {
  const [loading, setLoading] = useState(false);
  const postCloneReport = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await cloneReport(id);
        onSuccess?.();
      } catch (e) {
        console.log(e);
        onError?.();
      } finally {
        setLoading(false);
      }
    },
    [onError, onSuccess]
  );
  return {
    postCloneReport,
    loading,
  };
};

export default useCloneReport;
