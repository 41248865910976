import {
  IReportField,
  ReportFieldByIdParameter,
} from "v2/core/repository/report/types";
import { useCallback, useEffect, useState } from "react";

import { getReportFieldByIdFromRepo } from "v2/core/repository/report/getReportFieldByIdFromRepo";

const useGetReportFieldById = (id: string, lazy = false) => {
  const [fieldData, setFieldData] = useState<IReportField>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getReportFieldById = useCallback(async () => {
    setLoading(true);
    const params: ReportFieldByIdParameter = {
      columnId: id,
    };
    try {
      const response = await getReportFieldByIdFromRepo(params);
      const fieldData = response as unknown as IReportField;
      setFieldData(fieldData);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!lazy) getReportFieldById();
  }, [getReportFieldById, lazy]);

  return {
    loading,
    error,
    getReportFieldById,
    fieldData,
  };
};

export default useGetReportFieldById;
