import { CONDITION_TYPES, SECTION_TYPES } from "../../constant";
import React, { useCallback, useEffect, useState } from "react";
import {
  addSection,
  getSectionById,
  updateSection,
} from "../../services/toolsAndSettingsPagesApi";
import {
  permissionErrorCheck,
  renderFormatMsg,
  toastError,
  toastSuccess,
} from "../../shared/funs";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Error from "../Error/Error";
import { FieldArray } from 'react-final-form-arrays'
import { Form } from "react-final-form";
import InputField from "../Fields/InputField";
import PageHeading from "../PageHeading/PageHeading";
import SelectField from "../Fields/SelectField";
import arrayMutators from 'final-form-arrays'
import { get } from "lodash";
import useGetValidConditionalElements from "../../v2/core/services/forms/useGetValidConditionalElements";

const HandleSections = () => {

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isEdit = location.pathname.includes("Edit");
  const {
    validElements,
    loading,
    error: validElementsError,
  } = useGetValidConditionalElements(params.id, params.formId);

  const [variable, setVariable] = useState(null);
  const [error, setError] = useState(false);

  var heading = "EditSection";

  var values = {};
  if (isEdit) {
    values = {
      sectionId: get(variable, "sectionId", ""),
      formId: get(variable, "formId", ""),
      name: get(variable, "name", ""),
      title: get(variable, "title", ""),
      type: get(variable, "type", ""),
      
      conditionElement: get(variable, "conditionElement", ""),
      conditionType: get(variable, "conditionLogic.conditionType", ""),
      conditionValue: get(variable, "conditionValue", ""),
      sequence: get(variable, "sequence"),
      conditions:  [
        ...get(variable, "conditionLogic.conditions", [])
      ]
       
    };
  } else {
    heading = "AddSection";
    values = {
      name: "",
      type: "",
      title: "",
      conditionElement: "",
      conditionType: "",
      conditionValue: "",
      sequence: 0,
      conditions: [],
    };
  }

  useEffect(() => {
    if (isEdit) {
      getSectionById({ id: params.id })
        .then((res) => {
          setVariable(res);  
        })
        .catch((err) => {
          setVariable(null);
          setError(
            permissionErrorCheck(
              get(
                err,
                "data.value.errors.description",
                "Something went wrong, please try after some time!"
              )
            )
          );
        });
    }
  }, [isEdit, location.pathname, params.id]);

  const handleEditForm = async (data) => {
    let object = {
      sectionId: params.id,
      formId: params.formId,
      name: data.name,
      title: data.title,
      type: data.type,
      sequence: data.sequence,
    };

    if(data.conditions && data.conditions.length > 0) {
      object.conditionLogic.conditions = data.conditions;
    }
    if(data.conditionType && data.conditionType !== "") {
      object.conditionLogic.conditionType = data.conditionType;
    }

    updateSection(object)
      .then(() => {
        toastSuccess(
          renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated successfully")
        );
        history.push(`/sections/${params.formId}`);
      })
      .catch(() => {
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"));
      });
  };

  const handleAddForm = async (data) => {
    let object = {
      formId: params.formId,
      name: data.name,
      title: data.title,
      type: data.type,
      sequence: data.sequence,
     
    };
    if(data.conditions && data.conditions.length > 0) {
      object.conditionLogic.conditions = data.conditions;
    }
    if(data.conditionType && data.conditionType !== "") {
      object.conditionLogic.conditionType = data.conditionType;
    }
    addSection(object)
      .then(() => {
        toastSuccess(
          renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully")
        );
        history.push(`/sections/${params.formId}`);
      })
      .catch(() => {
        toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"));
      });
  };

  const validation = (data) => {
    let errors = {};
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.type || data.type === "") {
      errors.type = "Required";
    }
    if(data.conditions && data.conditions.length > 0) {
      data.conditions.forEach((condition, index) => {
        if (!condition?.element || condition?.element === "") {
          errors[`conditions[${index}].element`] = "Required";
        }
        if (!condition?.value || condition?.value === "") {
          errors[`conditions[${index}].value`] = "Required";
        }
      });
    }
    return errors;
  };

  const generateOptions = () => {
    let options = [<option value={``}> Select Type</option>];
    SECTION_TYPES.forEach(({ label, value }) => {
      options.push(<option value={value}>{label}</option>);
    });

    return options;
  };
  const generateTypeOptions = () => {
    let options = [<option value={``}> Select Type</option>];
    CONDITION_TYPES.forEach(({ label, value }) => {
      options.push(<option value={value}>{label}</option>);
    });

    return options;
  };
  

  const generateConditionalElements = useCallback(
    (conditions) => {
      if (!validElements.length || loading || validElementsError) {
        return [];
      }
      let options = [
        <option value={``} disabled>
          {" "}
          Select Element
        </option>,
      ];
    
      validElements.forEach(({ name, elementId }) => {
        
        options.push(<option  value={elementId}>{name}</option>);
      });
  
      return options;
    },  [loading, validElements, validElementsError]
  );
    
  return (
    <div className="main-content">
      <PageHeading headingId={heading} backURL={`/sections/${params.formId}`} />
      <div className="card p-3">
        {error ? (
          <Error msg={error} />
        ) : (
          <Form
            onSubmit={
              isEdit
                ? handleEditForm
                : handleAddForm
            }
            initialValues={values}
            validate={validation}
            mutators={{ ...arrayMutators }}

          >
            {({ handleSubmit, values, form: {
        mutators: { push } // injected from final-form-arrays above
      } }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form">
                    <div className="row">
                      <InputField
                        colClass="col-lg-6"
                        name={"name"}
                        type="text"
                        id="Name"
                        defaultLabel="Name"
                        placeholder={"Name"}
                      />
                      <InputField
                        colClass="col-lg-6"
                        name={"title"}
                        type="text"
                        id="Title"
                        defaultLabel="Title"
                        placeholder={"Title"}
                      />

                      <SelectField
                        colClass="col-lg-12"
                        name="type"
                        options={generateOptions()}
                        id="Type"
                        defaultLabel={"Type"}
                      />
                     {isEdit && <SelectField
                        colClass="col-lg-6"
                        name="conditionElement"
                        disabled={
                         true
                        }
                        options={generateConditionalElements}
                        id="conditionElement"
                        defaultLabel={"Condition Element (deprecated)"}
                        className={
                          validElements.length &&
                          !loading &&
                          !validElementsError
                            ? "form-control"
                            : "form-control read-only"
                        }
                      />}
                         {isEdit && <InputField
                        colClass="col-lg-6"
                        name={"conditionValue"}
                        type="text"
                        id="conditionValue"
                        defaultLabel="Condition Value (deprecated)"
                        placeholder={"Condition Value"}
                        disabled={true}
                        className={
                          values?.conditionElement === ""
                            ? "form-control read-only"
                            : "form-control"
                        }
                      />}
              
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="text-danger">
                        Note: Condition Element and Condition Value are deprecated. Please use the new mapper below. The condition element and condition value will be removed in the future. Default value for condition element and value is set as first entry in the mapper in Edit mode only.
                        </p>
                        </div>
                      </div>
                    <hr />
<div className="row">

<SelectField 
                      colClass="col-lg-12"
                      name="conditionType"
                      options={generateTypeOptions()}
                      id="conditionType"
                      defaultLabel="Condition Type"
                      className="form-control"
                      
                    />
</div>
                    {/* New Mapper */}
                    <FieldArray name="conditions"
                    >
                        {({ fields}) => fields.map((name, index) => {
                          return <div key={name} className="row align-items-center">
                            <SelectField
                              colClass="col-lg-5"
                              name={`${name}.element`}
                              disabled={!validElements.length || loading || validElementsError}
                              options={generateConditionalElements(values.conditions)}
                              id={`${name}.element`}
                              required={true}
                              defaultLabel={"Condition Element"}
                              className={validElements.length &&
                                !loading &&
                                !validElementsError

                                ? "form-control"
                                : "form-control read-only"} />

                            <InputField
                              colClass="col-lg-5"
                              name={`${name}.value`}
                              type="text"
                              id={`${name}.value`}
                              required={true}
                              defaultLabel="Condition Value"
                              placeholder={"Condition Value"}
                              disabled={values?.conditions[index]?.element === ""}
                              className={values?.conditions[index]?.element === ""
                                ? "form-control read-only"
                                : "form-control"} />
                            <div className="col-md-2">
                              <button type="button" onClick={() => fields.remove(
                                index
                              )} className="btn btn-danger mt-3" >
                                Remove
                              </button>
                            </div>

                          

                          </div>;
                        })
                        }
                      
                    </FieldArray>
                    <div className="row">
                      <div className="col-12 ">
                        <div className="submit_btn">
                          <button type="button" onClick={
                            () => push('conditions', { element: '', value: '' })
                          } className="btn btn-secondary">
                              Add condition
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>            
                  <div className="col-12 text-right">
                    <div className="submit_btn">
                      <button type="submit" className="btn btn-primary">
                        {isEdit
                          ? renderFormatMsg("Save", "Save")
                          : renderFormatMsg("Add", "Add")}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        )}
      </div>
    </div>
  );
};

export default React.memo(HandleSections);
