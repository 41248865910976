import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react'
import { checkPermission, findPermission, permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import { AppContext } from '../../context/AppContext';
import { Button } from 'react-bootstrap';
import Error from "../../components/Error/Error";
import LinkButton from '../../components/Buttons/LinkButton';
import { PAGE_SIZE } from '../../constant';
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { debounce } from "@material-ui/core";
import { get } from 'lodash';
import { getReportDefinition } from '../../services/toolsAndSettingsPagesApi';
import queryString from "query-string";
import useCloneReport from '../../v2/core/services/report/useCloneReport';

const ReportDefinition = () => {
  const location = useLocation()
  const history = useHistory();
  const [reportDefinition, setReportDefinition] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  let dataArray = get(userDetails, "accesses")
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("CREATE_EDIT_REPORT_DEFINITIONS").screenId))
  }, [dataArray])
  
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (Object.keys(parsed).length > 0) {
      setSearchValue(parsed.search);
    }
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchValue) {
      params.append("search", searchValue);
    } else {
      params.delete("search");
    }
    history.push({ search: params.toString() });
  }, [history, searchValue]);
  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = {
      page: page + 1,
      display: PAGE_SIZE,
      search
    }
    getReportDefinition(param).then(res => {
      setLoading(false)
      setReportDefinition(res.report)
      setTotalPage(res.totalPage)
    }).catch(err => {
      setError(permissionErrorCheck("Something went wrong! please try after some time!"))
      setLoading(false)
      setReportDefinition([])
    })
  }

  const onSuccess = () => {
    toastSuccess("Report Clone Successful")
    getData({
      page: 0,
      search: ""
    })
  }
  const onError = () => {
    toastError("Report Clone Failed")
  }
  const {postCloneReport, loading} = useCloneReport(onSuccess, onError)




  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
      accessor: 'name',
      minWidth: 100,
      maxWidth: 200,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Scope", "Scope")}</div>,
      accessor: 'scope',
      minWidth: 80,
      maxWidth: 180,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Recipient", "Recipient")}</div>,
      accessor: 'recipient',
      minWidth: 200,
      maxWidth: 340,
      resizable: false,
      style: { justifyContent: "flex-start" },
    },
  
    {
      Header: renderFormatMsg("Details", "Details"),
      resizable: false,
      width: 150,
      Cell: props => <Link to={`/report-definition/detail/Edit/${props.original.reportId}?search=${searchValue}`} className="edit-icon">{renderFormatMsg("Details", "Details")}</Link>
    },
   
  ]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      resizable: false,
      width: 150,
      Cell: props => <Link to={`/report-definition/Edit/${props.original.reportId}?search=${searchValue}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }

  columns.push( {
    Header: renderFormatMsg("Clone", "Clone"),
    resizable: true,
    Cell: (props) => (
      <Button
        disabled={loading}
        onClick={() => {
          postCloneReport(props.original.reportId);
        }}
      >
        Duplicate Report
      </Button>
    ),  })

  const fetchData = (state) => {
    getData({ page: state.page, search: state.search })
    setPageIndex(state.page)
  }
  const delayedQuery = debounce((state) => fetchData(state), 1000);

  const searchHandler = (e) => {
    setPageIndex(0);
    delayedQuery({ page: 0, search: e.target.value });
  };

  return (
    <div className="main-content">
    
      <PageHeading headingId={"ReportDefinition"} isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={searchHandler} value={parsed.search} />
              {pageReadOnly.write && <LinkButton classes={"ml-auto"} to={`/report-definition/Add?search=${searchValue}`} id="AddReportDefinition" defaultText="Add Constant" />}
            </div>
            <ReactTable data={reportDefinition} 
            columns={columns} isLoading={isLoading}   
            onFetchData={(state) =>
                fetchData({ ...state, search: parsed.search })
              } pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
    </div>
  )
}
export default React.memo(ReportDefinition)
