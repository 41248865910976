import React, { useEffect, useState } from 'react'
import { addReportField, getReportFieldById, updateReportField } from '../../services/toolsAndSettingsPagesApi';
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import Error from '../../components/Error/Error';
import { FORMAT_OPTIONS } from '../../constant';
import { Form } from 'react-final-form';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import SelectField from '../../components/Fields/SelectField';
import { get } from "lodash";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const ReportFields = (props) => {
  const [variable, setVariable] = useState(null)
  const [error, setError] = useState(false)
  const location = useLocation()
  const parsed = queryString.parse(location.search)

  var heading = "EditReportFields"

  var values = {};
  if (props.location.pathname.includes("Edit")) {
    values = {
      name: get(variable, "name", ""),
      title: get(variable, "title", ""),
      path: get(variable, "path", ""),
      format: get(variable, "format", ""),
    }
  } else {
    heading = "AddReportFields"
    values = {
      name: "",
      title: "",
      path: "",
      format: ""
    }
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getReportFieldById({ fieldId: props.match.params.id }).then(res => {
        setVariable(res)
      }).catch(err => {
        setVariable(null)
        setError(permissionErrorCheck(get(err, "data.value.errors.description", "Something went wrong, please try after some time!")))
      })
    }
  }, [props.location.pathname, props.match.params.id])

  const handleEditForm = async (data) => {

    let object = {
      "fieldId": props.match.params.id,
      "name": data.name,
      "title": data.title,
      "path": data.path,
      "format": data.format,
    }
    console.info('object => ', object);

    updateReportField(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/report-fields")
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })

  }


  const handleAddForm = async (data) => {
    let object = {
      "name": data.name,
      "title": data.title,
      "path": data.path,
      "format": data.format,
    }
    console.info('object => ', object);

    addReportField(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added successfully"))
      props.history.push("/report-fields")
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }

  const validation = (data) => {
    let errors = {}
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.path || data.path === "") {
      errors.path = "Required";
    }
    if (!data.title || data.title === "") {
      errors.title = "Required";
    }
    if (!data.format || data.format === "") {
      errors.format = "Required";
    }

    return errors
  }


  const generateOptions = (options) => {
    var _options = [<option option value={""} > Select Format</option>]
    options.map(({ value, label }, index) =>
      _options.push(<option value={value}>{label}</option>)
    )

    return _options;
  }

  return (
    <div className="main-content">
    
      <PageHeading headingId={heading} backURL={`/report-fields${
        parsed.search ? `?search=${parsed.search}` : ""
      }`} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Edit") ? handleEditForm : handleAddForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                  
                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="Name"
                      defaultLabel="Name"
                      placeholder={"Name"}
                    />
                  </div>
                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"path"}
                      type="text"
                      id="Path"
                      defaultLabel="Path"
                      placeholder={"Path"}
                    />
                  </div>
                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"title"}
                      type="text"
                      id="Title"
                      defaultLabel="Title"
                      placeholder={"Title"}
                    />
                  </div>
                  <div className="row">
                    <SelectField
                      colClass="col-lg-6"
                      name="format"
                      options={generateOptions(FORMAT_OPTIONS)}
                      id="Format"
                      defaultLabel={"Format"}
                    />
                  
                  </div>
                </div>

                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(ReportFields)
