export const HOST_API = window.origin.includes("http://localhost")
  ? process.env.REACT_APP_HOST_API
  : window.origin;

export const NOTIFICATIONS_INTERVAL = 600000;
export const PAGE_SIZE = 10;
export const DATE_FORMAT = "YYYY-M-D";
export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const DEFAULT_DATE_FORMAT = "D.M.YYYY";
export const SEVERITY_SELECT_OPTION = [
  {
    id: "notificationTable.SeverityHigh",
    label: "High",
    value: 1,
  },
  {
    id: "notificationTable.SeverityMedium",
    label: "Medium",
    value: 2,
  },
  {
    id: "notificationTable.SeverityLow",
    label: "Low",
    value: 3,
  },
  {
    id: "notificationTable.SeverityIgnore",
    label: "Ignore",
    value: 4,
  },
];

export const SHORT_DATE_FORMAT_OPTIONS = [
  {
    label: "DD.MM.YYYY",
    value: "DD.MM.YYYY",
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
];

export const COMPLAINT_SECTION = "ComplaintSection";
export const REDIAL_SECTION = "RedialSection";
export const SAVE_BUTTON_SECTION = "SaveButtonSection";
export const SHIFT_PLANNING_SECTION = "ShiftPlanningSection";
export const BUTTON_SECTION = "ButtonSection";
export const TWO_COLUMN_SECTION = "TwoColumnSection";
export const HTML_SECTION = "HTMLSection";

export const SECTION_TYPES = [
  { label: "Complaint Section", value: COMPLAINT_SECTION },
  { label: "Redial Section", value: REDIAL_SECTION },
  { label: "Save Button Section", value: SAVE_BUTTON_SECTION },
  { label: "Shift Planning Section", value: SHIFT_PLANNING_SECTION },
  { label: "Button Section", value: BUTTON_SECTION },
  { label: "Two Column Section", value: TWO_COLUMN_SECTION },
  { label: "HTML Section", value: HTML_SECTION },
];

export const CONDITION_TYPES = [
  {
    label: "AND",
    value: "and",
  },
  {
    label: "OR",
    value: "or",
  },
];

export const REDIAL_BUTTON = "RedialButton";
export const WRONG_NUMBER_BUTTON = "WrongNumberButton";
export const TWO_COLUMN_TEXT_ROW = "TwoColumnTextRow";
export const TWO_COLUMN_CHECKBOX_ROW = "TwoColumnCheckBoxRow";
export const TWO_COLUMN_RADIO_BUTTON_ROW = "TwoColumnRadioButtonRow";
export const TWO_COLUMN_EMAIL_ROW = "TwoColumnEmailRow";
export const TWO_COLUMN_PHONE_ROW = "TwoColumnPhoneRow";
export const TWO_COLUMN_NUMBER_ROW = "TwoColumnNumberRow";
export const TWO_COLUMN_DATE_ROW = "TwoColumnDateRow";
export const TWO_COLUMN_DROPDOWN_ROW = "TwoColumnDropDownRow";
export const TWO_COLUMN_HYPERLINK = "TwoColumnHyperlink";
export const HTML_ELEMENT = "HTMLElement";
export const SAVE_BUTTON_LINE = "SaveButtonLine";

export const ELEMENT_TYPES = [
  { label: "Redial Button", value: "RedialButton" },
  { label: "Wrong Number", value: "WrongNumberButton" },
  { label: "Two Column Text Row", value: "TwoColumnTextRow" },
  { label: "Two Column Checkbox Row", value: "TwoColumnCheckBoxRow" },
  { label: "Two Column Radio Button Row", value: "TwoColumnRadioButtonRow" },
  { label: "Two Column Email Row", value: "TwoColumnEmailRow" },
  { label: "Two Column Phone Row", value: "TwoColumnPhoneRow" },
  { label: "Two Column Number Row", value: "TwoColumnNumberRow" },
  { label: "Two Column Date Row", value: "TwoColumnDateRow" },
  { label: "Two Column DropDown Row", value: "TwoColumnDropDownRow" },
  { label: "Two Column Hyperlink", value: "TwoColumnHyperlink" },
  { label: "HTML Element", value: "HTMLElement" },
  { label: "Two Column Action Dropdown", value: "TwoColumnActionDropDown" },
  { label: "Static Text", value: "StaticText" },
  { label: "Save Button Line", value: "SaveButtonLine" },
];

export const LONG_DATE_FORMAT_OPTIONS = [
  {
    label: "dd.MM.YYYY HH:mm am/pm",
    value: "DD.MM.YYYY HH:mm a",
  },
  {
    label: "MM/DD/YYYY HH:mm am/pm",
    value: "MM/DD/YYYY HH:mm a",
  },
  {
    label: "DD.MM.YYYY HH:mm:ss",
    value: "DD.MM.YYYY HH:mm:ss",
  },
];

export const FORMAT_OPTIONS = [
  {
    label: "String",
    value: "string",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Time",
    value: "time",
  },
  {
    label: "Integer",
    value: "integer",
  },
];

export const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "Russian",
    value: "ru",
  },
];

export const SCOPE_OPTIONS = [
  {
    label: "Promotion Type",
    value: "Promotion Type",
  },
  {
    label: "Promotion Group",
    value: "Promotion Group",
  },
];

export const TARGET_OPTIONS = [
  {
    label: "Manual",
    value: "Manual",
  },
  {
    label: "Corezoid",
    value: "Corezoid",
  },
  {
    label: "SendBox",
    value: "SendBox",
  },
];

export const WARNING_OPTIONS = [
  {
    label: "--",
    value: "",
  },
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const ERROR_OPTIONS = [
  {
    label: "--",
    value: "",
  },
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const REPORT_LOGIC_OPTIONS = [
  {
    label: "Manual",
    value: "Manual",
  },
  {
    label: "AfterInactivity",
    value: "AfterInactivity",
  },
  {
    label: "OnTime",
    value: "OnTime",
  },
  {
    label: "AfterCallableFinished",
    value: "AfterCallableFinished",
  },
];

export const PUSH_LOGIC_OPTIONS = [
  {
    label: "Manual",
    value: "Manual",
  },
  {
    label: "AfterReceiving",
    value: "AfterReceiving",
  },
  {
    label: "OnTime",
    value: "OnTime",
  },
  {
    label: "AfterConfirmation",
    value: "AfterConfirmation",
  },
];
export const CLASSIFICATION_OPTIONS = [
  {
    label: "Form Input",
    value: "Form Input",
  },
  {
    label: "Test",
    value: "Test",
  },
  {
    label: "Exa",
    value: "Exa",
  },
  {
    label: "Blacklist",
    value: "Blacklist",
  },
  {
    label: "Custom",
    value: "Custom",
  },
];

export const PAGE_ACCESS_FUNCTION = {
  USER: "52322d02-68b5-4934-9ee2-007236411704",
  PROMOTION: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
  USER_ROLES: "bb684e41-a280-4c21-a753-236a7efa96f6",
  PROMOTIONS: "6d66af5f-6a97-45eb-9a98-d29aa2c7c17d",
  CLIENT_PAGE: "e4ed8613-be81-4c0d-b768-39fd3a7ea327",
  PROMOTION_TYPES: "eb50a708-1403-4da2-b75e-8dd27675725b",
  AASIGN_MASTER_USER_FLAGE: "eb50a708-1403-4aa2-b75e-8dd27675725b",
  ASSIGN_MASTER_USER_FLAG: "064ade0a-8831-4216-96ba-cd74b88d8b00",
  CREATE_EDIT_CLIENT: "71fa1a81-094b-4f50-b62f-cfab28296f5d",
  CREATE_EDIT_USERS: "617aae35-67b0-4afd-8090-77f9c733d7b2",
  CREATE_EDITROLES: "9bef799f-21fc-40f9-8588-1c63ca42c810",
  CREATE_EDIT_PROMOTIONS: "7a43a78e-a738-4b0b-bb37-662b4bc46b70",
  CREATE_EDIT_CALLTASK: "9b8b5f0c-8ed0-4b2a-9994-29e97ce17064",
  CREATE_EDIT_CONSTANT: "62885ef1-3f2f-4ee5-90ce-f1d13261ae81",
  CREATE_EDIT_PROMOTIONTYPE: "3e7eb38d-4b9a-4973-a954-9bd367ea9f46",
  EDIT_PROMOTION: "692c0c16-8d80-43a8-a15d-9a7496507a7d",
  OVERVIEW: "47021746-63fa-4b3a-a798-e92d065afffb",
  REVIEWS: "051506fa-8a45-4636-a04f-0e84e6ac0868",
  OPENRECRUITINGTASKS: "051506fa-8a45-4636-a04f-0e84e6ac0868",
  PROMOTION_PUSH_TO_DIALER: "6de08754-b8ab-4a4d-8904-3fd2ebb32b8b",
};

export const SIDEBAR_MENU_ACCESS_FUNCTION = {
  OVERVIEW: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
  CALL: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
  REVIEWS: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
  OPENRECRUITINGTASKS: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
};

export const PERMISSION_LEVEL_READ_ONLY = "RO";
export const PERMISSION_LEVEL_READ_WRITE = "RW";

export const ZONES = [
  { label: "Арбатская", value: "Арбатская" },
  { label: "Баррикадная", value: "Баррикадная" },
  { label: "Бауманская", value: "Бауманская" },
  { label: "Грузинский вал", value: "Грузинский вал" },
  { label: "Деловой центр - Афимол", value: "Деловой центр - Афимол" },
  { label: "Киевская", value: "Киевская" },
  { label: "Китай-город/Лубянка", value: "Китай-город/Лубянка" },
  { label: "Комсомольский проспект", value: "Комсомольский проспект" },
  { label: "Краснопрудная", value: "Краснопрудная" },
  { label: "Курская", value: "Курская" },
  { label: "Ленинский проспект", value: "Ленинский проспект" },
  { label: "Лесная", value: "Лесная" },
  { label: "Новокузнецкая", value: "Новокузнецкая" },
  { label: "м. Новослободская", value: "м. Новослободская" },
  { label: "Октябрьская", value: "Октябрьская" },
  { label: "Павелецкая", value: "Павелецкая" },
  { label: "Парк культуры", value: "Парк культуры" },
  { label: "Проспект мира", value: "Проспект мира" },
  { label: "Рижская", value: "Рижская" },
  { label: "Римская/Площадь Ильича", value: "Римская/Площадь Ильича" },
  { label: "Серпуховская", value: "Серпуховская" },
  { label: "Смоленская", value: "Смоленская" },
  { label: "Сухаревская", value: "Сухаревская" },
  { label: "Таганская", value: "Таганская" },
  { label: "Тверская", value: "Тверская" },
  { label: "Улица 1905 года", value: "Улица 1905 года" },
  { label: "Цветной бульвар", value: "Цветной бульвар" },
  { label: "Чистые пруды", value: "Чистые пруды" },
];

export const SCREEN_ACCESS_RIGHT = [
  {
    screenId: "62885ef1-3f2f-4ee5-90ce-f1d13261ae81",
    name: "CREATE_EDIT_CONSTANTS",
    icon: "",
    screenName: "Create & Edit Constants",
  },
  {
    screenId: "3e7eb38d-4b9a-4973-a954-9bd367ea9f46",
    name: "CREATE_EDIT_PROMOTION_TYPES",
    icon: "",
    screenName: "Create & Edit PromotionTypes",
  },
  {
    screenId: "692c0c16-8d80-43a8-a15d-9a7496507a7d",
    name: "EDIT_PROMOTION",
    icon: "",
    screenName: "Edit Promotion",
  },
  {
    screenId: "47021746-63fa-4b3a-a798-e92d065afffb",
    name: "OVERVIEW",
    icon: "",
    screenName: "Overview",
  },
  {
    screenId: "051506fa-8a45-4636-a04f-0e84e6ac0868",
    name: "REVIEWS",
    icon: "",
    screenName: "Reviews",
  },
  {
    screenId: "0aa18c52-edd3-4e83-ad66-044249e105a1",
    name: "OPEN_RECRUITING_TASKS",
    icon: "",
    screenName: "Open Recruiting Tasks",
  },
  {
    screenId: "6de08754-b8ab-4a4d-8904-3fd2ebb32b8b",
    name: "PROMOTION_PUSH_TO_DIALER",
    icon: "",
    screenName: "Promotion PushToDialer",
  },
  {
    screenId: "064ade0a-8831-4216-96ba-cd74b88d8b00",
    name: "ASSIGN_MASTER_USER_FLAG",
    icon: "",
    screenName: "Assign Master User Flag",
  },
  {
    screenId: "71fa1a81-094b-4f50-b62f-cfab28296f5d", //av
    name: "CREATE_EDIT_CLIENT",
    icon: "",
    screenName: "Create & Edit Client",
  },
  {
    screenId: "617aae35-67b0-4afd-8090-77f9c733d7b2", //av
    name: "CREATE_EDIT_USERS",
    icon: "",
    screenName: "Create & Edit Users",
  },
  {
    screenId: "9bef799f-21fc-40f9-8588-1c63ca42c810", //av
    name: "CREATE_EDIT_ROLES",
    icon: "",
    screenName: "Create & Edit Roles",
  },
  {
    screenId: "196db3a2-2c80-40ee-95f4-826109f6ca50",
    name: "VIEW_OVERVIEW",
    icon: "",
    screenName: "View Overview",
  },
  {
    screenId: "7a43a78e-a738-4b0b-bb37-662b4bc46b70",
    name: "CREATE_EDIT_PROMOTIONS",
    icon: "",
    screenName: "Create & Edit Promotions",
  },
  {
    screenId: "9b8b5f0c-8ed0-4b2a-9994-29e97ce17064",
    name: "CREATE_EDIT_CALLTASK",
    icon: "",
    screenName: "Create & Edit CallTask",
  },
  {
    screenId: "59efab4d-5979-45f8-a71e-17c5b7baaee2",
    name: "CREATE_EDIT_PROMOTION_GROUP",
    icon: "",
    screenName: "Create & Edit PromotionGroup",
  },
  {
    screenId: "d4d06910-12c3-4a46-b2c5-21b6ab62ea6a",
    name: "CREATE_EDIT_REPORT_FIELDS",
    icon: "",
    screenName: "Create & Edit ReportFields",
  },
  {
    screenId: "2d2c91f3-e6dd-48cb-abc1-98e88d7f04cd",
    name: "CREATE_EDIT_REPORT_DEFINITIONS",
    icon: "",
    screenName: "Create & Edit ReportDefinitions",
  },
  {
    screenId: "5de55c07-9679-4387-968b-29c9875916dd",
    name: "CREATE_EDIT_FORMS",
    icon: "",
    screenName: "Create & Edit Forms",
  },
];
