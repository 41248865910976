import Calendar from '../Calendar'
import { Field } from 'react-final-form';
import FieldErrorMessage from '../FieldErrorMessage';
import React from 'react'
import { renderFormatMsg } from '../../shared/funs';

/**
 * 
 * @param {object} functional component for show active and end date
 * @returns 
 */
const ActiveFromAndToDate = ({ readOnly = false, className = '' }) => {
  return (
    <div className="row">
      <Field name="activeFrom">
        {({ input, meta }) => (
          <div className="frm_grp form-group" style={{ flex: "0 0 20%" }}>
            <label>{renderFormatMsg("ActiveFrom", "ActiveFrom")}</label>
            <Calendar className={readOnly && `read-only ${className}`}  disabled={readOnly} placeholderText="Active from" onSelect={input.onChange} name={input.name} selectedDate={input.value} />
            <FieldErrorMessage meta={meta} />
          </div>
        )}
      </Field>
      <Field name="activeTo">
        {({ input, meta }) => (
          <div className="frm_grp form-group" style={{ flex: "0 0 20%" }}>
            <label> {renderFormatMsg("ActiveTo", "activeTo")} </label>
            <Calendar className={className} placeholderText="Active to" onSelect={input.onChange} name={input.name} selectedDate={input.value} />
            <FieldErrorMessage meta={meta} />
          </div>
        )}
      </Field>
    </div>
  )
}

export default ActiveFromAndToDate
