export const PROMOTION = {
  getPromotionById: "api/v1/Promotion/GetPromotion",
  getPromotionList: "api/v1/Promotion/GetList",
  putPromotion: "api/v1/Promotion/Promotion",
  resetPromotion: "api/v1/Promotion/ResetPromotion",
  finalizePromotion: "api/v1/Promotion/PromotionFinalize",
  resendReport: "api/v1/Promotion/ResendReport",
  pausePromotion: "api/v1/Promotion/PromotionPause",
  isPromotionBucketEmpty: "api/v1/Promotion/IsBucketEmpty",
  deletePromotion: "api/v1/Promotion/DeletePromotion",
  deletePromotionBucket: "api/v1/Promotion/DeleteBucket",
  deletePromotionLead: "api/v1/Promotion/DeleteLead",
  getPromotionInfo: "api/v1/Promotion/GetInfo",
  confirmPromotion: "api/v1/Promotion/ConfirmPromotion",
};
