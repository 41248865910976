import { DATE_FORMAT, PAGE_SIZE } from '../../../constant';
import React, { useEffect, useState } from 'react'
import { addNewUser, getUserData, updateUser } from '../../../services/toolsAndSettingsPagesApi';
import { renderFormatMsg, toastError, toastSuccess } from '../../../shared/funs';

import ActiveFromAndToDate from '../../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import Error from '../../../components/Error/Error';
import { Form } from 'react-final-form';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { get } from "lodash"
import moment from 'moment';

const HandleUser = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(false)

  let heading = "AddNewUser"
  var values = {
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    activeFrom: "",
    activeTo: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditUser"
    values = {
      userName: get(user, "userName"),
      name: get(user, "name"),
      firstName: get(user, "firstName"),
      lastName: get(user, "lastName"),
      email: get(user, "email"),
      mobileNumber: get(user, "mobileNumber"),
      activeFrom: get(user, "activeFrom"),
      activeTo: get(user, "activeTo")
    }
  }


  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      let param = { "page": 1, "display": PAGE_SIZE, "search": props.match.params.userId }
      getUserData(param).then(res => {
        setUser(res.users[0])
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        setError(err)
      })
    }
  }, [props.location.pathname, props.match.params.userId])


  const validation = (data) => {
    let errors = {}

    if (!data.userName || data.userName === "") {
      errors.userName = "Required";
    }
    if (!data.firstName || data.firstName === "") {
      errors.firstName = "Required";
    }
    if (!data.lastName || data.lastName === "") {
      errors.lastName = "Required";
    }
    if (!data.email || data.email === "") {
      errors.email = "Required";
    }
    if (!data.mobileNumber || data.mobileNumber === "") {
      errors.mobileNumber = "Required";
    }

    if (!data.activeFrom || data.activeFrom === "") {
      errors.activeFrom = "Required";
    }
    if (!data.activeTo || data.activeTo === "") {
      errors.activeTo = "Required";
    }
    return errors
  }

  const handleForm = async (data) => {

    let object = {
      "userName": get(data, "userName"),
      "firstName": get(data, "firstName"),
      "lastName": get(data, "lastName"),
      "email": get(data, "email"),
      "mobileNumber": get(data, "mobileNumber"),
      "activeFrom": moment(get(data, "activeFrom")).format(DATE_FORMAT),
      "activeTo": moment(get(data, "activeTo")).format(DATE_FORMAT)
    }
    if (props.location.pathname.includes("Add")) {
      await addNewUser(object).then(resp => {
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/user")
      }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
    } else {
      object.userId = props.match.params.userId
      await updateUser(object).then(resp => {
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        props.history.push("/user")
      }).catch(err => { toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")) })
    }
  }
  return (
    <div className="main-content">
   
      <PageHeading headingId={heading} backURL={"/user"} isLoading={isLoading} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"userName"}
                      type="text"
                      id="UserName"
                      defaultLabel="User Name"
                      placeholder={"User Name"}
                    />
                    <div className="col-lg-6"> </div>
                  </div>
                  <div className="row">
                    <InputField
                      colClass="col-lg-6"
                      name={"firstName"}
                      type="text"
                      id="firstName"
                      defaultLabel="First Name"
                      placeholder={"First Name"}
                    />
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"lastName"}
                      type="text"
                      id="LastName"
                      defaultLabel="Last Name"
                      placeholder={"Last Name"}
                    />
                  </div>
                  <div className="row">
                   
                    <InputField
                      colClass="col-lg-6"
                      name={"email"}
                      type="text"
                      id="Email"
                      defaultLabel="Email"
                      placeholder={"Email"}
                    />
                  
                    <InputField
                      colClass="col-lg-6"
                      name={"mobileNumber"}
                      type="text"
                      id="MobilePhone"
                      defaultLabel="Script Title"
                      placeholder={"Mobile phone"}
                    />
                    <div className="col-lg-6"></div>
                  </div>
                </div>
                <ActiveFromAndToDate />
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                      ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}
export default React.memo(HandleUser)
