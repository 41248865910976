import { deleted, get, post, put } from "./index";

//User Page APIs
export const getUserData = async (params) => {
  return await get(`api/v1/Admin/users`, params);
};

export const getReportDefinitionById = async (params) => {
  return await get(`api/v1/Report/GetReport`, params);
};

export const getButtonStructureFieldsById = async (params) => {
  return await get(`api/v1/Report/GetReportFieldList`, params);
};

export const getPromotionGroupData = async (params) => {
  return await get(`api/v1/PromotionGroup/GetList`, params);
};

export const getPromotionGroupLinkedPromotion = async (params) => {
  return await get(`api/v1/PromotionGroup/LinkedPromotions`, params);
};

//get user's roles
export const getUserRoles = async (params) => {
  return await get(`api/v1/admin/userroles`, params);
};

export const addNewUser = async (data) => {
  return await post(`api/v1/Admin/AddUser`, data);
};

export const updateUser = async (updatingData) => {
  return await put(`api/v1/Admin/UpdateUser`, updatingData);
};
//Roles page APIs
export const getClientRolesData = async (params) => {
  return await get(`api/v1/client/ClientRoles`, params);
};

export const getScreenList = async (params) => {
  return await get(`api/v1/Admin/ScreenMaster`, params);
};

export const updateUserRoles = async (updatingData) => {
  return await put(
    `api/v1/Client/UpdateClientRoleScreenPermission`,
    updatingData
  );
};
export const addUserRoles = async (addingData) => {
  return await post(`api/v1/Client/AddClientRoleScreenPermission`, addingData);
};

export const addPromotionGroup = async (data) => {
  return await post(`api/v1/PromotionGroup/AddPromotionGroup`, data);
};

export const updatePromotionGroup = async (data) => {
  return await put(`api/v1/PromotionGroup/UpdatePromotionGroup`, data);
};

// export const updateUserRoles = async (updatingData) => {
//     return await put(`api/v1/Client/UpdateClientRole`, updatingData)
// };
// export const addUserRoles = async (addingData) => {
//     return await post(`api/v1/Client/AddClientRole`, addingData);
// };

export const deleteUserRoles = async (id) => {
  return await deleted(`api/v1/Client/clientRole/${id}`);
};

//client page APIs
export const getClientData = async (params) => {
  return await get(`api/v1/Client/clients`, params);
};

export const addNewClient = async (updatingData) => {
  return await post(`api/v1/Client/AddClient`, updatingData);
};

export const updateClientData = async (data) => {
  return await put(`api/v1/Client/UpdateClient`, data);
};

//Warehouse page APIs
export const getWarehouseData = async (params) => {
  return await get(`api/v1/Warehouse/warehouses`, params);
};

export const updateWarehouseData = async (data) => {
  return await put(`api/v1/Warehouse/UpdateWarehouse`, data);
};
export const AddNewWarehouse = async (updatingData) => {
  return await post(`api/v1/Warehouse/AddWarehouse`, updatingData);
};
//userRoleAssign Page
export const getUserRoleAssignData = async (params) => {
  return await get(`api/v1/client/clientRoles`, params);
};
export const updateUserRoleAssignment = async (updatingData) => {
  return await put(`api/v1/Admin/UpdateUserRole`, updatingData);
};

//Promotion Type API
export const getPromotionType = async (params) => {
  return await get(`api/v1/PromotionType/GetList`, params);
};

export const getPromotions = async (params) => {
  return await get(`api/v1/Promotion/GetList`, params);
};

export const getPromotionDetails = async (params) => {
  return await get(`api/v1/CallTask/GetCallTaskListByPromo`, params);
};

export const getPromotionDetailsPhone = async (params) => {
  return await get(`api/v1/CallTask/GetCallTaskInfo`, params);
};

export const getPromotion = async (params) => {
  return await get(`api/v1/Promotion/GetPromotion`, params);
};

export const getPromotionTypeById = async (params) => {
  return await get(`api/v1/PromotionType/GetPromotionType`, params);
};

export const updateConstant = async (data) => {
  return await put(`api/v1/Constant/UpdateConstant`, data);
};

export const getConstant = async (data) => {
  return await get(`api/v1/Constant/GetConstant`, data);
};

export const getConstantById = async (data) => {
  return await get(`api/v1/Constant/GetConstant`, data);
};

export const getReportFieldById = async (data) => {
  return await get(`api/v1/ReportField/GetReportField`, data);
};

export const getFormById = async (data) => {
  return await get(`api/v1/Forms/Form`, data);
};

export const getSectionById = async (data) => {
  return await get(`api/v1/Forms/Section`, data);
};

export const getElementById = async (data) => {
  return await get(`api/v1/Forms/Element`, data);
};

export const updatePromotionType = async (data) => {
  return await put(`api/v1/PromotionType/UpdatePromotionType`, data);
};

export const addPromotionType = async (data) => {
  return await post(`api/v1/PromotionType/AddPromotionType`, data);
};

export const createPromotionType = async (id, data, onProgress) => {
  return await post(
    `api/v1/PromotionType/CreatePromotion/${id}`,
    data,
    onProgress
  );
};

export const updatePromotionStatus = async (data) => {
  return await post(`api/v1/Promotion/PromotionPause`, data);
};

export const deletePromotionType = async (key, id) => {
  return await deleted(
    `api/v1/PromotionType/DeleteReportFromPromotionType?${key}=${id}`
  );
};

export const addConstant = async (data) => {
  return await post(`api/v1/Constant/AddConstant`, data);
};

export const addForm = async (data) => {
  return await put(`api/v1/Forms/Form`, data);
};

export const addSection = async (data) => {
  return await put(`api/v1/Forms/Section`, data);
};

export const addElement = async (data) => {
  return await put(`api/v1/Forms/Element`, data);
};

export const addReportDefinition = async (data) => {
  return await post(`api/v1/Report/AddReport`, data);
};

export const updateReportDefination = async (data) => {
  return await put(`api/v1/Report/UpdateReport`, data);
};

export const deleteFieldFromReport = async ({ key, id }) => {
  return await deleted(`api/v1/Report/DeleteFieldFromReport?${key}=${id}`);
};

export const deleteSections = async ({ key, id }) => {
  return await deleted(`api/v1/Forms/Section?${key}=${id}`);
};

export const deleteElements = async ({ key, id }) => {
  return await deleted(`api/v1/Forms/Element?${key}=${id}`);
};

export const addReportField = async (data) => {
  return await post(`api/v1/ReportField/AddReportField`, data);
};

export const updateReportField = async (data) => {
  return await put(`api/v1/ReportField/UpdateReportField`, data);
};

export const updateForm = async (data) => {
  return await post(`api/v1/Forms/Form`, data);
};

export const updateSection = async (data) => {
  return await post(`api/v1/Forms/Section`, data);
};

export const updateElement = async (data) => {
  return await post(`api/v1/Forms/Element`, data);
};

export const updateReportFieldOrder = async (data) => {
  return await put(`api/v1/Report/SetSequence`, data);
};

export const updateSectionOrder = async (data) => {
  return await post(`api/v1/Forms/SetSectionsSequence`, data);
};

export const updateElementOrder = async (data) => {
  return await post(`api/v1/Forms/SetElementsSequence`, data);
};

export const addFieldToReport = async (updatingData) => {
  return await post(`api/v1/Report/AddFieldToReport`, updatingData);
};

export const setPushToDialer = async (id) => {
  return await post(`api/v1/CallTask/PushToDialer?promotionid=${id}`);
};

export const resetPromotionStatus = async (id) => {
  return await put(`api/v1/Promotion/ResetPromotion?promotionId=${id}`);
};

export const setFinish = async (id) => {
  return await post(`api/v1/Promotion/PromotionFinalize?promotionId=${id}`);
};

export const removePromotion = async (id) => {
  return await deleted(`api/v1/Promotion/DeletePromotion?promotionId=${id}`);
};
export const removePromotionBucket = async (id) => {
  return await deleted(`api/v1/Promotion/DeleteBucket?promotionId=${id}`);
};

export const resendPromotionReport = async (params) => {
  return await post(
    `api/v1/Promotion/ResendReport?promotionId=${params.promotionId}&reportId=${params.reportId}`
  );
};

export const resendPromotionGroupReport = async (params) => {
  return await post(
    `api/v1/PromotionGroup/SendReport?promotionGroupId=${params.promotionGroupId}&reportId=${params.reportId}`
  );
};

export const getPushToDialerStatus = async (id) => {
  return await get(`api/v1/CallTask/PushToDialerStatus?promotionid=${id}`);
};

export const getReportFields = async (params) => {
  return await get(`api/v1/ReportField/GetList`, params);
};

export const getDialer = async (params) => {
  return await get(`api/v1/Configuration/GetDialerSettings`, params);
};

export const getForms = async (params) => {
  return await get(`api/v1/Forms/Forms`, params);
};

export const getSections = async (params) => {
  return await get(`api/v1/Forms/Sections`, params);
};

export const getElements = async (params) => {
  return await get(`api/v1/Forms/Elements`, params);
};

export const generateReport = async (reportId) => {
  return await post(`api/v1/Report/CreateReport?reportId=${reportId}`);
};

export const getReportDefinition = async (params) => {
  return await get(`api/v1/Report/GetList`, params);
};

export const promotionTypeReportList = async (params) => {
  return await get(`api/v1/PromotionType/PromotionTypeReportList`, params);
};

export const addReportToPromotionType = async (params) => {
  return await post(`api/v1/PromotionType/AddReportToPromotionType`, params);
};

export const removeReportToPromotionType = async (id) => {
  return await deleted(
    `api/v1/PromotionType/DeleteReportFromPromotionType?promotionTypeReportId=${id}`
  );
};

export const addReportToPromotionGroup = async (params) => {
  return await post(`api/v1/PromotionGroup/AddReportToPromotionGroup`, params);
};

export const removeReportToPromotionGroup = async (id) => {
  return await deleted(
    `api/v1/PromotionGroup/DeleteReportFromPromotionGroup?promotionGroupReportId=${id}`
  );
};

export const promotionGroupReportList = async (params) => {
  return await get(`api/v1/PromotionGroup/PromotionGroupReportList`, params);
};

export const getBucketEmptyStatus = async (id) => {
  return await post(`api/v1/Promotion/IsBucketEmpty?promotionid=${id}`);
};

export const getBrand = async () => {
  return await get(`api/v1/Brand/brand`);
};
