import { Route, Switch } from "react-router-dom";

import Call from "./view/Call/Call";
import ChangePassword from "./view/ChangePassword";
import ClientPage from "./view/ToolsAndSettings/ClientPage";
import Constants from "./view/Constants/Constants";
import EditReportField from "./view/ReportDefinition/editReportField";
import Elements from "./components/Elements/Elements";
import Forms from "./components/Forms/Forms";
import GenerateForm from "./view/GenerateForm/ComposedForm";
import HandleClient from "./view/ToolsAndSettings/HandleClient";
import HandleConstants from "./view/Constants/HandleConstants";
import HandleElements from "./components/Elements/HandleElements";
import HandleForms from "./components/Forms/HandleForms";
import HandlePromotionGroup from "./view/PromotionType/HandlePromotionGroup";
import HandlePromotionType from "./view/PromotionType/HandlePromotionType";
import HandlePromotions from "./view/Promotions/HandlePromotions";
import HandleReportDefinition from "./view/ReportDefinition/HandleReportDefinition";
import HandleReportDefinitionButton from "./view/ReportDefinition/HandleReportDefinitionButton";
import HandleReportFields from "./view/ReportFields/HandleReportFields";
import HandleSections from "./components/Sections/HandleSections";
import HandleUser from "./view/ToolsAndSettings/UpdatingPage/HandleUser";
import HandleUserRoles from "./view/ToolsAndSettings/UpdatingPage/HandleUserRoles";
import Layout from "./components/Layout/Layout";
import NotificationSettings from "./view/NotificationSettings/NotificationSettings";
import OpenRecruitingTasks from "./view/OpenRecruitingTasks/OpenRecruitingTasks";
import Overview from "./view/OverView/Overview";
import PageNotFound from "./components/PageNotFound";
import PromotionDetails from "./view/Promotions/PromotionDetails";
import PromotionDetailsPhone from "./view/Promotions/PromotionDetailsPhone";
import PromotionGroup from "./view/PromotionType/PromotionGroup";
import PromotionGroupLinkedReports from "./view/PromotionType/PromotionGroupLinkedReports";
import PromotionGroupReports from "./view/PromotionType/PromotionGroupReports";
import PromotionType from "./view/PromotionType/PromotionType";
import PromotionTypeReports from "./view/PromotionType/PromotionTypeReports";
import Promotions from "./view/Promotions/Promotions";
import React from "react";
import ReportDefinition from "./view/ReportDefinition/ReportDefinition";
import ReportFields from "./view/ReportFields/ReportFields";
import Reviews from "./view/Reviews/Reviews";
import Sections from "./components/Sections/Sections";
import UnfinishedTasks from "./v2/views/layouts/unfinishedTasks";
import User from "./view/ToolsAndSettings/User";
import UserRoleAssignment from "./view/ToolsAndSettings/UpdatingPage/UserRoleAssignment";
import UserRoles from "./view/ToolsAndSettings/UserRoles";

export const routesCode = [
  { path: "/", exact: true, component: Overview },
  { path: "/overview", exact: true, component: Overview },
  { path: "/call", exact: true, component: Call },
  { path: "/reviews", exact: true, component: Reviews },
  {
    path: "/open-recruiting-tasks",
    exact: true,
    component: OpenRecruitingTasks,
  },
  {
    path: "/unfinished-tasks",
    exact: true,
    component: UnfinishedTasks,
  },
  { path: "/constants", exact: true, component: Constants },
  { path: "/constants/Edit/:id", exact: true, component: HandleConstants },
  { path: "/constants/Add", exact: true, component: HandleConstants },
  { path: "/user", exact: true, component: User },
  { path: "/user/Add", exact: true, component: HandleUser },
  { path: "/user/Edit/:userId", exact: true, component: HandleUser },
  {
    path: "/user/userRoleAssignment/:userId",
    exact: true,
    component: UserRoleAssignment,
  },
  { path: "/user-roles", exact: true, component: UserRoles },
  { path: "/user-roles/Add", exact: true, component: HandleUserRoles },
  { path: "/user-roles/Edit/:roleId", exact: true, component: HandleUserRoles },
  { path: "/client", exact: true, component: ClientPage },
  { path: "/client/Add", exact: true, component: HandleClient },
  { path: "/client/Edit/:clientId", exact: true, component: HandleClient },
  { path: "/promotions", exact: true, component: Promotions },
  { path: "/notifications", exact: true, component: NotificationSettings },
  { path: "/promotion-type", exact: true, component: PromotionType },
  { path: "/promotion-group", exact: true, component: PromotionGroup },
  {
    path: "/promotion-group/Add",
    exact: true,
    component: HandlePromotionGroup,
  },
  {
    path: "/promotion-group/Edit/:id",
    exact: true,
    component: HandlePromotionGroup,
  },
  {
    path: "/promotion-group/reports/:id",
    exact: true,
    component: PromotionGroupReports,
  },
  {
    path: "/promotion-group/linked-promotion/reports/:id",
    exact: true,
    component: PromotionGroupLinkedReports,
  },
  {
    path: "/promotion-type/Edit/:id",
    exact: true,
    component: HandlePromotionType,
  },
  { path: "/promotion-type/Add", exact: true, component: HandlePromotionType },
  {
    path: "/promotion-type/reports/:id",
    exact: true,
    component: PromotionTypeReports,
  },
  {
    path: "/promotions/details/:id/:taskId",
    exact: true,
    component: PromotionDetailsPhone,
  },
  { path: "/promotions/details/:id", exact: true, component: PromotionDetails },
  { path: "/promotions/edit/:id", exact: true, component: HandlePromotions },
  { path: "/report-fields", exact: true, component: ReportFields },
  {
    path: "/report-fields/Edit/:id",
    exact: true,
    component: HandleReportFields,
  },
  { path: "/report-fields/Add", exact: true, component: HandleReportFields },
  { path: "/report-definition", exact: true, component: ReportDefinition },
  {
    path: "/report-definition/Edit/:id",
    exact: true,
    component: HandleReportDefinition,
  },
  {
    path: "/report-definition/Add",
    exact: true,
    component: HandleReportDefinition,
  },
  {
    path: "/report-definition/detail/Edit/:id",
    exact: true,
    component: HandleReportDefinitionButton,
  },
  {
    path: "/report-definition/detail/edit-field/:id",
    exact: true,
    component: EditReportField,
  },
  { path: "/change-password", exact: true, component: ChangePassword },
  { path: "/forms", exact: true, component: Forms },
  { path: "/forms/Add", exact: true, component: HandleForms },
  { path: "/forms/Edit/:id", exact: true, component: HandleForms },
  { path: "/sections/:id", exact: true, component: Sections },
  { path: "/sections/Add/:formId", exact: true, component: HandleSections },
  {
    path: "/sections/Edit/:formId/:id",
    exact: true,
    component: HandleSections,
  },
  { path: "/elements/:id/:sectionId", exact: true, component: Elements },
  {
    path: "/elements/:id/:sectionId/Add",
    exact: true,
    component: HandleElements,
  },
  {
    path: "/elements/Edit/:id/:sectionId/:elementId",
    exact: true,
    component: HandleElements,
  },
  { path: "/generate-form/:id", exact: true, component: GenerateForm },
  { path: "/open-recruiting-tasks", exact: true, component: GenerateForm },
  { path: "*", component: PageNotFound },
];

class Routes extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Switch>
          {routesCode.map((route, i) => (
            <Route {...route} key={i} />
          ))}
        </Switch>
      </Layout>
    );
  }
}

export default Routes;
