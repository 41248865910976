import "./index.css";
import "./dropdown.css";
import "./App.scss";
import 'react-table/react-table.css'
import "./components/ReactTable/Table.scss"
import 'react-datepicker/dist/react-datepicker.css'
import "./view/ToolsAndSettings/UpdatingPage/forms.scss"
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { MainProvider } from "./context";
import React from "react";
import ReactDOM from "react-dom";
import de_data from "./languageFile/german.json";
import en_data from "./languageFile/english.json";
import history from "./shared/history";
import i18n from "./i18n";
import packageJson from '../package.json';
import ru_data from "./languageFile/russian.json";

console.log("build version", packageJson.version); // "1.0.0"


if (localStorage.getItem("clab-debug") !== null) {
  // @ts-ignore
  if (!window.console) window.console = {};
  var methods = ["log", "debug", "warn", "info", "error"];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () { };
  }
}


const messages = {
  en: en_data,
  de: de_data,
  ru: ru_data,
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter history={history}>
      <MainProvider>
        <App messages={messages} />
      </MainProvider>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
