import React from "react";
type TextResizeButtonGroupProps = {
  changeFontSize: string;
  normalFont: () => void;
  smallFont: () => void;
  extraSmallFont: () => void;
};
const TextResizeButtonGroup = ({
  changeFontSize,
  normalFont,
  smallFont,
  extraSmallFont,
}: TextResizeButtonGroupProps) => {
  return (
    <div className="btn-group btn-group-toggle m-1">
      <div
        className={
          "btn btn-secondary " + (changeFontSize === "14px" ? "active" : "")
        }
        onClick={normalFont}
        title="Normal Font"
      >
        <span style={{ fontSize: "16px" }}>A</span>
      </div>

      <div
        className={
          "btn btn-secondary " + (changeFontSize === "12px" ? "active" : "")
        }
        onClick={smallFont}
        title="Small Font"
      >
        <span style={{ fontSize: "12px" }}>A</span>
      </div>

      <div
        className={
          "btn btn-secondary " + (changeFontSize === "11px" ? "active" : "")
        }
        onClick={extraSmallFont}
        title="Extra Small Font"
      >
        <span style={{ fontSize: "10px" }}>A</span>
      </div>
    </div>
  );
};

export default TextResizeButtonGroup;
