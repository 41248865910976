import Loader from "v2/views/components/loader";
import PageNotFound from "components/PageNotFound";
import React from "react";
import { ToastContainer } from "react-toastify";
import queryString from "query-string";
import useConfirmPromotion from "v2/core/services/promotion/useConfirmPromotion";
import useGetPromotionInfoById from "v2/core/services/promotion/useGetPromotionInfoById";
import { useLocation } from "react-router-dom";
type ParamsType = {
  promotion_id: string;
  email: string;
};
const StartCall = () => {
  const location = useLocation();
  const params = queryString.parse(location.search) as ParamsType;
  const { data, loading, error } = useGetPromotionInfoById(params.promotion_id);
  const { confirmPromotion, loading: confirmLoading } = useConfirmPromotion();

  const handleConfirmPromotion = () => {
    confirmPromotion(params.promotion_id, params.email);
  };
  if (loading)
    return (
      <div className="d-flex justify-content-center m-4">
        <Loader />
      </div>
    );
  if (error) return <PageNotFound />;
  if (!params.promotion_id || !params.email) return <PageNotFound />;
  return (
    <div className="jumbotron vh-100 bg-light">
      <div className="container m-4">
        <div>
          <img
            src={require("images/logo.png")}
            alt="test"
            className="img-fluid my-4"
          />
        </div>

        <div className="card">
          <div className="card-body">
            <p>
              The promotion <b>{data?.description}</b> you have submitted
              contains <b>{data?.total}</b> records. There are{" "}
              <b>{data?.importErrorCount}</b> errors in the file. If you would
              like to start the promotion please press the following button.
            </p>

            <button
              disabled={confirmLoading}
              onClick={handleConfirmPromotion}
              className="btn btn-primary flex gap-1"
            >
              {confirmLoading && (
                <div
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              Confirm Start Calling
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={false}
        closeOnClick
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default StartCall;
