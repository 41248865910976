import { Field, Form } from 'react-final-form';
import React, { useEffect, useState } from 'react'
import { addConstant, getClientData, getConstantById, updateConstant } from '../../services/toolsAndSettingsPagesApi';
import { find, get } from "lodash";
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../shared/funs';

import Error from '../../components/Error/Error';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import Select from 'react-select'
import SelectField from '../../components/Fields/SelectField';

const HandleVariables = (props) => {
  const [variable, setVariable] = useState(null)
  const [clients, setClients] = useState([])
  const [error, setError] = useState(false)

  var heading = "EditConstant"

  var values = {};
  if (props.location.pathname.includes("Edit")) {
    values = {
      clientName: get(variable, "clientName", ""),
      clientId: get(variable, "clientId", ""),
      name: get(variable, "constantName", ""),
      description: get(variable, "description", ""),
      type: get(variable, "type", ""),
    }
  } else {
    heading = "AddConstant"
    values = {
      clientName: "",
      clientId: "",
      name: "",
      description: "",
      type: ""
    }
  }

  const getClients = (search = "") => {
    getClientData({
      page: 0,
      display: 9999999,
      search: search
    }).then(clients => {
      var tempClient = []
      clients.clients.forEach(client => {
        tempClient.push({
          value: client.clientName,
          label: client.clientName,
          clientId: client.clientId
        })
      })
      setClients(tempClient)
    }).catch(err => {
    })
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getConstantById({ constantid: props.match.params.id }).then(res => {
        setVariable(res)
        var allClient = []
        getClientData({
          page: 0,
          display: 9999999,
        }).then(clients => {

          clients.clients.forEach(client => {
            allClient.push({
              value: client.clientName,
              label: client.clientName,
              clientId: client.clientId
            })
          })

          if (!find(allClient, { clientId: res.clientId })) {
            allClient.push({
              value: res.clientName,
              label: res.clientName,
              clientId: res.clientId
            })
          }
          setClients(allClient)

        }).catch(err => {
          allClient.push({
            value: res.clientName,
            label: res.clientName,
            clientId: res.clientId
          })
          setClients(allClient)
        })
      }).catch(err => {
        setVariable(null)
        setError(permissionErrorCheck(get(err, "data.value.errors.description", "Something went wrong, please try after some time!")))
      })
    } else {
      getClientData({
        page: 0,
        display: 9999999,
      }).then(clients => {

        var tempClient = []
        clients.clients.forEach(client => {
          tempClient.push({
            value: client.clientName,
            label: client.clientName,
            clientId: client.clientId
          })
        })
        setClients(tempClient)
      }).catch(err => {
      })
    }
  }, [
    props.location.pathname,
    props.match.params.id
  ])

  const handleEditForm = async (data) => {

    let object = {
      "constantId": props.match.params.id,
      "clientId": data.clientId,
      "constantName": data.name,
      "description": data.description,
      "type": data.type

    }
    updateConstant(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/constants")
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })

  }


  const handleAddForm = async (data) => {
    let object = {
      "clientId": data.clientId,
      "constantName": data.name,
      "description": data.description,
      "type": data.type

    }
    addConstant(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/constants")
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }

  const validation = (data) => {
    let errors = {}
    if (!data.clientId || data.clientId === "") {
      errors.clientId = "Required";
    }
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (data.name && data.name !== "" && !data.name.match(/^[a-zA-Z0-9_.-]+$/)) {
      errors.name = "Invalid constant name";
    }
    if (!data.description || data.description === "") {
      errors.description = "Required";
    }
    if (!data.type || data.type === "") {
      errors.type = "Required";
    }
    return errors
  }

  const generateOptions = () => {
    let options = [<option value={``}> Select Type</option>]
    let optionsArray = [{ label: "List", value: "list" }, { label: "Number", value: "number" }]
    optionsArray.map(({ label, value }) => {
      return options.push(<option value={value}>{label}</option>)
    })

    return options;
  }

  return (
    <div className="main-content">
  
      <PageHeading headingId={heading} backURL="/constants" />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Edit") ? handleEditForm : handleAddForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="clientId">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable>{renderFormatMsg("ClientName", "Client Name")} </lable>
                            <Select
                              value={clients.filter(option => option.clientId === input.value)}
                              onChange={(value) => input.onChange(value.clientId)}
                              onInputChange={(inputValue) => {
                                getClients(inputValue)
                              }
                              } options={clients} />


                            {/* <input {...input} type="text" placeholder="Client Name" readOnly className="read-only form-control" /> */}
                            {meta.error && meta.touched && (<span>{meta.error}</span>)}
                          </div>
                        )}
                      </Field>
                    </div>



                  </div>

                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("Name", "Constant Name")} </lable>
                            <input {...input} type="text" placeholder="Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="Name"
                      defaultLabel="Constant Name"
                      placeholder={"Constant Name"}
                    />
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="description">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("Description", "Description")} </lable>
                            <input {...input} type="text" placeholder="Description" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-6"
                      name={"description"}
                      type="text"
                      id="Description"
                      defaultLabel="Description"
                      placeholder={"Description"}
                    />
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="type">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("Type", "Type")} </lable>
                            <select  {...input} defaultValue={input.value} className="form-control">
                              <option value={``}>Select Type</option>
                              <option value={`number`}>Number</option>
                              <option value={`list`}>List</option>
                            </select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <SelectField
                      colClass="col-lg-6"
                      name="type"
                      options={generateOptions()}
                      id="Type"
                      // disabled={(!permissionForCategory1.write) ? true : false}
                      defaultLabel={"Type"}
                    // className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                    />
                  </div>

                </div>

                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary">
                      {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandleVariables)
